<template>
  <!-- <div class="h-full relative left-0 top-0 flex flex-col overflow-x-hidden"> -->
  <div class="h-full relative left-0 top-0 flex flex-col">
    <h1 class="text-center pt-6 pb-7 title">Einkommensschutz</h1>
    <div class="flex flex-row gap-2 justify-between h-full">
      <router-view @updateData="updateData()" />
      <div class="chart-wrapper relative grow overflow-x-hidden">
        <div class="line-bg absolute left-0 top-0 w-full h-full overflow-hidden">
          <div class="line" v-for="n of 50" :key="n">
          </div>
        </div>
        <v-chart ref="chart" class="chart" :option="chart" />
        <v-chart ref="pdfChart" class="chart pdf-chart absolute -right-[1000px] top-0" :option="chartPdf" />
        <div class="summary-wrapper absolute top-10 left-10 bg-slate--50 p-4 max-w-sm">
          <div class="einkommen-verlust-wrapper flex">
            <div 
              class="einkommen w-full"
              v-if="($route.name === 'einkommensschutz_1' || $route.name === 'einkommensschutz_2' || $route.name === 'einkommensschutz_3')"
            >
              <p>Dein Einkommen in<br> Summe bis zur Rente:</p>
              <p class="big">{{parseInt(compChartSeriesData.at(-1), 10).toLocaleString()}}€</p>
            </div>
            <div 
              class="verlust w-full" 
              v-if="state.app.einkommensschutz.simulationResult !== null && ($route.name === 'einkommensschutz_2' || $route.name === 'einkommensschutz_3')"
            >
              <!-- <p>Möglicher Einkommensverlust ab Alter {{compBuAbJahr}}:</p> -->
              <p>Möglicher Einkommensverlust:</p>
              <p class="big -m-3">- {{parseInt((compChartSeriesData.at(-1) - compChartCapped.at(-1)), 10).toLocaleString()}}€</p>
            </div>
          </div>
          <div class="slider" v-if="compActiveVersicherung != null">
            <div class="flex items-center">
              <p>{{compActiveVersicherung.sliderTitle}}</p>
              <div 
                v-if="compActiveVersicherung.popperText"
                class="info-icon grow pl-1 cursor-pointer">
                <VDropdown
                  :distance="15"
                  :triggers=[]
                  :shown="showInfo"
                  :placement="'right'"
                >
                  <div>
                    <img @click.stop="showInfo = !showInfo" class="w-5 h-5 z-20" src="static/img/info-icon-grey.svg">
                  </div>
                  <template #popper>
                    <div class="w-[320px] p-4 overflow-hidden">
                      <div class="flex flex-col">
                        <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                          <!-- <div class="title">Berufsunfähigkeits&shy;versicherung</div> -->
                          <div class="text-popper break-words mt-2" v-html="compActiveVersicherung.popperText"></div>
                        </div>
                        <div v-if="compActiveVersicherung.popperSource != null" class="self-end mt-4 cursor-pointer"><a :href="compActiveVersicherung.popperSource" target="_blank">Mehr Informationen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></a></div>
                      </div>
                    </div>
                  </template>
                </VDropdown>
              </div>
            </div>
            <app-slider 
              v-if="compActiveVersicherung.title != 'Krankengeld & private Krankentagegeldversicherung'"
              :minValue="slider[compActiveVersicherung.title].min"
              :maxValue="slider[compActiveVersicherung.title].max"
              :interval="slider[compActiveVersicherung.title].interval"
              :modelObject="'einkommensschutz'"
              :modelName="'simulation'+compActiveVersicherung.title"
              :valueSuffix="slider[compActiveVersicherung.title].sliderType"
              :bigText="true"
              :inputStyle="slider[compActiveVersicherung.title].style"
              :popperText="compActiveVersicherung.popperText"
            />
            <p class="flex items-center">Rente in Summe: &nbsp;
              <span class="big" :style="{'color':compActiveVersicherung.bgColor}" >{{Math.round(compTotalPayment).toLocaleString()}}€</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { registerTheme, use } from 'echarts/core'
import { CanvasRenderer } from "echarts/renderers"
import { BarChart } from "echarts/charts"

import VChart, { THEME_KEY } from "vue-echarts"

import { GridComponent } from 'echarts/components';
import AppSlider from '@/components/AppSlider.vue'

use([
  CanvasRenderer,
  BarChart,
  GridComponent
]);

export default {
  name: 'Einkommenschutz',
  components: {
    VChart,
    AppSlider,
  },
  data() { return {
    state: this.$store.state,
    showInfo: false,
    somethingHasChanged: false,
    slider: {
      "Berufsunfähigkeitsversicherung": {
        min: 500,
        max: 10000,
        interval: 5,
        sliderType: 'euro',
        style: {color:'#FABE50'}
      },
      "Erwerbsunfähigkeitsversicherung": {
        min: 0,
        max: 10000,
        interval: 50,
        sliderType: 'euro',
        style: {color:'#FF7A00'}
      },
      "Grundfähigkeitsversicherung": {
        min: 500,
        max: 1500,
        interval: 50,
        sliderType: 'euro',
        style: {color:'#00C2FF'}
      },
      "Dread Disease Versicherung": {
        min: 50000,
        max: 250000,
        interval: 1000,
        sliderType: 'euro',
        style: {color:'#CF0BA4'}
      },
      "Krankengeld & private Krankentagegeldversicherung": {
        min: null,
        max: null,
        sliderType: null,
        style: {color:'#7738A9'}
      },
    },
    chart: {
      xAxis: {
        // name: "Jahre bis zur Rente",
        // nameLocation: "middle",
        // nameGap: "30",
        type: 'category',
        data: [],
        axisLabel: {
          fontSize: 13
        }
      },
      yAxis: {
        name:"Einkommen\n(aufsummiert)",
        nameTextStyle: {
          align: "left",
        },
        position: 'right',
        type: 'value',
        axisLabel: {
          formatter: function (value, index) {
            return value.toLocaleString() + '€';
          },
          fontSize: 13
        }
      },
      series: [
        {
          name: "perfectLive",
          color: ['rgba(115, 123, 125, 0.5)'],
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
          // stack: 'one',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungBU",
          color: ['#FABE50'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungEU",
          color: ['#FF7A00'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungGF",
          color: ['#00C2FF'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungDD",
          color: ['#CF0BA4'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungKTG",
          color: ['#7738A9'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "buCap",
          color: ['hsl(204, 74%, 46%)'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
      ]
    },
    chartPdf: {
      xAxis: {
        // name: "Jahre bis zur Rente",
        // nameLocation: "middle",
        // nameGap: "30",
        type: 'category',
        data: [],
        axisLabel: {
          fontSize: 13
        }
      },
      yAxis: {
        name:"Einkommen\n(aufsummiert)",
        nameTextStyle: {
          align: "left",
        },
        position: 'right',
        type: 'value',
        axisLabel: {
          formatter: function (value, index) {
            return value.toLocaleString() + '€';
          },
          fontSize: 13
        }
      },
      series: [
        {
          name: "perfectLive",
          color: ['rgba(115, 123, 125, 0.5)'],
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
          // stack: 'one',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungBU",
          color: ['#FABE50'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungEU",
          color: ['#FF7A00'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungGF",
          color: ['#00C2FF'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungDD",
          color: ['#CF0BA4'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "versicherungKTG",
          color: ['#7738A9'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
        {
          name: "buCap",
          color: ['hsl(204, 74%, 46%)'],
          data: [],
          type: 'bar',
          // stack: 'one',
          barGap: '-100%',
          showBackground: false,
          // backgroundStyle: {
          //   color: 'rgba(180, 180, 180, 0.2)'
          // }
        },
      ]
    }
  }},
  watch: {
    'state.app.einkommensschutz' : {
      handler: function(newVal, oldVal) {
        this.somethingHasChanged = true;
        this.updateData();
      },
      deep: true
    },
    'state.app.einkommensschutz.model' : {
      handler: function(newVal, oldVal) {
        //check if any model is true
        if (!Object.values(newVal).some(Boolean)) {
          this.state.app.einkommensschutz.versicherungsFormen.find((o, i) => {
            if (o.active) {
              o.active = false;
            }
          })
        }
      },
      deep: true
    }
  },
  mounted() {
    this.updateData();
  },
  computed: {
    compChartXdata() {
      let tmpArr = [];
      for (let i = this.state.app.einkommensschutz.alter; i <= 67-1; i++) {
        tmpArr.push(i)
      }

      return tmpArr
    },
    compTotalPayment() {
      let paymentCut = this.compChartCapped.at(-1);
      let lastPaymentOfSimulation = 0;

      if (this.compActiveVersicherung.title === "Berufsunfähigkeitsversicherung") {
        lastPaymentOfSimulation = this.compBarBU.at(-1);
      }else if (this.compActiveVersicherung.title === "Erwerbsunfähigkeitsversicherung") {
        lastPaymentOfSimulation = this.compBarEU.at(-1);
      }else if (this.compActiveVersicherung.title === "Grundfähigkeitsversicherung") {
        lastPaymentOfSimulation = this.compBarGF.at(-1);
      }else if (this.compActiveVersicherung.title === "Dread Disease Versicherung") {
        lastPaymentOfSimulation = this.compBarDD.at(-1);
      }else if (this.compActiveVersicherung.title === "Krankengeld & private Krankentagegeldversicherung") {
        lastPaymentOfSimulation = this.compBarKTG.at(-1);
      }

      let totalPayment = lastPaymentOfSimulation - paymentCut;

      this.state.app.einkommensschutz["buAge"] = this.state.app.einkommensschutz.alter + Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100) + 1;

      this.state.app.einkommensschutz['RenteGesamtBerufsunfähigkeitsversicherung'] = this.compBarPdfBU.at(-1) - paymentCut;
      this.state.app.einkommensschutz['RenteGesamtErwerbsunfähigkeitsversicherung'] = this.compBarPdfEU.at(-1) - paymentCut;
      this.state.app.einkommensschutz['RenteGesamtGrundfähigkeitsversicherung'] = this.compBarPdfGF.at(-1) - paymentCut;
      this.state.app.einkommensschutz['RenteGesamtDread Disease Versicherung'] = this.compBarPdfDD.at(-1) - paymentCut;
      this.state.app.einkommensschutz['RenteGesamtKrankengeld und private Krankentagegeldversicherung'] = this.compBarPdfKTG.at(-1) - paymentCut;
      this.state.app.einkommensschutz['RenteBerufsunfähigkeitsversicherungNichtMaximum'] = this.compBarBU.at(-1) - paymentCut;
      this.state.app.einkommensschutz['RenteErwerbsunfähigkeitsversicherungNichtMaximum'] = this.compBarEU.at(-1) - paymentCut;

      return totalPayment;
    },
    compChartSeriesData() {
      let nettoYear = 0;
      let tmpArr = []
      this.compChartXdata.forEach(elem => {
        nettoYear += (this.state.app.einkommensschutz.nettoMtl * 12)
        nettoYear *= (1 + this.state.app.einkommensschutz.gehaltSteigerung / 100)
        tmpArr.push(nettoYear)
      });
      
      this.state.app.einkommensschutz.summeEinkommen = parseInt(tmpArr.at(-1), 10);
      return tmpArr
    },
    compChartCapped() {
      let newArr = []
      
      if (this.state.app.einkommensschutz.simulationResult !== null) {
        let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
        let tmpArr = [...this.compChartSeriesData]

        tmpArr.forEach((elem,index) => {
          if (index >=cutIndex ) {
            elem = tmpArr[cutIndex]
          } 
          newArr.push(elem)
        })
      }else {
        newArr = this.compChartSeriesData;
      }
      
      this.state.app.einkommensschutz.simulationVerlust = parseInt(this.compChartSeriesData.at(-1) - newArr.at(-1))
      return newArr
    },
    compBarBU() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      let nettoYear = this.state.app.einkommensschutz.nettoMtl * 12;

      if (this.state.app.einkommensschutz.versicherungsFormen[0].active) {
        newArr =  new Array(cutIndex+1).fill(0);
        // newArr.push(2000000);
        
        let tmpArr = [...this.compChartSeriesData];
        let tmpYear = this.state.app.einkommensschutz['simulationBerufsunfähigkeitsversicherung'] * 12;

        const salaryIncreaseRate = Math.min(this.state.app.einkommensschutz.gehaltSteigerung, 3) / 100;

        const salaryForYear = (initialSalary, rate, years) => {
            return initialSalary * Math.pow(1 + rate, years);
        };

        let currentYearSalary = salaryForYear(tmpYear, salaryIncreaseRate, cutIndex + 2); 

        tmpArr.forEach((val,index) => {
          if (index >= cutIndex+1) {

            if (index >= cutIndex+1) {
                if (index === cutIndex+1) {
                    newArr.push(this.compChartCapped.at(-1) + currentYearSalary);
                } else {
                    newArr.push(newArr.at(-1) + currentYearSalary);
                }

                currentYearSalary += currentYearSalary * salaryIncreaseRate;
            }
          }
        });
      }

      return newArr;
    },
    compBarEU() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
      if (this.state.app.einkommensschutz.versicherungsFormen[1].active) {
        newArr =  new Array(cutIndex+1).fill(0);
        // newArr.push(2000000);
        
        let tmpArr = [...this.compChartSeriesData];
        let tmpYear = this.state.app.einkommensschutz['simulationErwerbsunfähigkeitsversicherung'] * 12;

        const salaryIncreaseRate = Math.min(this.state.app.einkommensschutz.gehaltSteigerung, 3) / 100;

        const salaryForYear = (initialSalary, rate, years) => {
            return initialSalary * Math.pow(1 + rate, years);
        };

        let currentYearSalary = salaryForYear(tmpYear, salaryIncreaseRate, cutIndex + 2); 

        tmpArr.forEach((val,index) => {
          if (index >= cutIndex+1) {

            if (index >= cutIndex+1) {
                if (index === cutIndex+1) {
                    newArr.push(this.compChartCapped.at(-1) + currentYearSalary);
                } else {
                    newArr.push(newArr.at(-1) + currentYearSalary);
                }

                currentYearSalary += currentYearSalary * salaryIncreaseRate;
            }
          }
        });
      }

      return newArr;
    },
    compBarGF() {
      let newArr = [];
      const cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);

      if (this.state.app.einkommensschutz.versicherungsFormen[2].active) {
          
          let tmpArr = new Array(cutIndex+1).fill(0);  // Fülle nur bis cutIndex
          
          // Nehme die jährliche Gehaltssteigerungsrate aus dem State
          const salaryIncreaseRate = Math.min(this.state.app.einkommensschutz.gehaltSteigerung, 3) / 100;

          const salaryForYear = (initialSalary, rate, years) => {
              return initialSalary * Math.pow(1 + rate, years);
          };
          

          let yearlyIncome = salaryForYear(this.state.app.einkommensschutz.simulationGrundfähigkeitsversicherung * 12, salaryIncreaseRate, cutIndex+2);  
          let accumulatedYearlyIncomes = [];

          this.compChartXdata.forEach((elem, index) => {
            if (index > cutIndex) {
              accumulatedYearlyIncomes.push(yearlyIncome);

              let totalIncomeTillNow = accumulatedYearlyIncomes.reduce((acc, val) => acc + val, 0);
              tmpArr.push(totalIncomeTillNow + this.compChartCapped.at(-1));

              // Erhöhen Sie das Gehalt für das nächste Jahr
              yearlyIncome *= (1 + salaryIncreaseRate);
            }
          });

          newArr = tmpArr;
      }

      return newArr;
    },
    compBarDD() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
      if (this.state.app.einkommensschutz.versicherungsFormen[3].active) {
        newArr =  new Array(cutIndex+1).fill(0);
        newArr.push(this.state.app.einkommensschutz['simulationDread Disease Versicherung'] + this.compChartCapped.at(-1));
      }

      return newArr;
    },
    compBarKTG() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
      if (this.state.app.einkommensschutz.versicherungsFormen[4].active) {
        newArr =  new Array(cutIndex+1).fill(0);
        // let tmpLastYear = this.compChartSeriesData[cutIndex] - this.compChartSeriesData[cutIndex-1];
        // tmpLastYear *= (100 - this.state.app.einkommensschutz.gehaltSteigerung)/100;

        let currentYear = this.compChartSeriesData[cutIndex+1];
        let nextYear = currentYear;
        newArr.push(currentYear);
        newArr.push(nextYear);
      }

      return newArr;
    },
    compBarPdfBU() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      let nettoYear = this.state.app.einkommensschutz.nettoMtl * 12;

      newArr =  new Array(cutIndex+1).fill(0);
      
      let tmpArr = [...this.compChartSeriesData];
      let tmpYear = 0;
      if (this.state.app.einkommensschutz['nettoMtl'] <= 1500) {
        tmpYear = 1500 * 12;
      }else {
        tmpYear = this.state.app.einkommensschutz['nettoMtl'] * 0.90 * 12;
      }
      console.warn(tmpYear);

      const salaryIncreaseRate = Math.min(this.state.app.einkommensschutz.gehaltSteigerung, 3) / 100;

      const salaryForYear = (initialSalary, rate, years) => {
          return initialSalary * Math.pow(1 + rate, years);
      };

      let currentYearSalary = salaryForYear(tmpYear, salaryIncreaseRate, cutIndex + 2); 

      tmpArr.forEach((val,index) => {
        if (index >= cutIndex+1) {

          if (index >= cutIndex+1) {
              if (index === cutIndex+1) {
                  newArr.push(this.compChartCapped.at(-1) + currentYearSalary);
              } else {
                  newArr.push(newArr.at(-1) + currentYearSalary);
              }

              currentYearSalary += currentYearSalary * salaryIncreaseRate;
          }
        }
      });

      return newArr;
    },
    compBarPdfEU() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
      newArr =  new Array(cutIndex+1).fill(0);
      
      let tmpArr = [...this.compChartSeriesData];
      let tmpYear = this.state.app.einkommensschutz['nettoMtl'] * 0.90 * 12;

      const salaryIncreaseRate = Math.min(this.state.app.einkommensschutz.gehaltSteigerung, 3) / 100;

      const salaryForYear = (initialSalary, rate, years) => {
          return initialSalary * Math.pow(1 + rate, years);
      };

      let currentYearSalary = salaryForYear(tmpYear, salaryIncreaseRate, cutIndex + 2); 

      tmpArr.forEach((val,index) => {
          if (index >= cutIndex+1) {
              if (index === cutIndex+1) {
                  newArr.push(this.compChartCapped.at(-1) + currentYearSalary);
              } else {
                  newArr.push(newArr.at(-1) + currentYearSalary);
              }

              currentYearSalary += currentYearSalary * salaryIncreaseRate;
          }
      });

      return newArr;
  },
    compBarPdfGF() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
      // let nettoYear = 0;
      let tmpArr = new Array(cutIndex+1).fill(0);  // Fülle nur bis cutIndex
          
      // Nehme die jährliche Gehaltssteigerungsrate aus dem State
      const salaryIncreaseRate = Math.min(this.state.app.einkommensschutz.gehaltSteigerung, 3) / 100;

      const salaryForYear = (initialSalary, rate, years) => {
          return initialSalary * Math.pow(1 + rate, years);
      };
      

      let yearlyIncome = salaryForYear(this.slider["Grundfähigkeitsversicherung"].max * 12, salaryIncreaseRate, cutIndex+2);  
      let accumulatedYearlyIncomes = [];

      this.compChartXdata.forEach((elem, index) => {
        if (index > cutIndex) {
          accumulatedYearlyIncomes.push(yearlyIncome);

          let totalIncomeTillNow = accumulatedYearlyIncomes.reduce((acc, val) => acc + val, 0);
          tmpArr.push(totalIncomeTillNow + this.compChartCapped.at(-1));

          // Erhöhen Sie das Gehalt für das nächste Jahr
          yearlyIncome *= (1 + salaryIncreaseRate);
        }
      });

      newArr = tmpArr;

      return newArr;
    },
    // compBarPdfGF() {
    //   let newArr = [];
    //   let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
    //   let nettoYear = 0;
    //   let tmpArr = []
    //   tmpArr =  new Array(cutIndex+1).fill(0);
    //   this.compChartXdata.forEach((elem,index) => {
    //     if (index > cutIndex) {
    //       nettoYear += (this.slider["Grundfähigkeitsversicherung"].max * 12)
    //       tmpArr.push(nettoYear+this.compChartCapped.at(-1))
    //     }
    //   });
    //   newArr = tmpArr;


    //   return newArr;
    // },
    compBarPdfDD() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
      newArr =  new Array(cutIndex+1).fill(0);
      newArr.push(this.slider["Dread Disease Versicherung"].max + this.compChartCapped.at(-1));

      return newArr;
    },
    compBarPdfKTG() {
      let newArr = [];
      let cutIndex = Math.floor(this.compChartSeriesData.length * this.state.app.einkommensschutz.simulationResult / 100);
      
      newArr =  new Array(cutIndex+1).fill(0);

      let currentYear = this.compChartSeriesData[cutIndex+1];
      let nextYear = currentYear;
      newArr.push(currentYear);
      newArr.push(nextYear);

      return newArr;
    },
    compActiveVersicherung() {
      // let currentlyAcitve = this.state.app.einkommensschutz.versicherungsFormen.filter(o => o.acitve);
      let tmpArr = [...this.state.app.einkommensschutz.versicherungsFormen];
      let currentlyAcitve = tmpArr.filter(o => o.active);

      let toReturn = currentlyAcitve.length > 0 ? currentlyAcitve[0] : null

      return toReturn
    },
    compBuAbJahr() {
      let rentenBegin = 67;
      let alter = this.state.app.einkommensschutz.alter;
      let arbeitsZeit = rentenBegin - alter;
      let simulationBu = this.state.app.einkommensschutz.simulationResult / 100;

      let alterBu = alter + (arbeitsZeit * simulationBu) + 1;
      
      return Math.round(alterBu);
    },
  },
  methods: {
    updateData(data) {
      // this.chart.series[0].data = [[0,0],[this.state.app.vermoegen.sparphaseJahre, this.state.app.vermoegen.sparphaseJahre * 12 * this.state.app.vermoegen.sparbetragMtl]]
      // this.chart.series[1].data = this.compSparPotentialData
      this.chart.xAxis.data = this.compChartXdata;
      this.chart.series[0].data = this.compChartSeriesData; // gray background
      this.chart.series[6].data = this.compChartCapped; // blue bar capped
      this.chart.series[1].data = this.compBarBU; // yellow bar, BU
      this.chart.series[2].data = this.compBarEU; // orange bar, EU
      this.chart.series[3].data = this.compBarGF; // blue bar, GF
      this.chart.series[4].data = this.compBarDD; // magenta bar, DD
      this.chart.series[5].data = this.compBarKTG; // purple bar, KTG

      // Pdf clone
      this.chartPdf.xAxis.data = this.compChartXdata;
      this.chartPdf.series[0].data = this.compChartSeriesData; // gray background
      this.chartPdf.series[6].data = this.compChartCapped; // blue bar capped
      this.chartPdf.series[this.$store.compVersicherungsRanking[0].id].data = this.useCompForPdf()//this.compBarPdfBU;
    },
    useCompForPdf() {
      // clean all bars, except for the gray and the blue
      this.chartPdf.series.forEach((elem,index) => {
        if (index > 0 && index < 6) {
          elem.data = []
        }
      });

      // if no checkbox is set, return empty array
      if (!Object.values(this.state.app.einkommensschutz.model).some(item => item === true)) {
        return [];
      }
      let nrOne = this.$store.compVersicherungsRanking[0].title;
      if (nrOne === "Berufsunfähigkeitsversicherung") {
        return this.compBarPdfBU
      }
      if (nrOne === "Erwerbsunfähigkeitsversicherung") {
        return this.compBarPdfEU
      }
      if (nrOne === "Grundfähigkeitsversicherung") {
        return this.compBarPdfGF
      }
      if (nrOne === "Dread Disease Versicherung") {
        return this.compBarPdfDD
      }
      if (nrOne === "Krankengeld & private Krankentagegeldversicherung") {
        return this.compBarPdfKTG
      }
    },
    updateSelected(data) {
      
    }
  },
  beforeDestroy() {

    if (this.somethingHasChanged) {
      if (this.state.app.einkommensschutz.versicherungsFormen.find(o => o.title === "Berufsunfähigkeitsversicherung").active) {
        // visible chart
        this.state.app.pdfCharts.einkommen = document.getElementsByTagName("canvas")[0].toDataURL()
      }else {
        // hidden chart
        this.state.app.pdfCharts.einkommen = document.getElementsByTagName("canvas")[1].toDataURL()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1F2532;
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.7px;
  color: #737B7D;
}
.text-popper {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  letter-spacing: -0.7px;
  color: #737B7D;
}
.chart-wrapper {
  width: 630px;
  height: 100%;
  // position: absolute;
  // right: 0;
}
.chart {
  height: 100%;
}

.summary-wrapper {
  border-radius: 0.5rem;
  background: #FFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
  opacity: .95;
  p {
    margin-top: 7px;
    color: #737B7D;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;

    &.big {
      font-weight: 800;
      font-size: 26px;
      line-height: 22px;
      // letter-spacing: -.5px;
    }
  }

  span.big {
    font-weight: 800;
    font-size: 26px;
    line-height: 22px;
    // letter-spacing: -.5px;
  }

  .einkommen {
    p {
      color: #1E86CB;
    }
  }
  .verlust {
    
  }
  .slider {
    margin-top: 15px;
    p {
      margin-bottom: 7px;
    }
  }
}

.pdf-chart{
  width: 770px;
  height: 620px;
}

::v-deep .pdf-chart canvas{
  width: 770px!important;
  height: 620px!important;
}

::v-deep .chart-wrapper .slider-container .vue-slider {
  width: 129px!important;
  margin-right: 19px;
}
</style>
