<template>
  <controll-wrapper title="Wie sehen deine Präferenzen für den Einkommensschutz aus?">
    <template v-slot:content>
      <div class="pl-10 pr-10 pt-3">
        <modal v-model="ui.showGfModal">
          <div class="overflow-auto">
            <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_Grundfähigkeit.jpg">
          </div>
        </modal>
        <modal v-model="ui.showEuModal">
          <div class="overflow-auto">
            <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_Erwerbsunfähigkeit.jpg">
          </div>
        </modal>
        <modal v-model="ui.showBuModal">
          <div class="overflow-auto">
            <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_berufsunfähigkeit_Wahrscheinlichkeit.jpg">
          </div>
        </modal>
        <modal v-model="ui.showDdModal">
          <div class="overflow-auto">
            <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_DreadDisease.jpg">
          </div>
        </modal>
        <div class="flex flex-row gap-y-6 gap-x-6 checkboxes">
          <div class="basis-1/2 flex flex-row items-baseline">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" v-model="state.app.einkommensschutz.model.zahltBeiBU" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Zahlung, wenn aktueller Beruf nicht mehr ausgeübt werden kann
            </div>
            <div class="info-icon grow self-center" @click="ui.showBuModal = true">
              <img src="../../../public/static/img/info-icon-grey.svg">
            </div>
          </div>
          <div class="basis-1/2 flex flex-row items-baseline">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" v-model="state.app.einkommensschutz.model.zahltBeiEU" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Zahlung, wenn kein Beruf mehr ausgeübt werden kann
            </div>
            <div class="info-icon grow self-center" @click="ui.showEuModal = true">
              <img src="../../../public/static/img/info-icon-grey.svg">
            </div>
          </div>
        </div>
        <div class="flex flex-row mt-6 gap-x-6 checkboxes">
          <div class="basis-1/2 flex flex-row items-baseline">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" v-model="state.app.einkommensschutz.model.zahltBeiVerlustGF" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Zahlung nur bei Verlust bestimmter Fähigkeiten (z.B. Sehen. Gehen) 
            </div>
            <div class="info-icon grow self-center" @click="ui.showGfModal = true">
              <img src="../../../public/static/img/info-icon-grey.svg">
            </div>
          </div>
          <div class="basis-1/2 flex flex-row items-baseline">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" v-model="state.app.einkommensschutz.model.zahltBeiKrankheit" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Zahlung bei Eintritt schwerer Krankheiten (z.B. Krebs)
            </div>
            <div class="info-icon grow self-center" @click="ui.showDdModal = true">
              <img src="../../../public/static/img/info-icon-grey.svg">
            </div>
          </div>
        </div>
        <div class="mt-6 verischerungs-title">
          <div class="relative">
            <span>Deine Top-Versicherungsformen</span>
            <VDropdown
              :distance="35"
              :triggers=[]
              :shown="showInfo"
              :placement="'right'"
              :class="'absolute cursor-pointer top-[25%] right-[15%]'"
            >
              <div>
                <img @click.stop="showInfo = !showInfo" class="w-5 h-5 z-20" :src="'static/img/info-icon-grey.svg'">
              </div>
              <template #popper>
                <div class="w-[420px] p-4 overflow-hidden">
                  <div class="flex flex-col">
                    <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                      <div class="popper-text mt-7">
                        <strong>Wie kommt das Ranking zustande?</strong><br><br>
                        Das Ranking der Top-Versicherungsformen ergibt sich aus der Gewichtung der Leistungen und Bedingungen der jeweiligen Versicherung im Hinblick auf die von dir gewählten Präferenzen. Das Bewertungsmodell berücksichtigt dabei positiv, wenn Versicherungen wie z.B. Erwerbsunfähigkeitsversicherung und Berufsunfähigkeitsversicherung unabhängig von der Krankheitsursache leisten.
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
        <transition-group name="flip-list" class="mt-6 flex flex-col gap-3" tag="div">
          <div 
            v-for="(item, index) of $store.compVersicherungsRanking" 
            :key="item.id" 
            class="transition-all duration-1000 ease-in-out" 
            :class="item.hidden ? 'opacity-0 h-0' : 'opacity-100 h-auto'" 
            @click="compVersicherungsFormenActive && handleClick(item);updateData();item.clicked = true;">
            <div 
              class="versicherungs-container flex flex-row justify-between" 
              :style="{
                borderColor: compVersicherungsFormenActive ? item.bgColor : '#DCDCDC',
                background: item.active ? item.bgColor : 'transparent', 
                color: item.active ? 'white' : '#737B7D', 
                opacity: compVersicherungsFormenActive ? '1' : '0.6'
              }" 
              :class="{'active': compVersicherungsFormenActive}"
            >
              <div>Platz {{index+1}} | {{item.title}}</div>
              <VDropdown
                :distance="35"
                :triggers=[]
                :shown="item.showInfo"
                :placement="'right'"
              >
                <div>
                  <img @click.stop="item.showInfo = !item.showInfo" class="w-5 h-5 z-20" :src="item.active ? 'static/img/info-icon-white.svg' : 'static/img/info-icon-grey.svg'">
                </div>
                <template #popper>
                  <div class="w-[420px] p-4 overflow-hidden">
                    <div class="flex flex-col" v-if="item.id==1">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Berufsunfähigkeits&shy;versicherung</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Eine Berufsunfähigkeitsversicherung (BU) sichert deine Arbeitskraft umfassend ab und zahlt dir eine monatliche Rente, wenn du aus gesundheitlichen Gründen nicht mehr deinen aktuellen Beruf ausüben kannst. Sie gehört zu den wichtigsten Absicherungen für alle die, die auf ihr Arbeitseinkommen angewiesen sind. Generell gilt: Je früher und gesünder sie abgeschlossen wird, desto günstiger sind die Beiträge.
                          <br><br>
                          <strong>Zahlung bei Berufsunfähigkeit</strong><br>
                          Eine Berufsunfähigkeitsversicherung (BU) zahlt ab einem ärztlich festgestellten Berufsunfähigkeitsgrad von 50 % eine monatliche Rente. Wenn du also wegen Krankheit oder Unfall deine aktuelle Tätigkeit für mindestens sechs Monate nicht mehr ausüben kannst, bekommst du jeden Monat die vertraglich vereinbarte BU-Rente ausgezahlt. In der Regel solltest du die Laufzeit deiner BU bis zum Renteneintritt wählen, aktuell also bis zum 67. Lebensjahr. Solltest du in der Zeit, in der du die BU-Rente bekommst, wieder gesund werden oder dich ins Berufsleben wiedereingliedern, kann die Rentenzahlung vorzeitig enden.
                          <br><br>
                          <strong>Zahlung bei Erwerbsunfähigkeit</strong><br>
                          Eine Berufsunfähigkeitsversicherung (BU) zahlt auch eine monatliche Rente, wenn du erwerbsunfähig bist. „Erwerbsunfähig“ ist man, sobald man weniger als drei Stunden täglich in <strong>irgendeinem Job</strong> arbeiten kann. Im Gegensatz dazu bedeutet „berufsunfähig“, dass man den <strong>aktuellen Job</strong> nicht mehr ausüben kann.
                          <br><br>
                          <strong>Zahlung bei Verlust von Grundfähigkeiten</strong><br>
                          Eine Berufsunfähigkeitsversicherung (BU) zahlt eine monatliche Rente, sobald ein Verlust von Grundfähigkeiten dazu führt, dass du deinen aktuellen Beruf nicht mehr ausüben kannst. Als Grundfähigkeiten werden elementare Fähigkeiten wie hören, sehen, gehen oder auch der Gebrauch der Hände bezeichnet. 
                          <br><br>
                          <strong>Zahlung bei schweren Krankheiten</strong><br>
                          Schwere Krankheiten wie Krebs oder ein Herzinfarkt führen häufig zur Berufsunfähigkeit. Auch dann zahlt die BU eine monatliche Rente. Die Diagnose „berufsunfähig“ ist dabei nicht an bestimmte Krankheiten oder andere Auslöser gebunden. In manchen Fällen können Betroffene nach ihrer Genesung wieder arbeiten. In diesem Fall endet die BU-Zahlung.
                        </div>
                      </div>
                      <!--div class="self-end mt-4 cursor-pointer">Mehr Informationen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div-->
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==2">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Erwerbsunfähigkeits&shy;versicherung</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Eine Erwerbsunfähigkeitsversicherung (EU) ist eine mögliche Alternative zur Berufsunfähigkeitsversicherung (BU). Sie zahlt dir eine monatliche Rente, wenn du aus gesundheitlichen Gründen <strong>weniger als drei Stunden täglich</strong> in <strong>irgendeinem Beruf</strong> arbeiten kannst. Die EU ist für alle geeignet, die aufgrund eines risikoreichen Berufs keine oder nur eine teure Berufsunfähigkeitsversicherung (BU) bekommen oder die Vorerkrankungen haben. Wie bei der BU gilt auch hier: Je früher du abschließt, desto günstiger sind die Beiträge. 
                          <br><br>
                          <strong>Zahlung bei Berufsunfähigkeit</strong><br>
                          Eine Erwerbsunfähigkeitsversicherung (EU) zahlt eine monatliche Rente, wenn du weniger als drei Stunden täglich arbeiten kannst. Aber nicht, wenn du „berufsunfähig“ bist – das heißt, deinen <strong>letzten ausgeübten Job</strong> nicht mehr machen kannst. Trotz geleisteter Beitragszahlungen springt sie erst ein, wenn du tatsächlich in <strong>gar keinem Job</strong> mehr arbeiten kannst. 
                          <br><br>
                          <strong>Zahlung bei Erwerbsunfähigkeit</strong><br>
                          Eine Erwerbsunfähigkeitsversicherung (EU) zahlt eine monatliche Rente, wenn du aufgrund von Krankheit oder Unfall nicht mehr in der Lage bist, mindestens drei Stunden täglich einen Beruf auszuüben – ganz egal welcher. Je nach Anbieter können dabei nicht nur körperliche, sondern auch psychische Erkrankungen abgedeckt werden. 
                          <br><br>
                          <strong>Zahlung bei Verlust von Grundfähigkeiten</strong><br>
                          Eine Erwerbsunfähigkeitsversicherung (EU) zahlt eine monatliche Rente, wenn der Verlust von Grundfähigkeiten zur Erwerbsunfähigkeit führt und du weniger als drei Stunden am Tag arbeiten kannst. Der aktuelle Job ist dabei nicht relevant. Es zählt alleine, ob du überhaupt noch in der Lage bist zu arbeiten. 
                          <br><br>
                          <strong>Zahlung bei schweren Krankheiten</strong><br>
                          Eine Erwerbsunfähigkeitsversicherung (EU) zahlt auch bei schweren Krankheiten wie zum Beispiel Krebs eine monatliche Rente. Aber nur, wenn die Krankheit zur Erwerbsunfähigkeit führt. „Erwerbsunfähig“ bist du im Sinne einer EU, wenn du nicht mehr mindestens drei Stunden am Tag arbeiten kannst – egal in welchem Job. 
                        </div>
                      </div>
                      <!--div class="self-end mt-4 cursor-pointer">Mehr Informationen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div-->
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==3">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Grundfähigkeits&shy;versicherung</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Eine Grundfähigkeitsversicherung ist ein guter Basis-Schutz zur Absicherung deiner Arbeitskraft. Sie zahlt dir eine monatliche Rente, wenn du bestimmte körperliche oder geistige Grundfähigkeiten verlierst. Körperliche Grundfähigkeiten sind zum Beispiel sehen, gehen oder hören, zu den geistigen Fähigkeiten gehören unter anderem Gleichgewicht halten, orientieren oder eigenverantwortlich handeln. In einem Fähigkeitenkatalog wird genau definiert, welche Grundfähigkeiten versichert sind und welche nicht. Elementare Fähigkeiten sind meist automatisch eingeschlossen, weitere können in der Regel zusätzlich abgesichert werden. 
                          <br><br>
                          <strong>Zahlung bei Berufsunfähigkeit</strong><br>
                          Eine Grundfähigkeitsversicherung zahlt eine monatliche Rente, wenn du eine körperliche oder geistige Grundfähigkeit verlierst. Dabei spielt es keine Rolle, ob du trotz des Verlusts weiterhin berufsfähig bist. Wichtig: Eine Leistung gibt es nur dann, wenn die Grundfähigkeit auch vertraglich abgesichert ist. 
                          <br><br>
                          <strong>Zahlung bei Erwerbsunfähigkeit</strong><br>
                          Eine Grundfähigkeitsversicherung zahlt beim Verlust einer körperlichen oder geistigen Grundfähigkeit monatlich eine Rente. Es ist dabei nicht entscheidend. ob du weiterhin erwerbsfähig bist. Ausschlaggebend für den Leistungsfall ist der Einschluss der Grundfähigkeit im Vertrag.
                          <br><br>
                          <strong>Zahlung bei Verlust von Grundfähigkeiten</strong><br>
                          Eine Grundfähigkeitsversicherung zahlt dir genau für diesen Fall eine monatliche Rente. Wichtig ist dabei, dass die eingebüßte Grundfähigkeit im Fähigkeitenkatalog der Police enthalten und somit auch zu 100 % abgesichert ist. 
                          <br><br>
                          <strong>Zahlung bei schweren Krankheiten</strong><br>
                          Eine Grundfähigkeitsversicherung springt auch ein und zahlt eine monatliche Rente, wenn eine schwere Krankheit zum Verlust einer Grundfähigkeit führt. Auch hier gilt: Nur vertraglich definierte Grundfähigkeiten sind versichert und führen zum Leistungsfall. 
                        </div>
                      </div>
                      <!--div class="self-end mt-4 cursor-pointer">Mehr Informationen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div-->
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==4">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Dread Disease Versicherung</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Eine Dread Disease Versicherung ist eine Alternative für alle, die weder eine Berufsunfähigkeitsversicherung (BU), noch eine Erwerbsunfähigkeitsversicherung (EU) bekommen. „Dread Disease“ heißt übersetzt „gefürchtete Krankheit“. Versicherer verstehen darunter schwere Krankheiten wie Krebs, Herzinfarkt oder Multiple Sklerose. Wird eine solche Krankheit diagnostiziert, zahlt die Dread Disease Versicherung einmalig eine Summe in der vertraglich festgelegten Höhe. Im Gegensatz zu BU, EU oder Grundfähigkeitsversicherung bekommst du keine monatliche Rente. 
                          <br><br>
                          <strong>Zahlung bei Berufsunfähigkeit</strong><br>
                          Eine Dread Disease Versicherung zahlt dir einmalig eine Summe, wenn du eine Krankheit bekommst, die im Vertrag abgesichert ist. Dabei ist nicht relevant, ob weiterhin deinen aktuellen Job machen kannst. Nach Zahlung der Einmalsumme endet der Vertrag und es gibt keine weitere Leistung. Auch nicht im Fall, dass du erneut krank wirst. Da viele schwere Krankheiten äußerst selten sind, bietet die Dread Disease Versicherung wenig zusätzlichen Nutzen als Absicherung gegen Berufsunfähigkeit. Die am häufigsten auftretenden Ursachen für Berufsunfähigkeit – psychische Erkrankungen und chronische Rückenbeschwerden – sind nicht versichert.
                          <br><br>
                          <strong>Zahlung bei Erwerbsunfähigkeit</strong><br>
                          Eine Dread Disease Versicherung schützt dich nur, wenn bei dir eine Krankheit diagnostiziert wird, die du vertraglich abgesichert hast. Ob du weiterhin erwerbsfähig bleibst, spielt hierbei keine Rolle. Nach Leistung einer Einmalzahlung endet der Schutz. Wirst du erneut krank, springt die Dread Disease Versicherung nicht wieder ein.
                          <br><br>
                          <strong>Zahlung bei Verlust von Grundfähigkeiten</strong><br>
                          Eine Dread Disease Versicherung zahlt dir einmalig einen Betrag, wenn du eine schwere Krankheit bekommst. Wichtig dabei: Nur vertraglich festgelegte schwere Krankheiten sind versichert. Verlierst du eine Grundfähigkeit, zum Beispiel sehen, hören oder den Gebrauch deiner Hände, leistet die Dread Disease Versicherung nicht, auch nicht, wenn du erneut eine schwere Krankheit bekommst.
                          <br><br>
                          <strong>Zahlung bei schweren Krankheiten</strong><br>
                          Eine Dread Disease Versicherung ist genau dann für dich da, wenn du eine schwere Krankheit bekommst. Voraussetzung ist, dass es sich um eine vertraglich abgesicherte Krankheit handelt. Dann bekommst du einmalig eine Summe ausbezahlt, um medizinische Behandlungen zu finanzieren, weiterhin Kredite zu bedienen oder dein Geschäft weiterzuführen, falls du selbstständig bist. Beachte: Eine regelmäßige, monatliche Rente ist bei der Dread Disease Versicherung grundsätzlich ausgeschlossen. 
                        </div>
                      </div>
                      <!--div class="self-end mt-4 cursor-pointer">Mehr Informationen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div-->
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==5">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">KRANKENGELD &amp; PRIVATE KRANKENTAGEGELDVERSICHERUNG</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Das Krankengeld der gesetzlichen Krankenversicherungen wird temporär über einen Zeitraum von maximal 78 Wochen bei ein und derselben Krankheit innerhalb von drei Jahren gezahlt. Das Krankengeld kann in Kombination mit einer anschließenden Berufsunfähigkeitsversicherung den Verlust deines Einkommens teilweise absichern. Du kannst das gesetzliche Krankengeld mit einer <strong>Krankentagegeldversicherung</strong> erhöhen. Ob dieser zusätzliche Schutz für dich passt, hängt davon ab, ob du angestellt oder selbständig und gesetzlich oder privat krankenversichert bist. Bist du <strong>angestellt und gesetzlich versichert</strong>, kannst du mit ihr nach einer 6-wöchigen Lohnfortzahlung deines Arbeitgebers das gesetzliche Krankengeld erhöhen. Denn das gesetzliche Krankengeld ist in der Regel rund 20 % niedriger als dein Nettoeinkommen. Bist du <strong>angestellt und privat versichert</strong>, endet die Lohnfortzahlung nach 6 Wochen und es gibt danach kein Krankengeld. Eine Krankentagegeldversicherung sichert also auch darüber hinaus deinen Lebensstandard ab. Bist du <strong>selbständig und gesetzlich versichert</strong>, hast du die Wahl: Entscheidest du dich für ein Krankengeld, bekommst du nach dem 43. Tag rund 70 % deines regelmäßigen Einkommens von der gesetzlichen Krankenkasse. Dafür steigt aber dein Beitragssatz. Brauchst du schon vor dem 43. Tag Geld, ist eine Krankentagegeldversicherung sinnvoll. Ebenso, wenn du dich gegen das Krankengeld entscheidest. Bist du <strong>selbständig und privat versichert</strong>, kannst du die Krankentagegeldversicherung bei deiner privaten Krankenversicherung oder einem günstigeren Anbieter abschließen. 
                          <br><br>
                          <strong>Zahlung bei Berufsunfähigkeit</strong><br>
                          Du erhältst Krankengeld bzw. Krankentagegeld, wenn du gar nicht mehr arbeiten kannst. Ob du dabei deinen aktuellen Job oder irgendeinen Job nicht mehr ausüben kannst, ist dabei unerheblich. Achtung: Als Selbständiger solltest du mit der Krankentagegeldversicherung abklären, ob du bestimmte arbeitsbezogene Tätigkeiten auch während deiner Krankschreibung ausführen darfst, ohne den Leistungsfall zu gefährden.
                          <br><br>
                          <strong>Zahlung bei Erwerbsunfähigkeit</strong><br>
                          Das Krankengeld bzw. die Krankentagegeldversicherung springt ein, wenn du krankheitsbedingt nicht mehr arbeiten kannst – unabhängig von deiner weiteren Erwerbsfähigkeit. Wichtig für Selbständige: Der Leistungsfall kann erlöschen, wenn du zum Beispiel im Krankenhaus geschäftliche E-Mails an deine Mitarbeiter verschickst oder dich anderweitig um dein Unternehmen kümmerst. Sprich am besten vorher mit deiner Versicherung, welche Tätigkeiten möglich sind.
                          <br><br>
                          <strong>Zahlung bei Verlust von Grundfähigkeiten</strong><br>
                          Das Krankengeld bzw. Krankentagegeldversicherung sichert dich ab, wenn du aufgrund einer Krankheit überhaupt nicht mehr arbeitsfähig bist. Ob du eine Grundfähigkeit wie zum Beispiel sehen, gehen oder hören eingebüßt hast, spielt keine Rolle. Als Selbständiger solltest du dich vorher bei deiner Versicherung erkundigen, welche arbeitsbezogenen Tätigkeiten du während deiner Krankschreibung weiterhin für deinen Betrieb erledigen darfst, ohne den Versicherungsschutz zu gefährden.
                          <br><br>
                          <strong>Zahlung bei schweren Krankheiten</strong><br>
                          Das Krankengeld bzw. die Krankentagegeld erhältst du, wenn du krankheitsbedingt ausfällst und nicht mehr arbeiten kannst. Wichtig zu wissen: Im Gegensatz zur gesetzlichen Krankenversicherung wird deine Gesundheit bei der privaten Krankentagegeldversicherung geprüft – und du kannst aufgrund von Vorerkrankungen eventuell auch abgelehnt werden.
                        </div>
                      </div>
                      <!--div class="self-end mt-4 cursor-pointer">Mehr Informationen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div-->
                    </div>
                  </div>
                </template>
              </VDropdown>
            </div>
          </div>
        </transition-group>
      </div>
    </template>
  </controll-wrapper>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue";
import AppSlider from '@/components/AppSlider.vue'
import Modal from '@/components/modal.vue'

export default {
  name: 'Einkommenschutz_3',
  components: {
    ControllWrapper,
    AppSlider,
    Modal
  },
  data() { return {
    state: this.$store.state,
    showInfo: false,
    ui: {
      showGfModal: false,
      showEuModal: false,
      showBuModal: false,
      showDdModal: false,
    }
  }},
  mounted() {
    this.updateData();
  },
  computed: {
    compVersicherungsFormenActive() {
      for (let n in this.state.app.einkommensschutz.model ) {
        if (this.state.app.einkommensschutz.model[n]) return true
      }
      return false
    }
  },
  methods: {
    handleClick(item) {
      if (!item.active) {
        this.state.app.einkommensschutz.versicherungsFormen.forEach(elem => {
          elem.active = false;
        });
      }
      item.active = !item.active;
    },
    updateData(data) {
      this.$emit('updateData')
    }
  },
  beforeDestroy() {
    for (let item of this.state.app.einkommensschutz.versicherungsFormen) {
      item.showInfo = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.flip-list-move {
  transition: all 1s;
}
.flip-list-enter-active {
  transition: all 1s;
}
.flip-list-leave-active {
  transition: all 1s;
}
.flip-list-enter {
  opacity:0!important
}
.flip-list-leave-to {
  opacity:0!important
}
.modal-scrollbar {
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.4px;
    color: #1E86CB;
  }
  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #373F41;
  }
  .more {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #737B7D;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #737B7D;
    border: 0px none #a62121;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #737B7D;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #737B7D;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #b52626;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.checkbox-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.7px;
  color: #737B7D;
  padding-left:4px
}
.info-icon {
  text-align:right
  img {
    min-width:20px
    min-height:20px
    display:inline-block
    cursor:pointer
    margin-top: -4px;
  }
}
.verischerungs-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.1px;
  color: #373F41;
}
.versicherungs-container {
  padding: 15px 19px;
  gap: 12px;
  width: 100%;
  background: #FFFFFF;
  border: 2px solid #DCDCDC;
  border-radius: 3px;
  cursor:pointer
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.7px;
  color: #DCDCDC;
  user-select:none;
  &.active {
    color: #737B7D
  }
}
.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
.control_indicator {
    position: absolute;
    top: 0px;
    left: 0;
    height: 22px;
    width: 22px;
    background: #ffffff;
    border: 2px solid #D9D9D9;
    border-radius: 4px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #ffffff;
}

.control input:checked ~ .control_indicator {
    background: #ffffff;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #ffffff;
}
.control input:disabled ~ .control_indicator {
    background: #c7c7c7;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 10px;
    top: -12px;
    width: 7px;
    height: 24px;
    border: solid #5889da;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #ffffff;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #3c67e3;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}
@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
   0% {
       transform: scale(0);
    }
   30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}
</style>
