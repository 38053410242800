<template>
  <controll-wrapper class="flex flex-col" title="Wie sehen deine Präferenzen für den Vermögensaufbau aus?">
    <template v-slot:content>
      <div class="pl-10 pr-10 pt-1 flex flex-col h-full flex-grow">
        <div class="flex flex-row gap-y-4 gap-x-6 checkboxes">
          <div class="basis-1/2 flex flex-row items-baseline">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" @change="checkIfOneIsActive()" v-model="state.app.vermoegen.model.ohneAbgeltungssteuer" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Abgeltungssteuer<br>
              vermeiden
            </div>
            <div class="info-icon grow self-center" @click="ui.showAbgeltungModal = true">
              <img src="../../../public/static/img/info-icon-grey.svg">
            </div>
            <modal v-model="ui.showAbgeltungModal">
              <div class="overflow-auto">
                <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_AbgeltungssteuerEffekt.jpg">
              </div>
            </modal>
          </div>
          <div class="basis-1/2 flex flex-row items-baseline">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" @change="checkIfOneIsActive()" v-model="state.app.vermoegen.model.flexibel" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Flexibel ein- und<br>
              auszahlen
            </div>
          </div>
        </div>
        <div class="flex flex-row mt-6 gap-x-6 checkboxes">
          <div class="basis-1/2 flex flex-row items-baseline">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" @change="checkIfOneIsActive()" v-model="state.app.vermoegen.model.inflationsschutz" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Inflationsgeschützt<br>
              sparen
            </div>
            <div class="info-icon grow self-center" @click="ui.showInflationModal = true">
              <img src="../../../public/static/img/info-icon-grey.svg">
            </div>
            <modal v-model="ui.showInflationModal">
              <div class="overflow-auto">
                <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_Inflationssicherheit.jpg">
              </div>
            </modal>
          </div>
          <div class="basis-1/2 flex flex-row items-baseline" :class="{ 'opacity-70': !$store.compFondsrentenVisible }">
            <div class="">
              <label class="control control-checkbox">
                <input type="checkbox" @change="checkIfOneIsActive()" v-model="state.app.vermoegen.model.lebenslangeRente" :disabled="!$store.compFondsrentenVisible" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div class="checkbox-text">
              Lebenslange Renten-<br>
              zahlung ermöglichen
            </div>
            <div class="info-icon grow self-center"  @click="ui.showRentenModal = true">
              <img src="../../../public/static/img/info-icon-grey.svg">
            </div>
            <modal v-model="ui.showRentenModal">
              <div class="overflow-auto">
                <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_Rentenversicherung.jpg">
              </div>
            </modal>
          </div>
        </div>
        <div class="mt-6 sparformen-title flex flex-row items-center gap-2">
          <div>Deine Top-Sparformen</div>
          <VDropdown
            :distance="35"
            :triggers=[]
            :shown="ui.showSparformenInfo"
            :placement="'right'"
          >
            <div>
              <img @click.stop="ui.showSparformenInfo = !ui.showSparformenInfo" class="w-5 h-5 z-20 cursor-pointer" src="../../../public/static/img/info-icon-grey.svg">
            </div>
            <template #popper>
              <div class="w-[420px] p-4 overflow-hidden">
                <div class="flex flex-col">
                  <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                    <div class="text">
                      <div class="">
                        <strong>Wie kommt das Ranking zustande?</strong>
                        <br>
                        Beim Ranking werden die Leistungen der jeweiligen Sparformen betrachtet – und in welchem Umfang sie deine Präferenzen erfüllen. Das Bewertungsmodell berücksichtigt dabei auch, wenn Sparformen deinen Wünschen und Zielen nicht entsprechen. 
                        <br><br>
                        Achtung: In der Grafik werden historische Wertentwicklungen dargestellt, keine Prognosen. Historische Wertentwicklungen stellen keinen verlässlichen Indikator für Prognosen zum zukünftigen Verlauf der Wertentwicklung dar. Anlagen verfügen grundsätzlich über Renditechancen, enthalten aber auch immer ein Verlustrisiko.
                      </div>
                    </div>
                  </div>
                  <div @click="ui.showSparformenInfo = false;linkInModal()" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
        <transition-group name="flip-list" class="sparformen-container px-2 -mx-2 scroll mt-1 flex flex-col overflow-auto max-h-[350px]" tag="div">
          <div v-for="(item, index) of $store.compSparformenRanking" :key="item.id" @click="compSparformenActive && (item.active=!item.active);updateData()" :class="item.hidden ? 'opacity-0 h-0' : 'opacity-100 mt-3 h-auto'">
            <div class="sparform-container flex flex-row justify-between" :style="{borderColor: compSparformenActive ? item.bgColor : '#DCDCDC', background: (item.active || item.autoActive) ? item.bgColor : 'transparent', color: (item.active || item.autoActive) ? 'white' : '#737B7D', opacity: compSparformenActive ? '1' : '0.6'}" :class="{'active': compSparformenActive}">
              <div>Platz {{index+1}} | {{item.title}}
              <span v-if="item.active || item.autoActive" class="font-normal"><br>(&#8709; Rendite von {{ compYearSpan }}: {{ item.durchschnittlicheRendite }}% p. a.)</span></div>
              <VDropdown
                :distance="35"
                :triggers=[]
                :shown="item.showInfo"
                :placement="'right'"
              >
                <div>
                  <img @click.stop="item.showInfo = !item.showInfo" class="w-5 h-5 z-20" :src="(item.active || item.autoActive) ? 'static/img/info-icon-white.svg' : 'static/img/info-icon-grey.svg'">
                </div>
                <template #popper>
                  <div class="w-[420px] p-4 overflow-hidden">
                    <div class="flex flex-col" v-if="item.id==1">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Die Fondsrente.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Eine Fondsrente ohne Beitragsgarantie ist eine langfristige Sparform in Investmentfonds mit hohen Renditechancen. Allerdings garantieren dir die Versicherer nicht die Höhe der späteren Auszahlung. Du gehst also ein Risiko ein, nicht 100 % der eingezahlten Beträge zurückzubekommen. Doch keine Sorge – je länger die Ansparphase ist, um so geringer wird das Verlustrisiko.
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Ja, die Fondsrente ohne Beitragsgarantie bietet dir die Sicherheit einer späteren lebenslangen Rente und gleichzeitig Chancen auf hohe Rendite an der Börse. Dank des <b>Zinseszins-Effektes</b> hast du schon mit kleinen Sparbeträgen die Chance auf ein Vermögen im Alter - wenn du früh genug mit dem Sparen beginnst. Du hast die Wahl, das angesammelte Kapital nach Ablauf der Ansparphase in eine monatliche lebenslange Rente umzuwandeln – oder es dir einmalig komplett auszahlen zu lassen.
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Die Fondsrente ohne Beitragsgarantie ist steuerlich begünstigt, wenn du <b>mindestens zwölf Jahre lang</b> sparst und dir das Geld <b>nach dem 62. Lebensjahr</b> auszahlen lässt (bei Verträgen, die vor 2012 abgeschlossen wurden, kann die Auszahlung bereits nach dem 60. Lebensjahr erfolgen). Du musst dann während der Vertragslaufzeit auch keine Abgeltungssteuer zahlen. Löst du die Versicherung vorher auf, werden 25 % Abgeltungssteuer auf die Kapitalerträge fällig. Wenn du dir am Ende der Ansparphase dein Erspartes einmalig auszahlen lässt, musst du nur die Hälfte der Erträge versteuern. Die andere Hälfte bekommst du steuerfrei. 
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Die Fondsrente ohne Beitragsgarantie ist gut geeignet, um langfristig zu sparen. Sie sollte bis zum Renteneintritt laufen und nicht vorher gekündigt werden, sonst werden Abschläge fällig. Du kannst während der Ansparphase Teile deiner Erträge switchen, das heißt, die Fonds, in die du investierst, wechseln. Flexible Einzahlungen sind ebenfalls möglich. Auszahlungen bzw. Entnahmen aus dem Guthaben sind möglich, aber dran denken: Vor Ende der Ansparphase musst du dann 25 % Abgeltungssteuer auf die Erträge zahlen.
                          <br><br>
                          <strong>Ist die Fondsrente ohne Beitragsgarantie sicher vor Inflation?</strong><br>
                          Mit Inflation ist das Ansteigen der Verbraucherpreise und damit der Kaufkraftverlust deines Geldes gemeint. Bei einer hohen Inflation kannst du also weniger für dein Geld kaufen als bei einer niedrigen. Die Fondsrente investiert langfristig am Kapitalmarkt. Deine Sparbeträge fließen dabei meist auch in Unternehmen, die an der Börse notiert sind. Diese Unternehmen bzw. deren Anteile müssen nicht zwangsläufig von der Inflation betroffen sein. Das Ziel der Fondsrente ohne Beitragsgarantie ist, eine Rendite über der Inflationsrate zu erreichen. Je nachdem, wie sie investiert, kann sie das schaffen. Garantiert ist es allerdings nicht.
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal('#modal-fondspolice-ohne-garantie')" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==2">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Die Fondsrente mit Garantie.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Eine Fondsrente mit Beitragsgarantie ist eine langfristige Sparform, bei der die finanzielle Altersvorsorge mit chancenreichen Aktieninvestments kombiniert wird. Die Beitragsgarantie sorgt für Sicherheit – beträgt sie z. B. 100 %, bekommst du bei Rentenbeginn dein eingezahltes Kapital auf jeden Fall zu 100 % wieder heraus. Bei 90 %, 80 % etc. ist es entsprechend. Die Beitragsgarantie bietet dir zwar Sicherheit, verhindert aber bessere Renditechancen in den Fondsanlagen, da dein Geld zu einem mehr oder weniger großen Teil in einem Sicherungsvermögen mit wenig Renditechancen angelegt wird.
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Ja, die Fondsrente mit Beitragsgarantie bietet dir die Sicherheit einer späteren lebenslangen Rente und gleichzeitig Chancen auf Rendite an der Börse. Dank des <b>Zinseszins-Effektes</b> hast du bereits mit kleinen Sparbeträgen die Chance auf ein Vermögen im Alter - wenn du früh genug mit dem Sparen beginnst. Du hast die Wahl, das angesammelte Kapital nach Ablauf der Ansparphase in eine monatliche lebenslange Rente umzuwandeln – oder dir die Erträge einmalig komplett auszahlen zu lassen. Für die Rente gilt die günstige Ertragsanteilbesteuerung.
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Die Fondsrente mit Beitragsgarantie ist steuerlich begünstigt, wenn du <b>mindestens zwölf Jahre lang</b> sparst und dir das Geld <b>nach dem 62. Lebensjahr</b> auszahlen lässt (bei Verträgen, die vor 2012 abgeschlossen wurden, kann die Auszahlung bereits nach dem 60. Lebensjahr erfolgen). Du musst dann während der Vertragslaufzeit auch keine Abgeltungssteuer zahlen. Löst du die Versicherung vorher auf, werden 25 % Abgeltungssteuer auf die Kapitalerträge fällig. Wenn du dir am Ende der Ansparphase dein Erspartes einmalig auszahlen lässt, musst du nur die Hälfte der Erträge versteuern. Die andere Hälfte bekommst du steuerfrei. 
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Die Fondsrente mit Beitragsgarantie ist ein Produkt für langfristiges Sparen. Sie sollte bis zum Renteneintritt laufen und nicht vorher gekündigt werden, denn dann werden Abschläge fällig. Abhängig vom Anbieter kannst du während der Ansparphase entweder Teile oder die gesamte Anlage switchen. Das heißt, die Fonds, in die du investierst, wechseln – und das steuerfrei! Flexible Einzahlungen sind ebenfalls möglich. Auszahlungen bzw. Entnahmen aus dem Guthaben sind möglich, aber dran denken: vor Ende der Ansparphase musst du dann 25 % Abgeltungssteuer auf die Erträge zahlen.
                          <br><br>
                          <strong>Ist die Fondsrente mit Beitragsgarantie sicher vor Inflation?</strong><br>
                          Mit Inflation ist das Ansteigen der Verbraucherpreise und damit der Kaufkraftverlust deines Geldes gemeint. Bei hoher Inflation ist dein Geld also weniger wert als in Zeiten niedriger Inflation. Die Fondsrente mit Beitragsgarantie investiert zwar langfristig am Kapitalmarkt zum überwiegenden Teil auch in Unternehmen, die an der Börse notiert und meist nicht von der Inflation betroffen sind. Durch die Beitragsgarantie fließt jedoch ein Großteil in ein weniger renditeorientiertes Sicherungsvermögen, welches die Inflation auch auf lange Sicht nicht vollständig ausgleichen kann.
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal('#modal-fondspolice-mit-garantie')" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==3">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Der Fondssparplan.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Ein Fondssparplan ist eine regelmäßige – beispielsweise monatliche – Einzahlung in ein Fondsdepot bei einer Bank oder einem Online-Broker. Die Fonds werden entweder aktiv gemanagt oder bilden einen Index nach (so genannte „ETFs – Exchange Traded Funds“). Da Aktienfonds aus vielen Aktien bestehen, die am Kapitalmarkt gehandelt werden, unterliegen sie börsenüblichen Schwankungen. Deshalb solltest du einen Fondssparplan auf lange Sicht anlegen. 
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Jein, ein Fondssparplan bietet dir zwar die Möglichkeit, im Alter lebenslang Geld daraus entnehmen zu können – aber nur, wenn er auch ausreichend Vermögen beinhaltet und regelmäßig Rendite produziert. Durch die Schwankungen am Kapitalmarkt gibt es aber keine Garantie einer lebenslangen Rente. Plane deshalb gut, wie lange und wie viel Rente du später monatlich aus deinem Fondsdepot entnehmen willst. Gut zu wissen: Statistisch gesehen werden wir im Durchschnitt sieben Jahre älter, als von den meisten angenommen! 
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Seit 2009 werden Gewinne aus Fondssparplänen mit 25 % Abgeltungssteuer zuzüglich Solidaritätszuschlag und Kirchensteuer belegt. Auch Dividenden- und Zinszahlungen unterliegen dieser Steuer – egal, wann die Fondsanteile gekauft wurden. Hinzu kommt, dass jede Anpassung des Portfolios, also das Umschichten und Wechseln der Fonds, ebenfalls zur Steuerpflicht führt.  
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Ein Fondssparplan ist eine langfristige Art des Vermögensaufbaus. Du kannst die Sparraten jederzeit vorübergehend aussetzen, erhöhen, reduzieren oder ganz stoppen. Du bestimmst und es gibt keine Kündigungsfristen. Du kannst die Gewinne auch zum Weitersparen wieder anlegen oder dir regelmäßig was davon auszahlen lassen. In der Auszahlphase solltest du aber darauf achten, dass du einen möglichst guten „Ausstiegszeitpunkt“ erwischst, in dem deine Aktienfonds einen hohen Börsenwert haben. 
                          <br><br>
                          <strong>Ist der Fondssparplan sicher vor Inflation?</strong><br>
                          Die Inflation ist der sinkende Wert deines Geldes aufgrund höherer Verbraucherpreise. Je höher die Inflation, desto weniger kannst du dir für dein Geld kaufen. Mit einem Fondssparplan investierst du langfristig in den Kapitalmarkt. Deine Sparbeträge fließen dabei meist auch in börsennotierte Unternehmen. Diese Unternehmen bzw. deren Wertpapiere müssen nicht zwangsläufig von der Inflation betroffen sein. Das Ziel des Fondssparplans ist es, eine Rendite zu erreichen, die über der Inflationsrate liegt. Je länger du investierst, desto höher ist die Chance, das zu erreichen. 
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal('#modal-fondssparplan')" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==4">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Immobilien.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Immobilien sind eine gute Möglichkeit, langfristig Vermögen aufzubauen und fürs Alter vorzusorgen. Immobilien gehören zu den wertstabilen Geldanlagen und bieten dir gleichzeitig Renditechancen und Steuervorteile. Bevor du eine Immobilie finanzierst, solltest du mindestens 15 bis 20  % des Kaufpreises als Eigenkapital angespart haben. Dazu eignet sich etwa ein monatlicher Dauerauftrag bei einem Bankkonto. Den restlichen Kaufpreis finanzierst du dann über ein Darlehen, ebenfalls zum Beispiel bei einer Bank.
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Ja, eine Immobilie bietet dir die Möglichkeit einer späteren lebenslangen Rente. Besitzt du ein so genanntes „Betongold“, kannst du entweder ein Leben lang mietfrei darin wohnen oder deine Immobile vermieten und die Mieteinnahmen für deine Rente verwenden. Eine weitere Option ist, deine Immobilie zu verkaufen und dennoch lebenslang darin wohnen zu bleiben – möglich ist das mit der so genannten „Immobilienrente“. Den Erlös aus dem Verkauf kannst du dir je nach Wunsch und Modell auf einmal, in Raten, als lebenslange Leib¬rente ¬oder zeitlich befristet auszahlen lassen. Du kannst deine Immobilie auch verkaufen, den Erlös in eine so genannte „Sofortrente“ bei einer Rentenversicherung einzahlen und es dir garantiert lebenslang auszahlen lassen. 
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Auf Erträge aus deinem Immobilienvermögen zahlst du grundsätzlich keine Abgeltungssteuer. Je nachdem, wie du sie nutzt, bringt dir eine Immobilie als Altersvorsorge steuerliche Vorteile: Nutzt du sie als Kapitalanlage, kannst du Kaufpreis, Darlehenszinsen, Nebenkosten sowie Kosten für Instandhaltungen und Reparaturen von der Steuer absetzen. Willst du selbst darin wohnen, kannst du bis zu 20  % der Handwerkerkosten und maximal 1.200 Euro jährlich von der Steuer absetzen. Beim so genannten „Wohn-Riester“ kannst du deine Traumimmobilie staatlich gefördert finanzieren („Eigenheimrente“). Willst du deine Immobilie verkaufen, zahlst du keine Steuern auf den Gewinn – aber nur, wenn du selber darin gewohnt hast. Hast du deine Immobilie vermietet oder beruflich genutzt, ist der Verkauf erst nach zehn Jahren steuerfrei.  
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Immobilien sind eine sehr langfristige Spar- und Anlageform und – wie der Name schon sagt – „immobil“, also nicht beweglich. Bedenke also: Ändert sich deine Lebenssituation und du möchtest zum Beispiel im Alter umziehen, kannst du deine eigenen vier Wände nicht mitnehmen oder schnell wieder zu Geld machen.  Immobilien sind also wenig flexibel, dafür umso sicherer. Wenn du auf die richtige Lage deiner Immobilie achtest, ist die Wahrscheinlichkeit hoch, dass sie im Wert steigt und gute Renditechancen bietet. 
                          <br><br>
                          <strong>Sind Immobilien sicher vor Inflation?</strong><br>
                          Von Inflation spricht man, wenn die Preise für Waren und Dienstleistungen allgemein steigen und du somit für dein Geld immer weniger kaufen kannst. Immobilien sind eine solide Geldanlage und stellen einen guten Schutz vor Inflation dar. Im Gegensatz zu Geld verlieren sie als echte, anfassbare Sachwerte weniger an Wert. Im Gegenteil: Ihr Wert kann je nach Lage und Nachfrage steigen. Die Inflation kann sich auch positiv auf eine Immobilienfinanzierung auswirken: Mit der Inflation sinkt der Wert der Schulden und sie kann leichter abbezahlt werden – vorausgesetzt, dein Gehalt steigt auch mit der Inflation. 
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal('#modal-immobilien')" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==5">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Das Festgeld.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Ein Festgeldkonto ist eine geeignete Sparform, dein Geld kurz- und mittelfristig sicher anzulegen. Der Zinssatz und der Zeitraum, in dem du anlegst, werden vorher bestimmt. Wie der Name schon sagt, ist dein Geld fest angelegt und du kannst es während der Laufzeit nicht abheben. Ersparnisse bis 100.000 Euro sind gesetzlich abgesichert – aber nur, wenn die Bank ihren Sitz innerhalb der Europäischen Union hat. 
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Ein Festgeldkonto bietet dir erstmal keine Sicherheit einer späteren lebenslangen Rente. Nach Ende der Laufzeit bekommst du dein Geld mit Zinsen zurück und kannst es entweder erneut anlegen oder es für deinen Ruhestand verwenden. Eine weitere Möglichkeit ist, das Geld nach Ende der Laufzeit in eine so genannte „Sofortrente“ bei einer Rentenversicherung einzuzahlen. Sie garantiert dir dann eine lebenslange monatliche Rente – egal, wie alt du wirst. 
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Zinsen auf dem Festgeldkonto sind bis zu einem Freibetrag von 1.000 Euro pro Jahr und Anleger steuerfrei. Alles darüber hinaus kostet 25  % Abgeltungssteuer. Hinzu kommt der Solidaritätszuschlag und – sofern du kirchensteuerpflichtig bist – die Kirchensteuer. Falls du dein Festgeldkonto bei einer Bank in Deutschland oder bei einer deutschen Niederlassung einer ausländischen Bank hast, führt die Bank diese Abgeltungssteuer direkt ab. Falls nicht, musst du die Zinserträge in deiner Einkommensteuererklärung angeben. 
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Ein Festgeldkonto ist eine gute Möglichkeit, das Ersparte kurz- bis mittelfristig ohne Risiko und dennoch mit Zinsen anzulegen. Während des festgelegten Zeitraumes kannst du weder Geld ein- noch auszahlen lassen. Da sich die Zinsen bei einem Festgeldkonto ändern können, kannst du dich auch für kürzere Laufzeiten entscheiden, um flexibel von eventuell besseren Konditionen profitieren zu können.
                          <br><br>
                          <strong>Ist das Festgeldkonto sicher vor Inflation?</strong><br>
                          Die Inflation bezeichnet die Geldentwertung durch steigende Preise. Je höher die Inflation, desto weniger kannst du dir für dein Geld kaufen. Mit einem Festgeldkonto legst du dein Geld kurz- bis mittelfristig an und weißt schon im Vorfeld ganz sicher, wie viele Zinsen du bekommst. Um jedoch den Kaufkraftverlust infolge der Inflation vollständig auszugleichen, eignet sich diese Sparform nicht.
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal('#modal-festgeld')" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==6">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Das Girokonto.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Ein Girokonto ist für deinen allgemeinen Zahlungsverkehr wie Lohn oder Gehalt, Miete und laufende Ausgaben unerlässlich. Es ist aber nicht geeignet, um Geld zu sparen, da du keinerlei Zinsen bekommst. Der Begriff „Girokonto“ stammt übrigens vom italienischen „giro“, was so viel heißt wie „Kreislauf“ oder „kreisen lassen“. Bei manchen Banken ist das Girokonto kostenlos, wenn du einen bestimmten monatlichen Zahlungseingang hast. Andere Banken verlangen generell Gebühren für die Kontoführung. Bei allen Banken gilt: Überziehst du dein Girokonto und rutschst ins Minus, zahlst du hohe Dispogebühren.
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Nein, ein Girokonto bietet dir keine Sicherheit, dass du später eine lebenslange Rente bekommst. Zum einen, weil du keine Zinsen auf dein Geld bekommst und es dadurch nicht mehr werden kann. Und zum anderen, weil du gar nicht so viel sparen kannst, dass du damit deinen späteren Lebensunterhalt bestreiten kannst. Hast du eine große Summe auf deinem Girokonto angespart, kannst du es jederzeit in eine „Sofortrente“ bei einer Versicherung einzahlen und dir damit eine garantierte lebenslange Auszahlung sichern.
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Kurz und knapp: Nein. Da dein Geld auf dem Girokonto nicht verzinst wird, musst du auch keine Steuern zahlen.
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Beim Girokonto verfügst du jederzeit über dein Geld. Du kannst jeden Tag rund um die Uhr so wenig oder soviel abheben, wie du willst (und es dein Konto hergibt). Denk aber daran: Bist du im Minus, zahlst du hohe Überziehungsgebühren.
                          <br><br>
                          <strong>Ist das Girokonto sicher vor Inflation?</strong><br>
                          Ganz im Gegenteil! Ein Girokonto wirft keinerlei Zinsen ab und ist deshalb zu 100 % von der Inflation betroffen. Inflation bedeutet, dass die Preise für Waren und Dienstleistungen steigen und dein Geld an Wert verliert. Du kannst dir also weniger davon kaufen. Hast du monatlich etwas Geld übrig, solltest du es nicht auf dem Girokonto „herumliegen“ lassen, sondern es möglichst chancenreich investieren, um den Wertverlust auszugleichen.
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal('#modal-girokonto')" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==7">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Geld unter dem Kopfkissen.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Sein Erspartes zuhause in einem Sparstrumpf, unterm Kopfkissen oder in anderen Verstecken aufzubewahren, kann auch eine Lösung sein. Egal, ob kurz-, lang- oder mittelfristig: Mit dieser Sparform bist du immer unabhängig von den Öffnungszeiten deiner Bank und musst dich auch nicht über niedrige Zinsen ärgern. Versteckst du dein Geld zuhause, hast du es jederzeit zur Hand – vorausgesetzt du findest es schnell wieder.  
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Nein, ein Sparstrumpf bietet dir keine Sicherheit einer späteren lebenslangen Rente. Zum einen, weil du wahrscheinlich nicht so viel sparen und verstecken kannst, dass es bis zum Rest deines Lebens reichen wird. Zum anderen, weil dein Geld jedes Jahr zu 100 % von der Inflation betroffen ist und dadurch weniger wert wird. Aber egal, wie viel Geld du am Ende zuhause versteckst – du kannst es jederzeit bei einer Rentenversicherung in eine „Sofortrente“ einzahlen und es dir dann lebenslang als monatliche Rente ausbezahlen lassen. 
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Der Sparstrumpf und andere kreative Geldverstecke sind nicht abgeltungssteuerpflichtig, du kannst so viel Geld verstecken, wie du willst. Bedenke aber, dass es auch von Einbrechern gestohlen werden kann. 
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Versteckst du dein Geld im Sparstrumpf oder woanders in deinen vier Wänden, kannst du jederzeit darüber verfügen, etwas entnehmen oder mehr dazulegen. Das Einzige, das dir vielleicht irgendwann ausgeht, sind die Ideen für neue Verstecke. 
                          <br><br>
                          <strong>Ist Geld unter dem Kopfkissen sicher vor Inflation?</strong><br>
                          Mit Inflation wird die Geldentwertung bezeichnet, die sich aufgrund steigender Preise bei Produkten und Dienstleistungen ergeben. Erhöhen sich die Preise, kannst du dir weniger kaufen – dein Geld wird also weniger wert. Der Sparstrumpf bzw. das Geldverstecken zuhause ist zu 100 % von der Inflation betroffen, da diese Art des Sparens keinerlei Zinsen bringt und sich das Geld nicht vermehren kann. Es sei denn, du legst noch mehr Geld in den Sparstrumpf. 
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal()" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                    <div class="flex flex-col" v-else-if="item.id==8">
                      <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                        <div class="title">Gold.</div>
                        <div class="text mt-7">
                          <strong>Definition</strong><br>
                          Gold ist für viele die klassische „Krisenwährung“ in unsicheren Zeiten. Was die meisten vielleicht nicht wissen: Der Wert von Gold schwankte in der Vergangenheit mehr als der Weltaktienmarkt, erzielte aber im Vergleich nur die Hälfte an Rendite. Gewinne mit Gold lassen sich realisieren, wenn der Goldpreis steigt und das Edelmetall zu diesem Zeitpunkt verkauft wird.
                          <br><br>
                          <strong>Lebenslange Rente möglich?</strong><br>
                          Gold an sich bietet dir zwar keine Sicherheit einer späteren lebenslangen Rente. Der Vorteil ist jedoch, dass die Investition recht sicher ist. Du kannst es jederzeit verkaufen und dir davon selbst eine Rente ausbezahlen oder du verkaufst es und zahlst den Erlös in eine „Sofortrente“ bei einer Rentenversicherung ein. Sie garantiert dir im Gegenzug eine lebenslange monatliche Rente – auch „Leibrente“ genannt. 
                          <br><br>
                          <strong>Müssen Steuern auf das Ersparte gezahlt werden? (Abgeltungssteuer)</strong><br>
                          Bei physischem Gold wie zum Beispiel Barren oder Münzen sind die Gewinne aus dem Verkauf abgeltungssteuerfrei – vorausgesetzt, du besitzt sie mindestens ein Jahr. Anders verhält es sich bei Erträgen aus Goldzertifikaten oder ETCs (Exchange Traded Commodities - an der Börse gehandelte Rohstoffe): sie sind immer steuerpflichtig. 
                          <br><br>
                          <strong>Wie flexibel ist das Produkt?</strong><br>
                          Gold ist eine Geldanlage mit hoher Flexibilität: In Form von kleinen Barren oder Münzen kannst du jederzeit kaufen oder verkaufen. Der Zeitpunkt des Ein- und Verkaufs ist von entscheidender Bedeutung: Je nach aktuell geltendem Goldkurs resultiert daraus deine erreichte Rendite. 
                          <br><br>
                          <strong>Ist Gold sicher vor Inflation?</strong><br>
                          Mit Inflation ist das Ansteigen der Verbraucherpreise und damit der Kaufkraftverlust deines Geldes gemeint. Bei hoher Inflation ist dein Geld also weniger wert als in Zeiten niedriger Inflation. Der Vorteil von Gold: Es lässt sich im Gegensatz zu Geld nicht beliebig vermehren. Als begrenzter Rohstoff behält das Edelmetall immer einen gewissen Sachwert, der von der Inflation nicht betroffen ist. 
                        </div>
                      </div>
                      <div @click="item.showInfo = !item.showInfo;linkInModal('#modal-gold')" class="self-end mt-4 cursor-pointer">Berechnungsgrundlagen <img class="inline-block ml-2" src="../../../public/static/img/chevron.svg"></div>
                    </div>
                  </div>
                </template>
              </VDropdown>
            </div>
          </div>
        </transition-group>
        <transition name="flip-list">
          <div class="info-text mt-5 grow flex flex-col justify-end" v-if="!$store.compFondsrentenVisible">
            <div>
              Aufgrund der Kurzfristigkeit deiner Ziele/Spardauer werden keine Rentenversicherungen angezeigt.
            </div>
          </div>
        </transition>
      </div>
      <transition name="fade-up">
        <div v-show="ui.showCalcModal" ref="modal" class="modal fixed w-full h-full bg-transparent flex flex-row justify-center items-center left-0 top-0 z-40" @click="ui.showCalcModal = false">
          <div class="modal-content bg-white max-w-[1100px] w-[80%] h-[70%] border-0 border-gray-300 shadow-2xl flex flex-col px-10 pb-10">
            <div class="w-full align-right text-right pt-4 text-3xl text-[#737B7D]">
              <span class="cursor-pointer font-bold" @click="ui.showCalcModal = false">x</span>
            </div>
            <div class="mt-4 relative h-auto overflow-y-auto pr-5" id="modal-scroll-container">
              <div class="modal-title">
                Datenbasis und Berechnungsgrundlagen für den Sparformvergleich
              </div>
              <div class="modal-text mt-9">
                Wir gehen von verschiedenen Annahmen für den Vergleich der verschiedenen Anlagearten aus, die wir im Folgenden näher beschreiben.<br>
                Ausgangspunkte für den Vergleich der verschiedenen Sparformen sind die monatliche Sparrate (Deine Einzahlungen pro Monat) und die Laufzeit (So lange willst du sparen), die individuell eingestellt werden können. Ebenso relevant ist die Angabe des Alters, da die Besteuerungsregeln bei der Fondspolice u.a. vom Alter bei Zufluss der Leistung abhängig sind.
                <br><br>
                <b id="modal-fondspolice-ohne-garantie">Berechnungsgrundlage für die Fondspolice ohne Garantie</b>
                <br><br>
                Über den gewählten Zeitraum wird die monatliche Sparrate in Aktienfonds angelegt. Zur Darstellung der Wertentwicklung von Aktienfonds wird die Renditeentwicklung des MSCI World Index über den gewählten Zeitraum herangezogen. Dabei erfolgt eine Vergangenheitsbetrachtung, ausgehend vom aktuellen Zeitpunkt wird der ausgewählte Spar-Zeitraum zurückgerechnet und so simuliert, dass die Fondspolice zum rückgerechneten Zeitpunkt abgeschlossen wurde.
                <br><br>
                <span class="text-[#F04C28]">WARNHINWEIS:</span> <i>Vergangenheitsbetrachtungen sind keine Prognosen und können keine Aussage über die zukünftige Entwicklung von Aktienfonds treffen! Die Kosten einer Fondspolice werden über den Reduction-in-Yield (RIY) Ansatz berücksichtigt. Hierfür wurden die Basisinformationsblätter (BIB) und Produktinformationsblätter (PIB) repräsentativer Versicherungsgesellschaften analysiert und ein Durchschnittswert ermittelt. Bei der RIY handelt es sich um den Prozentsatz, um den die Wertentwicklung der Fonds aufgrund der Kosten für die Police verringert wird.</i>
                <br><br>
                Die Steuerbetrachtung zum Rentenbeginn erfolgt nach dem Halbeinkünfteverfahren. Dabei wird eine Teilfreistellung von 15% auf die Fondserträge berücksichtigt. Wird bei Vertragsabschluss eine lebenslange Rentenzahlung vereinbart, erfolgt keine Besteuerung der Ablaufleistung. Es werden dann die Renten mit dem sogenannten Ertragsanteil besteuert. Die Rentenzahlung wird in der Darstellung nicht berücksichtigt.
                <br><br>
                Datenquelle:<br>
                <a href="https://www.msci.com/end-of-day-history?chart=regional&priceLevel=41&scope=R&style=C&currency=15&size=36&indexId=106" target="_blank">Index MSCI-World</a>
                <br><br>
                <b id="modal-fondspolice-mit-garantie">Berechnungsgrundlage für die Fondspolice mit Garantie:</b>
                <br><br>
                Bei der Berechnung Fondspolice mit Garantie kann die zu investierende monatliche Rate mit einem Prozentsatz aufgeteilt werden. Der angenommene Prozentsatz gibt den Anteil der monatlichen Rate an, der nach Abzug von Kosten mit einem festen Zins hochgerechnet wird. Der Abzug der Kosten erfolgt auch auf diesen Teil mithilfe der Reduction in Yield.
                <br><br>
                Der restliche Beitrag wird wie bei der Fondspolice ohne Garantie behandelt.<br>
                Der angesetzte Zins für die Hochrechnung des garantiert zu investierenden Beitragsteils erfolgt mit einem durchschnittlichen Wert der Gesamtverzinsung für Lebensversicherungen.
                <br>
                Die Besteuerung erfolgt, wie bei der Fondspolice ohne Garantie, wobei die Teilfreistellung nur auf den Anteil ohne Garantie angewendet wird.
                <br>
                Datenquelle:<br>
                <a href="https://www.msci.com/end-of-day-history?chart=regional&priceLevel=41&scope=R&style=C&currency=15&size=36&indexId=106" target="_blank">Index MSCI-World</a>
                <br><br>
                <b id="modal-fondssparplan">Berechnungsgrundlage für den Fondssparplan:</b>
                <br><br>
                Die Investition der monatlichen Sparrate erfolgt modellhaft in einen thesaurierenden Aktienfonds. Dazu werden die Wertentwicklungen des MSCI World Index herangezogen, analog zur Fondspolice. Es wird angenommen, dass der Fonds alle {{(state.app.vermoegen.fondsWechselIntervallInMonate / 12).toFixed(0)}} Jahre umgeschichtet wird.
                <br>
                Der Abzug einer jährlichen Vorabpauschale bleibt in dieser Berechnung unberücksichtigt.
                <br>
                Datenquelle:<br>
                <a href="https://www.msci.com/end-of-day-history?chart=regional&priceLevel=41&scope=R&style=C&currency=15&size=36&indexId=106" target="_blank">Index MSCI-World</a>
                <br><br>
                <b id="modal-immobilie">Berechnungsgrundlage für kreditfinanzierte Immobilien:</b>
                <br><br>
                Die Betrachtung der Investition in Immobilien geht von folgendem Ansatz aus: Die monatlich zur Verfügung stehenden Sparrate wird als Annuität zur Tilgung eines Darlehens angesetzt.
                Ausgehend von der monatlichen Rate, dem Darlehenszins und einem angenommenen Tilgungsprozentsatz wird das Darlehen ermittelt, das daraus über die eingegebene Laufzeit finanziert werden kann. Annahmen für den Darlehenszins und den Tilgungssatz ergeben sich aus den üblichen Bankkonditionen. Unser Beispiel basiert auf einer Berechnung der Vereinigten Volksbank Raiffeisenbank eG.
                Der Betrag des Darlehens wird dann mit der Wertsteigerung, die Immobilien in dem betrachteten Zeitraum in der Vergangenheit erreicht haben, ausgehend von Daten des Statistischen Bundesamtes für das Jahr 2022 zurückgesetzt.
                Datenquellen:<br><br>
                <a href="https://www.destatis.de/DE/Themen/Wirtschaft/Preise/Baupreise-Immobilienpreisindex/Publikationen/Downloads-Bau-und-Immobilienpreisindex/bauwirtschaft-preise-2170400223244.pdf?__blob=publicationFile" target="_blank">Statistisches Bundesamt: Preisindizes für die Bauwirtschaft</a><br>
                <a href="https://www.vvr-bank.de/immobilien/baufinanzierung/baufinanzierung/annuitaetendarlehen.html" target="_blank">Vereinigte Volksbank Raiffeisenbank eG: Darlehenszins und Tilgungssatz für kreditfinanzierte Immobilien</a>
                <br><br>
                <b id="modal-festgeld">Berechnungsgrundlage für Festgeld:</b>
                <br><br>
                Die angegebene Sparrate wird zunächst monatlich angespart bis eine Mindestsumme für die Anlage in Festgeld erreicht ist. Das Festgeld wird jeweils für ein Jahr angelegt und am Ende des Jahres mit den bis dahin angesparten monatlichen Raten wieder angelegt.
                <br><br>
                Datenquellen:<br>
                <a href="https://de.statista.com/statistik/daten/studie/202273/umfrage/entwicklung-des-zinssatzes-fuer-tagesgeld-in-deutschland/" target="_blank">Statista: Entwicklung des durchschnittlichen Zinssatzes für Tagesgeld in Deutschland in den Jahren von 1997 bis 2023</a><br>
                <a href="https://de.statista.com/statistik/daten/studie/202294/umfrage/entwicklung-des-zinssatzes-fuer-termingeld-in-deutschland/" target="_blank">Statista: Entwicklung des durchschnittlichen Zinssatzes für Termingeld in Deutschland in den Jahren von 1975 bis 2023</a><br>
                <br>
                <b id="modal-gold">Berechnungsgrundlage für Gold:</b>
                <br><br>
                Es wird angenommen, dass für die monatliche Sparrate Gold gekauft wird. Beim Kauf entstehen Ausgabeaufschläge. Eine Besteuerung bei der Veräußerung entsteht nicht.
                <br><br>
                Zur Berechnung wird der Goldindex verwendet und die historische Entwicklung für den ausgewählten Betrachtungszeitraum, rückgerechnet von 2023.<br>
                <br>
                Datenquelle:<br>
                <a href="https://www.finanzen.net/index/arca-gold-index" target="_blank">Arca Gold Index</a>
                <br><br>
                <b id="modal-girokonto">Berechnungsgrundlage für Girokonto:</b>
                <br><br>
                Guthaben auf dem Girokonto werden von Banken üblicherweise nicht verzinst, da das Geld jederzeit verfügbar gehalten werden muss.
                <!--
                Zugrunde liegt die Entwicklung des durchschnittlichen Zinssatzes in Deutschland von 1997 bis 2022. Ältere Daten wurden über den Zusammenhang von
                Festgeldzinsen zu Tagesgeldzinsen geschätzt. Die Zinserträge werden monatlich gutgeschrieben.
                <br><br>
                Datenquelle: https://de.statista.com/statistik/daten/studie/202273/umfrage/entwicklung-des-zinssatzes-fuer-tagesgeld-in-deutschland/
                -->
                <br><br>
                <b id="modal-rendite">Berechnungsgrundlage für die durchschnittliche jährliche Rendite:</b>
                <br><br>
                Die Methode, die wir verwenden, ist bekannt als "Interne Zinsfuß-Methode" (Internal Rate of Return, IRR). Sie versucht, den Zinssatz zu finden, bei dem der Barwert aller Cashflows (Einzahlungen und Auszahlungen) über den Zeitraum Ihrer Investition gleich Null ist.
                <br><br>
                In der Praxis starten wir mit einer geschätzten Rendite und passen diese schrittweise an, bis wir einen Zinssatz finden, der den Barwert unserer Cashflows so nahe wie möglich an Null bringt. Die Genauigkeit dieses Verfahrens kann durch die Schrittgröße und die Toleranzgrenze beeinflusst werden, die wir in unserer Berechnung verwenden.
                <br><br>
                Bitte beachten Sie, dass aufgrund der Art dieser Methode und der begrenzten Präzision von numerischen Berechnungen die berechnete Rendite leicht von der tatsächlichen Rendite abweichen kann.
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </controll-wrapper>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue"
import AppSlider from '@/components/AppSlider.vue'
import VueScrollTo from 'vue-scrollto'
import Modal from '@/components/modal.vue'

var scrollOptions = {
    container: '#modal-scroll-container',
    easing: 'ease-in',
    lazy: false,
    offset: -20,
    force: true,
    cancelable: true,
    onStart: function(element) {
      // scrolling started
    },
    onDone: function(element) {
      // scrolling is done
    },
    onCancel: function() {
      // scrolling has been interrupted
    },
    x: false,
    y: true
}

export default {
  name: 'Vermoegen_2',
  components: {
    ControllWrapper, AppSlider, Modal
  },
  data() { return {
    state: this.$store.state,
    dataLastYear: 2023,
    ui: {
      oneModelActive: false,
      showSparformenInfo: false,
      showCalcModal: false,
      showAbgeltungModal: false,
      showInflationModal: false,
      showRentenModal: false
    }
  }},
  mounted() {
    this.$emit('updateData')
  },
  computed: {
    compSparformenActive() {
      for (let n in this.state.app.vermoegen.model) {
        if (this.state.app.vermoegen.model[n]) return true
      }
      return false
    },
    compYearSpan() {
      return this.dataLastYear - this.state.app.vermoegen.sparphaseJahre + " bis " + this.dataLastYear
    }
  },
  methods: {
    addToBody() {
      document.body.appendChild(this.$refs.modal)
    },
    async linkInModal(el) {
      this.addToBody()
      this.ui.showCalcModal = true
      await this.$store.sleep(1000)
      if (el) VueScrollTo.scrollTo(el, 400, scrollOptions)
    },
    /*
    checks if one checkbox is activated. if not remove charts and disable active buttons.
    */
    checkIfOneIsActive() {
      for (let key in this.state.app.vermoegen.model) {
        if (this.state.app.vermoegen.model[key]) {
          this.ui.oneModelActive = true
          this.updateData()
          return
        }
      }
      for (let item of this.state.app.vermoegen.sparformen) {
        item.active = false
        item.autoActive = false
      }
      this.updateData()
      this.ui.oneModelActive = false
      return
    },
    updateData(data) {
      this.$emit('updateData')
    }
  },
  beforeDestroy() {
    for (let item of this.state.app.vermoegen.sparformen) {
      item.showInfo = false
    }
  }
}
</script>

<style lang="stylus">


.flip-list-move {
  transition: all 1s;
}
.flip-list-enter-active {
  transition: all 1s;
}
.flip-list-leave-active {
  transition: all 1s;
}
.flip-list-enter {
  opacity:0!important
}
.flip-list-leave-to {
  opacity:0!important
}
</style>

<style lang="stylus" scoped>
.info-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.7px;
  color: #373F41;
}
.modal {
  a {
    color: #1E86CB;
  }
  .modal-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    letter-spacing: 0.1px;
    color: #373F41;
  }
  .modal-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #737B7D;
  }
  #modal-scroll-container {
    &::-webkit-scrollbar {
      width: 11px;
      height: 11px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #DCDCDC;
      border: 0px none #a62121;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #DCDCDC;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #DCDCDC;
    }
    &::-webkit-scrollbar-track {
      background: #F2F2F2;
      border: 0px none #b52626;
      border-radius: 15px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #F2F2F2;
    }
    &::-webkit-scrollbar-track:active {
      background: #F2F2F2;
    }
    &::-webkit-scrollbar-corner {
      background: #F2F2F2;
    }
  }
}
.modal-scrollbar {
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.4px;
    color: #1E86CB;
  }
  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #373F41;
  }
  .more {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #737B7D;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #737B7D;
    border: 0px none #a62121;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #737B7D;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #737B7D;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #b52626;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.checkbox-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.7px;
  color: #737B7D;
  padding-left:4px
}
.info-icon {
  text-align:right
  img {
    width:20px
    height:20px
    display:inline-block
    cursor:pointer
  }
}

.sparformen-container {
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #737B7D;
    border: 0px none #a62121;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #737B7D;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #737B7D;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #b52626;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.sparformen-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.1px;
  color: #373F41;
}
.sparform-container {
  padding: 13px 19px;
  gap: 12px;
  width: 100%;
  background: #FFFFFF;
  border: 2px solid #DCDCDC;
  border-radius: 3px;
  cursor:pointer
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.7px;
  color: #DCDCDC;
  &.active {
    color: #737B7D
  }
  span {
    font-size: 14px;
  }
}
.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
}
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
.control_indicator {
    position: absolute;
    top: 0px;
    left: 0;
    height: 22px;
    width: 22px;
    background: #ffffff;
    border: 2px solid #D9D9D9;
    border-radius: 4px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #ffffff;
}

.control input:checked ~ .control_indicator {
    background: #ffffff;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #ffffff;
}
.control input:disabled ~ .control_indicator {
    //background: #c7c7c7;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 10px;
    top: -12px;
    width: 7px;
    height: 24px;
    border: solid #5889da;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    //border-color: #ffffff;
}
.control-checkbox .control_indicator::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: #3c67e3;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
}
@keyframes s-ripple {
    0% {
        transform: scale(0);
    }
    20% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
@keyframes s-ripple-dup {
   0% {
       transform: scale(0);
    }
   30% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
.control-checkbox input + .control_indicator::before {
    animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
    animation-name: s-ripple-dup;
}

.fade-up-enter-active {
  transition: all .9s;
}
.fade-up-leave-active {
  transition: all .9s;
}
.fade-up-enter {
  transform: translateY(110%);
  opacity:0
}
.fade-up-leave-to {
  transform: translateY(110%);
  opacity:0
}
</style>
