<template>
  <div class="h-full relative left-0 top-0">
    <h1 class="text-center pt-6 pb-7 title">Deine Wünsche und Ziele</h1>
    <div class="flex flex-row justify-evenly">
      <controll-wrapper title="Wie sehen deine Wünsche und Ziele aus?">
        <template v-slot:content>
          <div class="pl-16 pr-[74px]">
            <div class="flex flex-row gap-x-2 mb-2">
              <div class="text !mb-0">Kurzfristige Wünsche und Ziele</div>
              <div class="info-icon grow self-center inline-flex">
                <VDropdown
                  :distance="15"
                  :placement="'right'"
                  class="checkbox-info-container"
                >
                  <img src="../../public/static/img/info-icon-grey.svg">
                  <template #popper>
                    <div class="w-[420px] p-4 overflow-hidden">
                      <div class="flex flex-col">
                        <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                          <div class="text">
                            <div class="">
                              Für kurzfristige Ziele sparst du maximal 12 Jahre.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </VDropdown>
              </div>
            </div>
            <app-slider v-for="val in sliderValues.filter(o => o.type === 'kurzfristig')" :key="val.modelName"
              :modelObject="'ziele'"
              :minValue="val.minValue"
              :maxValue="val.maxValue"
              :modelName="val.modelName"
              :icon="val.icon"
              :valueSuffix="val.valueSuffix"
              :valueTitle="val.valueTitle"
              :interval="val.interval || 100"
              :isLazy="val.isLazy"
              @updateSelected="updateSelected(val.modelName)" 
              @updateData="updateData(val.modelName)" 
            />
            <div class="flex flex-row gap-x-2 mb-2">
              <div class="text !mb-0">Langfristige Wünsche und Ziele</div>
              <div class="info-icon grow self-center inline-flex">
                <VDropdown
                  :distance="15"
                  :placement="'right'"
                  class="checkbox-info-container"
                >
                  <img src="../../public/static/img/info-icon-grey.svg">
                  <template #popper>
                    <div class="w-[420px] p-4 overflow-hidden">
                      <div class="flex flex-col">
                        <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                          <div class="text">
                            <div class="">
                              Für langfristige Ziele musst du mindestens 12 Jahre lang sparen. Falls du keine langfristigen Ziele angibst, werden dir bestimmte Sparformen, zum Beispiel Renten, nicht empfohlen.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </VDropdown>
              </div>
            </div>
            <app-slider v-for="val in sliderValues.filter(o => o.type === 'langfristig')" :key="val.modelName"
              :modelObject="'ziele'"
              :minValue="val.minValue"
              :maxValue="val.maxValue"
              :modelName="val.modelName"
              :icon="val.icon"
              :icon2="val.icon2"
              :valueSuffix="val.valueSuffix"
              :valueTitle="val.valueTitle"
              :interval="val.interval || 100"
              :isLazy="val.isLazy"
              @updateSelected="updateSelected(val.modelName)" 
              @updateData="updateData(val.modelName)" 
              @openModal="openModal"
            />
            <div class="change-value text-[#737B7D] whitespace-nowrap">angenommene Lebenserwartung: {{ lifeExpectancy }} Jahre. <span @click="ui.showSfModal = true" class="show-sf-modal text-[#1E86CB] cursor-pointer"><img class="w-[16px] h-[16px]" src="../../public/static/img/setting2.png">Ändern.</span></div>
            <modal v-model="ui.showSfModal">
              <div class="overflow-auto sf-modal">
                <div class="m-1">
                  <div class="modal-title">Berechne hier deine individuelle Lebenserwartung!</div>
                  <div class="w-full h-full flex justify-center">
                    <iframe src="https://content.sonares.org/?toolId=12&noscript=1" ref="myIframe" frameborder="0" scrolling="no" allowfullscreen height="430px" width="400px" marginheight="0px" marginwidth="0px" />
                  </div>
                  <div>
                    <div class="input-title">
                      <div class="flex">
                        <img src="../../public/static/icons/payments.svg"> Geänderte Lebenserwartung eintragen
                      </div>
                      <div>
                        <div class="info-icon grow self-center">
                          <VDropdown
                            :distance="15"
                            :placement="'right'"
                            class="checkbox-info-container"
                          >
                            <img src="../../public/static/img/info-icon-grey.svg">
                            <template #popper>
                              <div class="w-[420px] p-4 overflow-hidden">
                                <div class="flex flex-col">
                                  <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                                    <div class="text">
                                      <div class="">
                                        Trage hier deine errechnete oder gewünschte Lebenserwartung ein, um deinen Finanzbedarf im Alter individuell berechnen zu können.                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </VDropdown>
                        </div>
                      </div>
                    </div>
                    <input v-model="lifeExpectancy" class="modal-input" type="text">
                  </div>
                  <div class="flex justify-center">
                    <button @click="updateLifeExpectancy" class="mt-4 py-2.5 px-3 bg-[#1E86CB] text-white rounded-3xl text-sm">
                      <span>Aktualisieren</span>
                    </button>
                  </div>                  
                </div>
              </div>
            </modal>
          </div>
        </template>
      </controll-wrapper>
      <div class="chart-wrapper relative">
        <div class="line-bg absolute left-0 top-0 w-full h-full overflow-hidden">
          <div class="line" v-for="n of 50" :key="n">
          </div>
        </div>
        <v-chart ref="myPie" class="chart" :option="chart" />
        <div class="sum-wrapper">
          <p class="sum-title">Finanzbedarf (Gesamt)</p>
          <p class="sum-value">{{$store.compFinanzbedarfFormatted}}</p>
          <p class="smaller">
          <span class="sum-title">Langfristig</span>
          <span class="sum-value">{{$store.compFinanzbedarfLangfristigFormatted}}</span>
          </p>
          <p class="smaller">
          <span class="sum-title">Kurzfristig</span>
          <span class="sum-value">{{$store.compFinanzbedarfKurzfristigFormatted}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue";
import { registerTheme, use } from 'echarts/core'
import { CanvasRenderer } from "echarts/renderers"
import { PieChart } from "echarts/charts"
import Modal from "@/components/modal.vue"

import VChart, { THEME_KEY } from "vue-echarts"

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import AppSlider from '@/components/AppSlider.vue'

use([
  CanvasRenderer,
  PieChart
]);

export default {
  name: 'Ziele',
  components: {
    ControllWrapper,
    VChart,
    AppSlider,
    Modal
  },
  data() { 
    return {
      state: this.$store.state,
      lifeExpectancy: 89,
      ui: {
        showSfModal: false
      },
      somethingHasChanged: false,
      sliderValues: [
        {
          valueSuffix: "euro",
          icon: "payments",
          valueTitle: "Erspartes zur freien Verfügung",
          minValue: 0,
          maxValue: 100000,
          modelName: "erspartes",
          interval: 500,
          isLazy: true,
          type: "kurzfristig"
        },
        {
          valueSuffix: "euro",
          icon: "work",
          valueTitle: "Startkapital für Existenzgründung",
          minValue: 0,
          maxValue: 100000,
          modelName: "jobSelbststaendig",
          isLazy: true,
          type: "kurzfristig"
        },
        {
          valueSuffix: "euro",
          icon: "car",
          valueTitle: "Mobilität",
          minValue: 0,
          maxValue: 100000,
          modelName: "mobilitaet",
          isLazy: true,
          type: "kurzfristig"
        },
        {
          valueSuffix: "euro",
          icon: "school",
          icon2: false,
          valueTitle: "Unterstützung der Kinder",
          minValue: 0,
          maxValue: 100000,
          modelName: "kinderSupport",
          isLazy: true,
          type: "langfristig"
        },
        {
          valueSuffix: "euro",
          icon: "home",
          icon2: false,
          valueTitle: "Wohneigentum",
          minValue: 0,
          maxValue: 1000000,
          modelName: "wohnEigentum",
          interval: 1000,
          isLazy: true,
          type: "langfristig"
        },
        {
          valueSuffix: "euro",
          icon: "elderly",
          icon2: true,
          valueTitle: "Zusätzliches Geld im Alter (pro Monat)",
          minValue: 0,
          maxValue: 5000,
          interval: 20,
          modelName: "geldImAlter",
          isLazy: true,
          type: "langfristig"
        },
      ],
      chart: {
        series: [
          {
            // color: ["hsl(204, 74%, 46%)", "hsl(204, 74%, 56%)", "hsl(204, 74%, 66%)", "hsl(204, 74%, 71%)", "hsl(204, 74%, 76%)", "hsl(204, 74%, 81%)"],
            selectedMode: 'single',
            label: {
              position: 'inner',
              fontSize: 24,
            },
            labelLine: {
              show: false
            },
            name: "test",
            type: "pie",
            radius: ["60%", "95%"],
            center: ["50%", "50%"],
            data: [
            ],
            itemStyle: {
              // borderRadius: 8
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    }
  },
  props: ['showSf'],
  mounted() {
    this.initDonut();
    this.$emit('updateData');
  },
  computed: {
    compChangeLifeExpectancy() {
      return parseInt(this.lifeExpectancy) - 67;
    },
  },
  watch: {
    'state.app.ziele' : {
      handler: function(newVal, oldVal) {
        this.somethingHasChanged = true;
      },
      deep: true
    },
  },
  methods: {
    initDonut() {
      let _tmpObj = {... this.state.app.ziele}
      for (const ziel in _tmpObj) {
        this.updateData(ziel)
      }
    },
    updateData(data) {
      // console.warn(this.slider[data]);
      // console.warn(tmpData.filter(e => e.name === data));
      let tmpData = this.chart.series[0].data
      let iconName = this.sliderValues.find(obj => obj.modelName === data).icon;

      let kurzfristigColor = ["hsl(39, 94%, 65%)", "hsl(39, 94%, 70%)", "hsl(39, 94%, 75%)", "hsl(39, 94%, 80%)"];
      let langfristigColoR = ["hsl(204, 74%, 46%)", "hsl(204, 74%, 66%)", "hsl(204, 74%, 76%)"];

      for (const elem of tmpData) {
        // elem.selected = false;
        delete elem.selected
      }

      // get color orders
      let dataType = this.sliderValues.find(obj => obj.modelName === data).type;

      let colorToUse;

      if (dataType === "kurzfristig") {
        colorToUse = kurzfristigColor[this.chart.series[0].data.filter(o => o.donutType === "kurzfristig").length];
      }
      if (dataType === "langfristig") {
        colorToUse = langfristigColoR[this.chart.series[0].data.filter(o => o.donutType === "langfristig").length];
      }

      if (tmpData.filter(e => e.name === data).length === 0) {
        let tmpObj = {
          value: this.state.app[this.$route.name][data],
          donutType: dataType,
          itemStyle: {color: colorToUse},
          name: data,
          // icon: "/static/icons/"+iconName+".png",
          selected: true,
          label: {
            formatter: '{Icon|}',
            rich: {
              Icon: {
                height: 30,
                align: 'left',
                backgroundColor: {
                  image: require("../../public/static/icons/"+iconName+"_white.png")
                }
              }
            }
          }
        }
        //console.warn("../assets/icons/"+data+this.compSvgPng);
        tmpData.push(tmpObj)
      }else {
        tmpData.filter(e => e.name === data)[0].value = this.state.app[this.$route.name][data]
        tmpData.filter(e => e.name === data)[0].selected = true
      }

      if (data === "geldImAlter") {
        tmpData.filter(e => e.name === data)[0].value = (this.state.app[this.$route.name][data] * 12 * this.$store.state.changedLifeExpectancy)
      }

      if (this.state.app[this.$route.name][data] <= 0) {
        this.chart.series[0].data = tmpData.filter(function(obj) {
          // console.warn(obj.name !== data);
          return obj.name !== data
        })
      }

      // sort array
      let orderArray = ["erspartes", "jobSelbststaendig", "mobilitaet", "kinderSupport", "wohnEigentum", "geldImAlter"]

      this.chart.series[0].data.sort((a, b) => {
        const aIndex = orderArray.indexOf(a.name);
        const bIndex = orderArray.indexOf(b.name);
        if (aIndex === -1 || bIndex === -1) {
          return 0;
        }
        return aIndex - bIndex;
      });

      this.$refs.myPie.setOption(this.chart, true)
    },
    updateSelected(data) {
      // console.warn(data);
      let tmpData = this.chart.series[0].data

      for (const elem of tmpData) {
        // elem.selected = false;
        delete elem.selected
      }

      if (tmpData.filter(e => e.name === data).length > 0) {
        tmpData.filter(e => e.name === data)[0].selected = true
      }

      this.$refs.myPie.setOption(this.chart, true)

    },
    updateSfModal(data) {
      this.$emit('updateData')
    },
    updateLifeExpectancy() {
      // console.log("lifeExpectancy", this.lifeExpectancy);
      this.$store.state.changedLifeExpectancy = this.compChangeLifeExpectancy;
      this.updateChart();
      this.ui.showSfModal = false
    },
    updateChart() {
      this.initDonut();
      this.$refs.myPie.setOption(this.chart, true)
    },
    openModal() {
      this.ui.showSfModal = true
    },
  },
  beforeDestroy() {
    if (this.somethingHasChanged) {
      this.state.app.pdfCharts.ziele = document.getElementsByTagName("canvas")[0].toDataURL()
    }
  }
}
</script>

<style lang="stylus">
  h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #1F2532;
  }
</style>

<style lang="stylus" scoped>
.text {
  font-weight: 600;
  color: #1F2532;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
  margin-left: -4px;
  margin-bottom: 8px;
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1F2532;
}
.inline {
  display: flex;
}
.chart-wrapper {
  width: 630px;
  height: 630px;
  // position: absolute;
  // right: 0;
}
.chart {
  height: 630px;
}
.line-bg {
  .line {
    width:100%
    border: 1px solid #F2F2F2;
    margin-bottom:37px
  }
}

.sum-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .sum-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.7px;
    color: #1E86CB;
  }
  .sum-value {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 35.5px;
    text-align: center;
    letter-spacing: -0.7px;
    color: #737B7D;
  }
  .smaller {
    text-align: center;
    .sum-title {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      letter-spacing: -0.7px;
      color: #1E86CB;
    }
    .sum-value {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20.8px;
      text-align: center;
      letter-spacing: -0.7px;
      color: #737B7D;
      margin-left: 5px;
    }
  }
}

.change-value, .show-sf-modal {
  font-family: 'Open Sans';
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.7px;
}

.change-value {
  transform: translateY(-12px);
}

.sf-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 16px 48px;
}
.modal-title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
}

.input-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;

  font-weight: 600;
  letter-spacing: -0.7px;
  margin-bottom: 10px;
  color: #1E86CB;
  margin-top: 16px;
}

.modal-input {
  width: 100%;
  height: 36px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 10px 17px 10px 7px;
}
</style>
