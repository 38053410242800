<template>
  <controll-wrapper title="Wie hoch ist dein Gesamteinkommen bis zur Rente?">
    <template v-slot:content>
      <div class="pl-16 pr-[74px]">
        <app-slider v-for="val in sliderValues" :key="val.modelName"
          :modelObject="'einkommensschutz'"
          :minValue="val.minValue"
          :maxValue="val.maxValue"
          :modelName="val.modelName"
          :icon="val.icon"
          :valueSuffix="val.valueSuffix"
          :valueTitle="val.valueTitle"
          :interval="val.interval"
          :isLazy="val.isLazy"
          @updateSelected="updateSelected(val.modelName)" 
          @updateData="updateData(val.modelName)" 
        />
      </div>
    </template>
  </controll-wrapper>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue";
import AppSlider from '@/components/AppSlider.vue'


export default {
  name: 'Einkommenschutz_1',
  components: {
    ControllWrapper,
    AppSlider,
  },
  data() { return {
    state: this.$store.state,
    sliderValues: [
      {
        valueSuffix: "euro",
        valueTitle: "So viel verdienst du pro Monat (Netto)",
        minValue: 500,
        maxValue: 10000,
        interval: 50,
        modelName: "nettoMtl",
        isLazy: false,
      },
      {
        valueSuffix: "prozent",
        valueTitle: "Mit dieser Gehaltssteigerung rechnest du pro Jahr",
        minValue: 0,
        maxValue: 10,
        interval: 0.1,
        modelName: "gehaltSteigerung",
        isLazy: false,
      },
      {
        valueSuffix: "jahr",
        valueTitle: "So alt bist du",
        minValue: 16,
        maxValue: 67-1,
        interval: 1,
        modelName: "alter",
        isLazy: false,
      },
      // {
      //   type: "multi",
      //   valueTitle: "Geschlecht",
      //   values:['weiblich', 'männlich', 'divers'],
      //   modelName: "geschlecht",
      // },
    ],
  }},
  mounted() {
    this.updateData();
  },
  computed: {
    compChartXdata() {
      let tmpArr = [];
      for (let i = this.state.app.einkommensschutz.alter; i <= 67; i++) {
        tmpArr.push(i)
      }

      return tmpArr
    },
    compChartSeriesData() {
      let nettoYear = 0;
      let tmpArr = []
      this.compChartXdata.forEach(elem => {
        nettoYear += (this.state.app.einkommensschutz.nettoMtl * 12)
        nettoYear *= (1 + this.state.app.einkommensschutz.gehaltSteigerung / 100)
        tmpArr.push(nettoYear)
      });
      
      return tmpArr
    },
    compChartCapped() {
      let cutIndex = Math.floor(this.compChartSeriesData.length / 1.8);
      let tmpArr = [...this.compChartSeriesData]
      let newArr = []
      tmpArr.forEach((elem,index) => {
        if (index >=cutIndex ) {
          elem = tmpArr[cutIndex]
        } 
        newArr.push(elem)
      })
      return newArr
    }
  },
  methods: {
    updateData(data) {
      // this.chart.series[0].data = [[0,0],[this.state.app.vermoegen.sparphaseJahre, this.state.app.vermoegen.sparphaseJahre * 12 * this.state.app.vermoegen.sparbetragMtl]]
      // this.chart.series[1].data = this.compSparPotentialData
      // this.chart.xAxis.data = this.compChartXdata;
      // this.chart.series[0].data = this.compChartSeriesData;
      // this.chart.series[1].data = this.compChartCapped;
    },
    updateSelected(data) {
      
    }
  }
}
</script>

<style lang="stylus">
</style>

<style lang="stylus" scoped>
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1F2532;
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.7px;
  color: #737B7D;
}
.chart-wrapper {
  width: 630px;
  height: 100%;
  // position: absolute;
  // right: 0;
}
.chart {
  height: 100%;
}
</style>
