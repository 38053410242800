<template>
  <div class="h-full relative left-0 top-0 flex flex-col">
    <h1 class="text-center pt-6 pb-7 title">So baust du ein Vermögen auf</h1>
    <div class="flex flex-row gap-2 justify-between h-full">
      <router-view @updateData="updateData()" :showInflation.sync="ui.showInflation" :msciworld="msciworld" />
      <div class="chart-wrapper relative grow overflow-x-hidden">
        <div class="chart-legend-container absolute top-12 left-10 z-10">
          <div class="text-md text-xs leading-3 pb-2">
            <span v-if="compOneSparformActive">Die Anzeige der Sparformen basiert auf historischen Daten und ist keine Prognose.</span>
            <span v-else>&nbsp;</span>
          </div>
          <div class="chart-legend bg-slate-50 p-4 inline-block">
            <div>Dein Finanzbedarf (nach Inflation)</div>
            <div><strong>{{$store.compFinanzbedarfInflationFormatted}}</strong></div>
            <div class="mt-2">Dein aktuelles Sparpotential</div>
            <div class="text-[#1E86CB]"><strong>{{$store.compSparPotential.toLocaleString() + '€'}}</strong></div>
            <div class="mt-2">Differenz zum Bedarf</div>
            <div class="text-[#1E86CB]" :class="$store.compDifferenzBedarf < 0 && 'text-[#CC4122]'"><strong><span v-if="$store.compDifferenzBedarf > 0">+</span>{{$store.compDifferenzBedarf.toLocaleString() + '€'}}</strong></div>
          </div>
        </div>
        <div id="echart" class="chart"></div>
        <div id="pdfChart" class="chart pdf-chart absolute w-full -right-[1000px] top-0"></div>
        <!--v-chart ref="chart" class="chart" :option="chart" /-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue"
import AppSlider from '@/components/AppSlider.vue'

import { init as echartInit } from 'echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent  } from 'echarts/components';

let chart, pdfChart

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
]);

//import msciworld from '../../../public/static/data/msciworld.json' //https://curvo.eu/backtest/market-index/msci-world
import msciworld from '../../../public/static/data/msciworld2.json' //https://www.msci.com/end-of-day-history?chart=regional&priceLevel=41&scope=R&style=C&currency=15&size=36&indexId=106
import gold from '../../../public/static/data/gold.json'
//import immo from '../../../public/static/data/immo2.json' //https://hausinvest.de/downloads/
import immo3 from '../../../public/static/data/immo3.json' //https://www.destatis.de/DE/Themen/Wirtschaft/Preise/Baupreise-Immobilienpreisindex/Publikationen/Downloads-Bau-und-Immobilienpreisindex/bauwirtschaft-preise-2170400223244.pdf?__blob=publicationFile
import giro from '../../../public/static/data/tagesgeld2.json' //https://de.statista.com/statistik/daten/studie/202273/umfrage/entwicklung-des-zinssatzes-fuer-tagesgeld-in-deutschland/
import festgeld from '../../../public/static/data/festgeld.json' //https://de.statista.com/statistik/daten/studie/202294/umfrage/entwicklung-des-zinssatzes-fuer-termingeld-in-deutschland/

export default {
  name: 'Vermoegen',
  components: {
    ControllWrapper, AppSlider
  },
  data() { return {
    state: this.$store.state,
    dataLastYear: 2023,
    msciworld: msciworld,
    abgeltungssteuersatz: 0.26375,
    freibetrag: 1000,
    somethingHasChanged: false,
    fondspolice: {
      garantierterZins: 1.75,
      garantieAnteil: 50
    },
    fondssparen: {
      switchFaktor: 0.5,
      anteilZinsenDividenden: 0.5,
      basiszins: 0.0255
    },
    immobilienkredit: {
      zins: 3.92,
      tilgung: 1.5
    },
    ui: {
      showInflation: false
    },
    chart: {
      tooltip: {
        show: false,
        trigger: 'axis',
        valueFormatter: (value) => Number(value).toFixed(0),
        formatter: (series) => {
          for (let item of series) {
            if (!item.seriesName) continue;
            let f = this.chart.series.find((o) => o.name == item.seriesName)
            if (!f) continue;
            item.lastValue = f.data.at(-1)[1]
          }
          let str = '<div class="flex flex-col gap-1">'
          for (let item of series) {
            if (['Sparpotential', 'Inflation'].includes(item.seriesName)) continue;
            let newStr = `
            <div class="flex flex-row gap-2 w-full items-center">
            <div class="">
              <div class="w-3 h-3 rounded-full" style="background:${item.color}"></div>
            </div>
            <div class="">
            ${item.seriesName}:
            </div>
            <div class="text-right grow ml-2">
            ${Number(Number(item.lastValue).toFixed(0)).toLocaleString()}€
            </div>
            </div>`
            str = str + newStr
          }
          str = str + "</div>"
          return str
        }
      },
      legend: {
        show: true,
        align: "left",
        left: "35",
        /*
        data: [{
          name: 'Deine Einzahlungen'
        },
        {
          name: 'Sparpotential'
        },
        {
          name: 'Geschätzte Inflation'
        }]
        */
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
        }
      },
      xAxis: {
        type: 'value',
        //boundaryGap: false,
        splitNumber: 5,
        splitLine: {
          show: false
        },
        max: function (value) {
            return value.max;
        },
        axisLabel: {
          formatter: (value, index) => {
            if (this.compOneSparformActive) {
              let result = value + this.dataLastYear - this.state.app.vermoegen.sparphaseJahre
              return result
            } else {
              return value + " Jahre"
            }
          },
          fontSize: 13
        }
      },
      yAxis: {
        show: true,
        inverse: false,
        position: 'right',
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dotted'
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          formatter: function (value, index) {
            return value.toLocaleString() + '€';
          },
          fontSize: 13
        }
      },
      series: [
        {
          endLabel: {
            show: true,
            formatter: function (params) {
              return `${Number(params.value[1]).toLocaleString()}€`;
            },
            backgroundColor: '#1E86CB',
            color: '#FFFFFF',
            padding: 4
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          emphasis: {
            focus: 'series'
          },
          silent: true,
          lineStyle: {
            width:3,
            opacity:1
          },
          name: 'Deine Einzahlungen',
          type: 'line',
          smooth: true,
          //stack: 'Total',
          areaStyle: {},
          symbol: 'none',
          data: [[0, 0]],
          color:'#1E86CB'
        },
        {
          lineStyle: {
            width:3,
            opacity:1
          },
          emphasis: {
            focus: 'series'
          },
          silent: true,
          endLabel: {
            show: true,
            formatter: function (params) {
              return `${Number(params.value[1]).toLocaleString()}€`;
            },
            backgroundColor: '#7FBAE1',
            color: '#FFFFFF',
            padding: 4
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          name: 'Dein Sparpotential',
          type: 'line',
          smooth: false,
          //stack: 'Total',
          symbol: 'none',
          areaStyle: {},
          data: [[0,'0']],
          color: "rgba(30, 134, 203, 0.4)"
        },
        /*
        {
          name: 'Geschätzte Inflation',
          emphasis: {
            focus: 'series'
          },
          silent: false,
          endLabel: {
            show: true,
            formatter: function (params) {
              return `${params.value[1].toLocaleString()}€`;
            },
            backgroundColor: '#0F558D',
            color: '#FFFFFF',
            padding: 4
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          type: 'line',
          smooth: false,
          //stack: 'Total',
          symbol: 'none',
          lineStyle: {
            type: 'dotted'
          },
          data: [[0,'0']],
          color: "#0F558D"
        }
        */
      ]
    },
    pdfChart: {
      tooltip: {
        show: false,
        trigger: 'axis',
        valueFormatter: (value) => Number(value).toFixed(0),
        formatter: (series) => {
          for (let item of series) {
            if (!item.seriesName) continue;
            let f = this.chart.series.find((o) => o.name == item.seriesName)
            if (!f) continue;
            item.lastValue = f.data.at(-1)[1]
          }
          let str = '<div class="flex flex-col gap-1">'
          for (let item of series) {
            if (['Sparpotential', 'Inflation'].includes(item.seriesName)) continue;
            let newStr = `
            <div class="flex flex-row gap-2 w-full items-center">
            <div class="">
              <div class="w-3 h-3 rounded-full" style="background:${item.color}"></div>
            </div>
            <div class="">
            ${item.seriesName}:
            </div>
            <div class="text-right grow ml-2">
            ${Number(Number(item.lastValue).toFixed(0)).toLocaleString()}€
            </div>
            </div>`
            str = str + newStr
          }
          str = str + "</div>"
          return str
        }
      },
      legend: {
        show: true,
        align: "left",
        left: "35",
        /*
        data: [{
          name: 'Deine Einzahlungen'
        },
        {
          name: 'Sparpotential'
        },
        {
          name: 'Geschätzte Inflation'
        }]
        */
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
        }
      },
      xAxis: {
        type: 'value',
        //boundaryGap: false,
        splitNumber: 5,
        splitLine: {
          show: false
        },
        max: function (value) {
            return value.max;
        },
        axisLabel: {
          formatter: (value, index) => {
            if (this.compOneSparformActive) {
              let result = value + this.dataLastYear - this.state.app.vermoegen.sparphaseJahre
              return result
            } else {
              return value + " Jahre"
            }
          },
          fontSize: 13
        }
      },
      yAxis: {
        show: true,
        inverse: false,
        position: 'right',
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dotted'
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          formatter: function (value, index) {
            return value.toLocaleString() + '€';
          },
          fontSize: 13
        }
      },
      series: [
        {
          endLabel: {
            show: true,
            formatter: function (params) {
              return `${Number(params.value[1]).toLocaleString()}€`;
            },
            backgroundColor: '#1E86CB',
            color: '#FFFFFF',
            padding: 4
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          emphasis: {
            focus: 'series'
          },
          silent: true,
          lineStyle: {
            width:3,
            opacity:1
          },
          name: 'Deine Einzahlungen',
          type: 'line',
          smooth: true,
          //stack: 'Total',
          areaStyle: {},
          symbol: 'none',
          data: [[0, 0]],
          color:'#1E86CB'
        },
        {
          lineStyle: {
            width:3,
            opacity:1
          },
          emphasis: {
            focus: 'series'
          },
          silent: true,
          endLabel: {
            show: true,
            formatter: function (params) {
              return `${Number(params.value[1]).toLocaleString()}€`;
            },
            backgroundColor: '#7FBAE1',
            color: '#FFFFFF',
            padding: 4
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          name: 'Dein Sparpotential',
          type: 'line',
          smooth: false,
          //stack: 'Total',
          symbol: 'none',
          areaStyle: {},
          data: [[0,'0']],
          color: "rgba(30, 134, 203, 0.4)"
        },
        /*
        {
          name: 'Geschätzte Inflation',
          emphasis: {
            focus: 'series'
          },
          silent: false,
          endLabel: {
            show: true,
            formatter: function (params) {
              return `${params.value[1].toLocaleString()}€`;
            },
            backgroundColor: '#0F558D',
            color: '#FFFFFF',
            padding: 4
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          type: 'line',
          smooth: false,
          //stack: 'Total',
          symbol: 'none',
          lineStyle: {
            type: 'dotted'
          },
          data: [[0,'0']],
          color: "#0F558D"
        }
        */
      ]
    },
  }},
  mounted() {
    chart = echartInit(document.getElementById('echart'))
    pdfChart = echartInit(document.getElementById('pdfChart'))
    // pdfChart = echartInit(document.getElementById('pdfChart'))
    /*
    chart.on('finished', () => {
      if (!this.ui.showInflation)
        chart.dispatchAction({
          type: 'legendUnSelect',
          name: "Geschätzte Inflation"
        })
    })
    chart.on('legendselectchanged', (params) => {
      if (params.selected['Geschätzte Inflation']) {
        this.ui.showInflation = !this.ui.showInflation
      }
      else this.ui.showInflation = false
    })
    */
  
    this.updateData()
    let a = this.calculateAnnualReturn(0, 46435, this.state.app.vermoegen.sparbetragMtl, this.state.app.vermoegen.sparphaseJahre)
    let b = this.calculateAnnualReturn2(0, 46435, this.state.app.vermoegen.sparbetragMtl, this.state.app.vermoegen.sparphaseJahre)

    console.log("a b", a, b)
  },
  watch: {
    'state.app.vermoegen.sparbetragMtl' : {
      handler: function(newVal, oldVal) {
        this.somethingHasChanged = true;
      },
      deep: false
    },
    "ui.showInflation": function(n, o) {
      //console.log("showInflation watcher new value", n)
      if (n) {
        chart.dispatchAction({
          type: 'legendSelect',
          name: "Geschätzte Inflation"
        })
        pdfChart.dispatchAction({
          type: 'legendSelect',
          name: "Geschätzte Inflation"
        })
      } else {
        chart.dispatchAction({
          type: 'legendUnSelect',
          name: "Geschätzte Inflation"
        })
        pdfChart.dispatchAction({
          type: 'legendUnSelect',
          name: "Geschätzte Inflation"
        })
      }
    }
  },
  computed: {
    compOneSparformActive() {
      for (let item of this.state.app.vermoegen.sparformen) {
        if (item.active || item.autoActive) return true
      }
      return false
    },
    compSparPotentialData() {
      let princ = 0 // start deposit
      let add = this.state.app.vermoegen.sparbetragMtl // monthly deposit (need plus it every year)
      let rate = this.state.app.vermoegen.renditeziel / 100 // interest rate divided to create decimal
      let months = (this.state.app.vermoegen.sparphaseJahre * 12) //10 years of monthly contributions
      let monthCounter = 0
      let yearCounter = 0
      let data = [[0,'0']]
      for (let i = 1; i <= months; i++) {
        monthCounter++
        princ += add
        princ += princ * (rate / 12)
        if (monthCounter == 12) {
          yearCounter++
          monthCounter = 0
          data.push([yearCounter, princ.toFixed(0)])
        }
      }
      return data
    },
    compEinzahlungenData() {
      let data = [[0,'0']]
      for (let i = 1; i <= this.state.app.vermoegen.sparphaseJahre; i++) {
        let betrag = i * 12 * this.state.app.vermoegen.sparbetragMtl
        data.push([i, betrag])
      }
      return data
    },
    compInflationData() {
      let data = [[0,'0']]
      for (let i = 1; i <= this.state.app.vermoegen.sparphaseJahre; i++) {
        let betrag = i * 12 * this.state.app.vermoegen.sparbetragMtl
        let faktor = Math.pow(
        1 + this.state.app.vermoegen.durchschnittInflationsrate / 100,
        -1 * i)
        let result = Math.round(betrag / faktor)
        data.push([i, result])
      }
      return data
    }
  },
  methods: {
    calculateAnnualReturn(startValue, endValue, monthlyDeposit, years) {
        const months = years * 12;
        const cashFlows = new Array(months).fill(-monthlyDeposit);
        cashFlows[0] -= startValue; // adjust the first value by start value
        cashFlows[cashFlows.length - 1] += endValue; // adjust the last value by end value

        let guess = 0.05; // initial guess for IRR
        const tolerance = 0.0001; // change as per your requirement
        let step = 0.05; // step size

        while (step >= tolerance) {
            let result = cashFlows.reduce((acc, val, i) => {
                return acc + (val / Math.pow(1 + guess, i / 12)); // using the formula of IRR
            }, 0);

            if (Math.abs(result) < tolerance) {
                break;
            }

            if (result > 0) {
                guess += step;
            } else {
                guess -= step;
                step /= 10;
            }
        }

        return (guess * 100).toFixed(2); // convert to percentage and round to two decimal places
    },
    calculateAnnualReturn2(startValue, endValue, monthlyDeposit, years) {
        const months = years * 12;
        const cashFlows = new Array(months).fill(-monthlyDeposit);
        cashFlows[0] -= startValue; // adjust the first value by start value
        cashFlows[cashFlows.length - 1] += endValue; // adjust the last value by end value

        let guess = 0.05; // initial guess for IRR
        const tolerance = 0.00001; // change as per your requirement

        for (let i = 0; i < 100000; i++) { // limiting iterations to 100
            const fValue = cashFlows.reduce((acc, val, i) => {
                return acc + (val / Math.pow(1 + guess, i / 12)); // using the formula of IRR
            }, 0);

            const fPrimeValue = cashFlows.reduce((acc, val, i) => {
                return acc - (i * val / 12) / Math.pow(1 + guess, i / 12 + 1); // derivative of the formula
            }, 0);

            const newGuess = guess - fValue / fPrimeValue; // Newton-Raphson update

            if (Math.abs(newGuess - guess) < tolerance) { // if the guess is not changing significantly, we have found our answer
                guess = newGuess;
                break;
            }
            guess = newGuess;
        }

        return (guess * 100).toFixed(2); // convert to percentage and round to two decimal places
    },
    updateData(data) {
      console.log("update chart data")

      for (let item of this.state.app.vermoegen.sparformen) {
        item.autoActive = false
      }
      //activates the top sparform
      if (this.$store.compSparformenRanking[0]) {
        let id = this.$store.compSparformenRanking[0].id
        let f = this.state.app.vermoegen.sparformen.find((o) => o.id === id)
        if (f) f.autoActive = true
      }

      //sets not visible sparformen to inactive
      for (let item of this.state.app.vermoegen.sparformen.filter((o) => o.active)) {
        let f = this.$store.compSparformenRanking.find((p) => p.id === item.id)
        if (!f) item.active = false
      }

      //this.chart.series[0].data = [[0,0],[this.state.app.vermoegen.sparphaseJahre, this.state.app.vermoegen.sparphaseJahre * 12 * this.state.app.vermoegen.sparbetragMtl]]
      this.chart.series[0].data = this.compEinzahlungenData
      this.chart.series[1].data = this.compSparPotentialData
      //this.chart.series[0].data = [[0,0],[this.state.app.vermoegen.sparphaseJahre, this.state.app.vermoegen.sparphaseJahre * 12 * this.state.app.vermoegen.sparbetragMtl]]
      this.pdfChart.series[0].data = this.compEinzahlungenData
      this.pdfChart.series[1].data = this.compSparPotentialData
      //this.chart.series[2].data = this.compInflationData

      this.updateSingleChart(this.state.app.vermoegen.sparformen[0], 'Fondsrente ohne Garantie', this.calcMsciWorldChartData(), "#FABE50")
      //rechne ohne Steuern und ohne Kosten
      this.calcMsciWorldChartData(true, true)
      //rechne ohne Steuern aber mit Kosten
      this.calcMsciWorldChartData(true, false)

      this.updateSingleChart(this.state.app.vermoegen.sparformen[1], 'Fondsrente mit Garantie', this.calcMsciWorldMitGarantieChartData(), "#FF7A00")
      //rechne ohne Steuern und ohne Kosten
      this.calcMsciWorldMitGarantieChartData(true, true)
      //rechne ohne Steuern aber mit Kosten
      this.calcMsciWorldMitGarantieChartData(true, false)

      this.updateSingleChart(this.state.app.vermoegen.sparformen[7], 'Gold', this.calcGoldChartData(), "#f0890c")
      //this can be used for tagesgeld later
      //this.updateSingleChart(this.state.app.vermoegen.sparformen[5], 'Girokonto', this.calcGiroChartData(), "#7738A9")
      //Girikonto has no interest
      this.updateSingleChart(this.state.app.vermoegen.sparformen[5], 'Girokonto', this.calcGeldUntermKopfkissenData(), "#187900")
      this.updateSingleChart(this.state.app.vermoegen.sparformen[3], 'Kreditfinanzierte Immobilie', this.calcImmobilienChartDataByIndexAndCredit(), "#00C2FF")
      this.updateSingleChart(this.state.app.vermoegen.sparformen[6], 'Geld unterm Kopfkissen', this.calcGeldUntermKopfkissenData(), "#86C600")
      this.updateSingleChart(this.state.app.vermoegen.sparformen[4], 'Festgeld', this.calcFestgeldData(), "#7738A9")

      this.updateSingleChart(this.state.app.vermoegen.sparformen[2], 'Fondssparplan', this.calcMsciWorldFondssparenChartData(), "#CF0BA4")
      //rechne ohne Steuern und ohne Kosten
      this.calcMsciWorldFondssparenChartData(true, true)
      //rechne ohne Steuern aber mit Kosten
      this.calcMsciWorldFondssparenChartData(true, false)

      if (chart) {
        
        chart.setOption(this.chart, false)

      }

      if (pdfChart) {

        console.log("pdfChart", pdfChart)
        
        if (this.pdfChart.series.length >= 3) {
          this.pdfChart.series.splice(2,1);
        }
        if (this.$store.compSparformenRanking.length > 0 ){
        // && this.$store.compSparformenRanking.every(obj => obj.active === false)) {
          // console.log("Top-Sparform:",this.$store.compSparformenRanking[0].title);

          //hier werden die Berechnungen für die PDF gemacht, sofern die Berechnung nicht schon stattgefunden haben
          if (this.$store.compSparformenRanking.every(obj => obj.active === false && obj.autoActive === false)) {
            switch (this.$store.compSparformenRanking[0].title) {
              case "Fondsrente ohne Garantie":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[0], 'Fondsrente ohne Garantie', this.calcMsciWorldChartData(), "#FABE50")
                //rechne ohne Steuern und ohne Kosten
                this.calcMsciWorldChartData(true, true)
                //rechne ohne Steuern aber mit Kosten
                this.calcMsciWorldChartData(true, false)
                break;
              case "Fondsrente mit Garantie":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[1], 'Fondsrente mit Garantie', this.calcMsciWorldMitGarantieChartData(), "#FF7A00")
                //rechne ohne Steuern und ohne Kosten
                this.calcMsciWorldMitGarantieChartData(true, true)
                //rechne ohne Steuern aber mit Kosten
                this.calcMsciWorldMitGarantieChartData(true, false)
                break;
              case "Gold":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[7], 'Gold', this.calcGoldChartData(), "#f0890c")
                break;
              case "Girokonto":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[5], 'Girokonto', this.calcGiroChartData(), "#187900")
                break;
              case "Kreditfinanzierte Immobilie":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[3], 'Kreditfinanzierte Immobilie', this.calcImmobilienChartDataByIndexAndCredit(), "#00C2FF")
                break;
              case "Geld unter dem Kopfkissen":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[6], 'Geld unterm Kopfkissen', this.calcGeldUntermKopfkissenData(), "#86C600")
                break;
              case "Festgeld":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[4], 'Festgeld', this.calcFestgeldData(), "#7738A9")
                break;
              case "Fondssparplan":
                this.updatePdfChart(this.state.app.vermoegen.sparformen[2], 'Fondssparplan', this.calcMsciWorldFondssparenChartData(), "#CF0BA4")
                //rechne ohne Steuern und ohne Kosten
                this.calcMsciWorldFondssparenChartData(true, true)
                //rechne ohne Steuern aber mit Kosten
                this.calcMsciWorldFondssparenChartData(true, false)
                break;
            
              default:
                break;
            }
          }
        }

        pdfChart.setOption(this.pdfChart, true)
        
      }
      //this.$refs.chart.setOption(this.chart, true)
      //console.log("chart",chart)

    },
    updateSingleChart(sparform, name, data, color) {
      if (sparform.active || sparform.autoActive) {
        let f = this.chart.series.find((o) => o.name == name)
        if (!f) {
          this.chart.series.push({
            emphasis: {
              focus: 'series'
            },
            silent: false,
            labelLayout: {
              moveOverlap: 'shiftY',
            },
            name: name,
            type: 'line',
            smooth: false,
            //stack: 'Total',
            symbol: 'none',
            showSymbol: false,
            lineStyle: {
              type: 'solid'
            },
            //data: data,
            color: color,
            endLabel: {
              show: true,
              formatter: function (params) {
                //return params.value[1];
                return `${Number(Number(params.value[1]).toFixed(0)).toLocaleString()}€`;
              },
              backgroundColor: color,
              color: '#FFFFFF',
              padding: 4
            }
          })
          this.chart.series.at(-1).data = data
        }
        else {
          f.data = data
        }
        
        //sparform.durchschnittlicheRendite = this.calculateAnnualReturn(data[0][1], data.at(-1)[1], this.state.app.vermoegen.sparbetragMtl, this.state.app.vermoegen.sparphaseJahre)
        //sparform.durchschnittlicheRendite2 = this.calculateAnnualReturn2(data[0][1], data.at(-1)[1], this.state.app.vermoegen.sparbetragMtl, this.state.app.vermoegen.sparphaseJahre)
      } else {
        let f = this.chart.series.find((o) => o.name == name)
        if (f) {
          console.log("f", f)
          console.log("remove")
          f.name=""
          f.data=[]
        }
      }
      sparform.durchschnittlicheRendite = this.calculateAnnualReturn(data[0][1], data.at(-1)[1], this.state.app.vermoegen.sparbetragMtl, this.state.app.vermoegen.sparphaseJahre)
      console.log("sparform rendite durchschnitt", sparform.durchschnittlicheRendite)
    },
    updatePdfChart(sparform, name, data, color) {
      console.warn({name});
      if (!sparform.active && !sparform.autoActive) {
        let f = this.pdfChart.series.find((o) => o.name == name)        
        if (!f) {
          this.pdfChart.series.push({
            emphasis: {
              focus: 'series'
            },
            silent: false,
            labelLayout: {
              moveOverlap: 'shiftY',
            },
            name: name,
            type: 'line',
            smooth: false,
            //stack: 'Total',
            symbol: 'none',
            showSymbol: false,
            lineStyle: {
              type: 'solid'
            },
            data: data,
            color: color,
            endLabel: {
              show: true,
              formatter: function (params) {
                //return params.value[1];
                return `${Number(Number(params.value[1]).toFixed(0)).toLocaleString()}€`;
              },
              backgroundColor: color,
              color: '#FFFFFF',
              padding: 4
            }
          })
        }
        else {
          console.warn("else f");
          f.data = data
        }
      } else {
        console.warn("else sparform.active === true");
        let f = this.pdfChart.series.find((o) => o.name == name)
        console.warn({f});
        if (f) {
          console.log("remove")
          f.name=""
          f.data=[]
        }
      }
    },
    updateSelected(data) {
      
    },
    //Holt die Reduction in yield Werte, abhängig von der Laufzeit
    getReductionInYield(duration, fondssparen) {
      if (duration <= 12) {
        if (fondssparen) return 1.5
        return 1.7
      }
      if (duration <= 25) {
        if (fondssparen) return 1.4
        return 1.6
      }
      if (duration <= 35) {
        if (fondssparen) return 1.2
        return 1.4
      }
      else {
        if (fondssparen) return 1
        return 1.2
      }
    },
    //Verringert den Wert durch den riy Wert auf monatlicher Basis
    reduceByReductionInYieldValue(value, monthCounter, isFondssparen) {
      let year = Math.ceil(monthCounter / 12)
      let riy = this.getReductionInYield(year, isFondssparen)
      //monatlicher Prozentsatz riy
      riy = riy / 100 / 12
      value = value - (value * riy)
      return value
    },
    calcMsciWorld(type, sparBetrag, withoutTaxes = false, withoutCosts = false) {
      let valueArray = []
      let anzahlMonate = this.state.app.vermoegen.sparphaseJahre * 12
      //der Startindex des MSCI World wird anhand der angegeben Spardauer festgelegt
      let startIndex = this[type].length - 1 - anzahlMonate
      if (!sparBetrag) sparBetrag = this.state.app.vermoegen.sparbetragMtl
      if (startIndex < 0) {
        let amountMinus = Math.abs(startIndex)
        for (let z = 0; z < amountMinus; z++) {
          valueArray.push(null)
        }
        startIndex = 0
      }
      let gesamtBetrag = 0
      // Startwert des Index. Gilt als Referenzwert für spätere Käufe.
      let msciStartValue = this[type][startIndex]["Price"]
      let boughtShares = 0
      let monthCounter = 1
      //geht den Index ab dem Startwert bis zum aktuellsten Wert durch
      for (let i = startIndex; i < this[type].length; i++) {
        let currentMsci = this[type][i]
        //errechnet den bisher investierten Gesamtbetrag
        gesamtBetrag = gesamtBetrag + sparBetrag
        //errechnet die gekauften Anteile 
        let boughtShare = msciStartValue / currentMsci["Price"] * sparBetrag
        //addiere vergangene Anteile mit den dazugekauften
        boughtShares = boughtShares + boughtShare
        //wenn es sich bei der Berechnung um den MSCI World handelt, dann reduziere die gekauften Shares um den "Reduction in yield" Faktor, es sei denn es soll explizit ohne Kosten gerechnet werden
        if (type == 'msciworld' && !withoutCosts) boughtShares = this.reduceByReductionInYieldValue(boughtShares, anzahlMonate, false)
        //errechne den Wert der aktuell gehaltenen Anteile
        let currentShareValue = boughtShares * currentMsci["Price"] / msciStartValue
        valueArray.push(currentShareValue)
        monthCounter++
      }
      let currentMsciValue = this[type][this[type].length - 1]["Price"]
      //gibt das Array mit der monatlichen Wertentwicklung zurück
      return valueArray
    },
    //generiert die PDF Werte für eine fondsgebundene Sparformen (don't repeat yourself function)
    generatePDFValues(name, data, withoutTaxes = false, withoutCosts = false) {
      let roi = this.calculateAnnualReturn(data[0][1], data.at(-1)[1], this.state.app.vermoegen.sparbetragMtl, this.state.app.vermoegen.sparphaseJahre)
      //wenn die Steuern nicht abgezogen werden, wird der entsprechende PDF Wert gesetzt
      if (withoutTaxes && !withoutCosts) {
        this.state.app.vermoegen.pdfValues[name + " ohne Steuern"] = data.at(-1)[1]
        this.state.app.vermoegen.pdfValues[name + " ohne Steuern ROI"] = roi
      }
      //wenn Steuern und Kosten nicht abgezogen werden, wird der entsprechende PDF Wert gesetzt
      if (withoutTaxes && withoutCosts) {
        this.state.app.vermoegen.pdfValues[name + " ohne Steuern und Kosten"] = data.at(-1)[1]
        this.state.app.vermoegen.pdfValues[name + " ohne Steuern und Kosten ROI"] = roi
      }
      //wenn die Steuern abgezogen werden, wird der entsprechende PDF Wert gesetzt
      if (!withoutTaxes && !withoutCosts) {
        this.state.app.vermoegen.pdfValues[name] = data.at(-1)[1]
        this.state.app.vermoegen.pdfValues[name + " ROI"] = roi
      }
      console.log(name + ", ohne Steuern: " + withoutTaxes + ", ohne Kosten: " + withoutCosts, data.at(-1)[1])
      console.log(name + "ROI , ohne Steuern: " + withoutTaxes + ", ohne Kosten: " + withoutCosts, roi, data)
    },
    //Hier wird das Array für die Fondsrente ohne Garantie erstellt
    //wenn withoutTaxes true ist, dann wird die Steuer nicht abgezogen, wenn withoutCosts true ist, dann werden die Kosten nicht abgezogen
    calcMsciWorldChartData(withoutTaxes = false, withoutCosts = false) {
      //Die grundlegende Berechnung wird durchgeführt
      let msciWorldData = this.calcMsciWorld('msciworld', null, withoutTaxes, withoutCosts)
      let result = []
      let i = 0
      //Das Array für den Chart wird erstellt
      for (let c = 0; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        let item = msciWorldData[i]
        //für jedes Jahr wird der passende Wert der Entwicklung zugewiesen
        result.push([c, item])
        i = i + 12
      }
      //wenn keine Lebenslangerente aktiviert wurde, wird davon ausgegangen, dass das Vermögen am Ende ausgezahlt werden soll und das wird entsprechend versteuert
      if (!this.state.app.vermoegen.model.lebenslangeRente && !withoutTaxes) result = this.reduceFondsPoliceChartDataByTaxes(result, 0, true)

      this.generatePDFValues("Fondsrente ohne Garantie", result, withoutTaxes, withoutCosts)

      return result
    },
    /*
    Calculate part of guaranteed and free
    calculate the free part like fondspolice without guarantee and the part with guarantee like a account with fixed interest rate
    finally summarize the values for every month
    BOOM!
    */
    calcMsciWorldMitGarantieChartData(withoutTaxes = false, withoutCosts = false) {
      //eingezahle Beträge Garantieanteil
      let sparBetragGarantie = this.state.app.vermoegen.sparbetragMtl * (this.fondspolice.garantieAnteil / 100)
      //eingezahle Beträge Fondsanteil
      let sparBetrag = this.state.app.vermoegen.sparbetragMtl - sparBetragGarantie
      //errechne Entwicklung des Fondsanteils
      let msciWorldData = this.calcMsciWorld('msciworld', sparBetrag, withoutTaxes, withoutCosts)
      let result = []
      let i = 0
      //erstelle Array des Fondsanteils
      for (let c = 0; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        let item = msciWorldData[i]
        result.push([c, item])
        i=i+12
      }
      let fondsResultWithoutTaxes = JSON.parse(JSON.stringify(result))
      //ziehe die Steuern für den Fondsanteil ab
      let newResult
      if (!this.state.app.vermoegen.model.lebenslangeRente && !withoutTaxes) newResult = JSON.parse(JSON.stringify(this.reduceFondsPoliceChartDataByTaxes(result, this.fondspolice.garantieAnteil, true)))
      if (!newResult) newResult = JSON.parse(JSON.stringify(result))

      //errechne Entwicklung des Garantieanteils
      let garantieResult = this.calcFesteVerzinsung(sparBetragGarantie, this.fondspolice.garantierterZins, this.state.app.vermoegen.sparphaseJahre)
      let garantieResultWithoutTaxes = JSON.parse(JSON.stringify(garantieResult))
      //ziehe die Steuern für den Garantieanteil ab
      if (!this.state.app.vermoegen.model.lebenslangeRente && !withoutTaxes) garantieResult = this.reduceFondsPoliceGuaranteeChartDataByTaxes(garantieResult, true)

      for (let i in result) {
        result[i][1] = Number(newResult[i][1]) + Number(garantieResult[i][1])
      }
      //if (!this.state.app.vermoegen.model.lebenslangeRente) result = this.reduceFondsPoliceChartDataByTaxes(result, this.fondspolice.garantieAnteil)

      this.generatePDFValues("Fondsrente mit Garantie", result, withoutTaxes, withoutCosts)

      //console.log("(ohne Steuern: "+withoutTaxes+", ohne Kosten: "+withoutCosts+") calcMsciWorldMitGarantieChartData", result.at(-1)[1], "Fondsanteil vor Steuern", fondsResultWithoutTaxes.at(-1)[1], "Fondsanteil nach Steuern", newResult.at(-1)[1], "Garantieanteil vor Steuern", garantieResultWithoutTaxes.at(-1)[1], "Garantieanteil nach Steuern", garantieResult.at(-1)[1])
      return result
    },
    calcFesteVerzinsung(sparbetrag, festerZins, dauerJahre) {
      let princ = 0 // start deposit
      let add = sparbetrag // monthly deposit (need plus it every year)
      let rate = festerZins / 100 // interest rate divided to create decimal
      let months = (dauerJahre * 12) //10 years of monthly contributions
      let monthCounter = 0
      let yearCounter = 0
      let data = [[0,'0']]
      for (let i = 1; i <= months; i++) {
        monthCounter++
        princ += add
        princ += princ * (rate / 12)
        if (monthCounter == 12) {
          yearCounter++
          monthCounter = 0
          data.push([yearCounter, princ.toFixed(0)])
        }
      }
      return data
    },
    calcMsciWorldFondssparenChartData(withoutTaxes = false, withoutCosts = false) {
      //errechnet wie bei der Fondsrente die Grundperformance über den MSCI World
      let resultObject = this.calcMsciWorldFondssparen('msciworld', null, withoutTaxes, withoutCosts)
      let savedVorabpauschale = resultObject.savedVorabpauschale
      let msciWorldData = resultObject.valueArray
      let result = []
      let i = 0
      for (let c = 0; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        let item = msciWorldData[i]
        result.push([c, item])
        i=i+12
      }
      //reduziert das Ergebnis um die anfallende Steuer
      if (!withoutTaxes) result = this.reduceFondssparenChartDataByTaxes(result, true, savedVorabpauschale)

      this.generatePDFValues("Fondssparplan", result, withoutTaxes, withoutCosts)

      return result
    },
    /*
    kopiert von msci world investment. Hier zusätzlich berücksichtigt die Fondswechsel.
    wenn withoutTaxes true ist, dann wird die Steuer nicht abgezogen, wenn withoutCosts true ist, dann werden die Kosten nicht abgezogen
    */
    calcMsciWorldFondssparen(type, sparBetrag, withoutTaxes = false, withoutCosts = false) {
      let valueArray = []
      //Intervall in denen ein Fondswechsel stattfindet
      let fondsWechselIntervallInMonate = this.state.app.vermoegen.fondsWechselIntervallInMonate
      let anzahlMonate = this.state.app.vermoegen.sparphaseJahre * 12
      //errechnet den Startindex des MSCI World Datensatzes (Beginn der Simulation in der Vergangenheit)
      let startIndex = this[type].length-1 - anzahlMonate
      if (!sparBetrag) sparBetrag = this.state.app.vermoegen.sparbetragMtl
      if (startIndex < 0) {
        let amountMinus = Math.abs(startIndex)
        for (let z = 0; z < amountMinus; z++) {
          valueArray.push(null)
        }
        startIndex = 0
      }
      let gesamtBetrag = 0
      // Startwert des Index. Gilt als Referenzwert für spätere Käufe.
      let msciStartValue = this[type][startIndex]["Price"]
      let boughtShares = 0
      let monthCounterFondsSwitch = 1
      let monthCounterVorabpauschale = 1
      let vorabpauschaleStartMsci = 0
      let vorabpauschaleEndMsci = 0
      let vorabpauschaleStartvermoegen = 0
      let savedVorabpauschale = 0
      for (let i = startIndex; i < this[type].length; i++) {
        let currentMsci = this[type][i]
        //wenn neues Jahr beginnt
        if (!withoutTaxes) {
          if (monthCounterVorabpauschale == 1) {
            //Startwert des MSCI World für das neue Jahr
            vorabpauschaleStartMsci = currentMsci["Price"]
            vorabpauschaleStartvermoegen = boughtShares * currentMsci["Price"] / msciStartValue
            //console.log("vorabpauschaleStartvermoegen", vorabpauschaleStartvermoegen)
          }
          //nach 12 Monaten wird Vorabpauschale berechnet
          if (monthCounterVorabpauschale == 12) {
            //Endwert MSCI World für das laufende Jahr
            vorabpauschaleEndMsci = currentMsci["Price"]
            let faktor = vorabpauschaleEndMsci / vorabpauschaleStartMsci
            let vorabpauschaleEndvermoegen = vorabpauschaleStartvermoegen * faktor
            let resultVorabpauschale = this.calcVorabpauschale(vorabpauschaleStartvermoegen, vorabpauschaleEndvermoegen)
            if (resultVorabpauschale.gezahlteSteuer) {
              //ermittle aktuellen Share-Wert der zu zahlenden Steuer und ziehe sie von den Shares im Besitz ab (Anteile verkaufen)
              let sharesToSell = msciStartValue / currentMsci["Price"] * sparBetrag
              boughtShares = boughtShares - sharesToSell
            }
            savedVorabpauschale = savedVorabpauschale + resultVorabpauschale.vorabpauschale
            //console.log("ergebnis vorabpauschale", resultVorabpauschale, "savedVorabpauschale", savedVorabpauschale)
            monthCounterVorabpauschale = 0
          }
        }
        gesamtBetrag = gesamtBetrag + sparBetrag
        //errechnet die gekauften Anteile 
        let boughtShare = msciStartValue / currentMsci["Price"] * sparBetrag
        //addiere vergangene Anteile mit den dazugekauften
        boughtShares = boughtShares + boughtShare
        //wenn es sich bei der Berechnung um den MSCI World handelt, dann reduziere die gekauften Shares um den "Reduction in yield" Faktor, es sei denn es soll explizit ohne Kosten gerechnet werden
        if (type == 'msciworld' && !withoutCosts) boughtShares = this.reduceByReductionInYieldValue(boughtShares, anzahlMonate, true)
        //errechne den Wert der aktuell gehaltenen Anteile
        let currentShareValue = boughtShares * currentMsci["Price"] / msciStartValue
        //fondswechsel alle x Monate (berechne nur, wenn Steuern berücksichtig werden sollen)
        if (!withoutTaxes && monthCounterFondsSwitch == fondsWechselIntervallInMonate) {
          let newShareValue = this.sellFondsForFondsSwitch(currentShareValue, gesamtBetrag, savedVorabpauschale)
          //console.log("fondswechsel vorher:", currentShareValue, 'nachher:', newShareValue)
          let faktor = newShareValue / currentShareValue
          boughtShares = boughtShares * faktor
          currentShareValue = newShareValue
          monthCounterFondsSwitch = 0
          savedVorabpauschale = 0
        }
        valueArray.push(currentShareValue) 
        monthCounterFondsSwitch++
        monthCounterVorabpauschale++
      }
      return { valueArray, savedVorabpauschale }
    },
    calcVorabpauschale(wertDesFondsJahresbeginn, wertDesFondsJahresende) {
      const basiszins = this.fondssparen.basiszins
      if (typeof wertDesFondsJahresbeginn === 'undefined') throw "Bitte wertDesFondsJahresbeginn übergeben"
      if (typeof wertDesFondsJahresende === 'undefined') throw "Bitte wertDesFondsJahresende übergeben"
      const teilfreistellung = 0.3
      const abgeltungssteuersatz = this.abgeltungssteuersatz
      const freibetrag = this.freibetrag

      //errechne Wertsteigerung
      let wertsteigerung = wertDesFondsJahresende - wertDesFondsJahresbeginn

      //console.log("vorabpauschale wertsteigerung", wertsteigerung)

      //wenn keine Gewinne, dann keine Abgeltungssteuer
      if (wertsteigerung <= 0) return {gezahlteSteuer:0, vorabpauschale:0}

      //Vorabpauschale
      let vorabpauschale = wertDesFondsJahresbeginn * (basiszins * 0.7)

      //console.log("vorabpauschale vorabpauschale (nach * basiszins * 0.7)", vorabpauschale)

      //wenn der Vorabpauschale größer als die Wertsteigerung ist, gilt die Wertsteigerung als vorabpauschale
      if (vorabpauschale > wertsteigerung) vorabpauschale = wertsteigerung

      //console.log("vorabpauschale korrektur", vorabpauschale)

      //beachte die Teilfreistellung
      let vorabpauschaleNachTeilfreistellung = (vorabpauschale * (1 - teilfreistellung)) - freibetrag

      //console.log("vorabpauschale nach teilfreistellung", vorabpauschaleNachTeilfreistellung)

      //die zu zahlende Steuer ohne Freibetrag
      let abgeltungssteuer = vorabpauschaleNachTeilfreistellung * abgeltungssteuersatz
      //console.log("vorabpauschale abgeltungssteuer", abgeltungssteuer)
      //die zu zahlende Steuer ist entweder 0 oder die Abgeltungssteuer - Freibetrag wenn größer als 0
      abgeltungssteuer = Math.max(0, abgeltungssteuer)

      return { gezahlteSteuer: abgeltungssteuer, vorabpauschale: vorabpauschale }
    },
    //hier werden die Steuern für einen Fondsswitch beim Fondssparen ausgerechnet
    sellFondsForFondsSwitch(value, invested, savedVorabpauschale) {
      //es wird angenommen, dass nur x% der Fonds geswitched werden
      let switchFaktor = this.fondssparen.switchFaktor
      let teilfreistellung = 0.3
      let abgeltungssteuersatz = this.abgeltungssteuersatz
      let freibetrag = this.freibetrag
      //Ermittlung des Gewinns
      let gewinn = (value * switchFaktor) - (invested * switchFaktor)
      gewinn = gewinn - savedVorabpauschale
      //wenn kein Gewinn, berechne keine Steuern
      if (gewinn <= 0) return value
      //berechne Gewinn nach Teilfreistellung
      let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung) - freibetrag
      if (gewinnNachTeilfreistellung <= 0) return value
      //Berechne Steuern vom übrig gebliebenen zu versteuerenden Betrag
      let steuern = gewinnNachTeilfreistellung * abgeltungssteuersatz
      value = value - steuern
      return value
    },
    //reduziert die Chartdaten um die angefallene Steuer
    reduceFondssparenChartDataByTaxes(result, onlyLastYear, savedVorabpauschale) {
      let teilfreistellung = 0.3
      let abgeltungssteuersatz = this.abgeltungssteuersatz
      let freibetrag = this.freibetrag

      if (onlyLastYear) {
        let lastItem = result.at(-1)
        let endwert = lastItem[1]
        let eingezahlt = lastItem[0] * 12 * this.state.app.vermoegen.sparbetragMtl
        let gewinn = endwert - eingezahlt
        gewinn = gewinn - savedVorabpauschale
        if (gewinn > 0) {
          let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung) - freibetrag
          let steuern = gewinnNachTeilfreistellung * abgeltungssteuersatz
          steuern = Math.max(0, steuern)
          lastItem[1] = lastItem[1] - steuern
        }
      }
      else {
        //reduziert den jeweiligen Wert un die theoretisch angefallene Steuer
        for (let item in result) {
          let endwert = result[item][1]
          let eingezahlt = result[item][0] * 12 * this.state.app.vermoegen.sparbetragMtl
          let gewinn = endwert - eingezahlt
          if (gewinn < 0) continue;
          let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung) - freibetrag
          let steuern = gewinnNachTeilfreistellung * abgeltungssteuersatz
          steuern = Math.max(0, steuern)
          result[item][1] = result[item][1] - steuern
        }
      }
      return result
    },
    //reduziert den Gewinn aus der Veräußerung der Immobilie
    reduceImmobilienChartDataByTaxes(result, onlyLastYear) {
      let teilfreistellung = 0.6
      let abgeltungssteuersatz = this.abgeltungssteuersatz
      let freibetrag = this.freibetrag
      if (onlyLastYear) {
        let lastItem = result.at(-1)
        //der aktuelle Endwert der Immobilie
        let endwert = lastItem[1]
        //wie viel bisher eingezahlt wurde
        let eingezahlt = lastItem[0] * 12 * this.state.app.vermoegen.sparbetragMtl
        console.log("immo eingezahlt", eingezahlt)
        //ziehe vom Gewinn den Freibetrag ab
        let gewinn = endwert - eingezahlt
        if (gewinn < 0) gewinn = 0
        //ziehe Teilfreistellung ab
        let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung) - freibetrag
        //berechne Steuern mit Abgeltungssteuersatz
        let steuern = gewinnNachTeilfreistellung * abgeltungssteuersatz
        steuern = Math.max(0, steuern)
        lastItem[1] = lastItem[1] - steuern
      } else {
        for (let item in result) {
          //der aktuelle Endwert der Immobilie
          let endwert = result[item][1]
          //wie viel bisher eingezahlt wurde
          let eingezahlt = result[item][0] * 12 * this.state.app.vermoegen.sparbetragMtl
          //ziehe vom Gewinn den Freibetrag ab
          let gewinn = endwert - eingezahlt
          if (gewinn < 0) continue
          //ziehe Teilfreistellung ab
          let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung) - freibetrag
          //berechne Steuern mit Abgeltungssteuersatz
          let steuern = gewinnNachTeilfreistellung * abgeltungssteuersatz
          steuern = Math.max(0, steuern)
          result[item][1] = result[item][1] - steuern
        }
      }
      
      return result
    },
    //https://www.versicherungsmakler-frf.de/investmentsteuerreform-2018-auswirkungen-auf-fondspolicen/
    reduceFondsPoliceChartDataByTaxes(result, garantieAnteil = 0, onlyLastYear) {
      let fondsAnteil = (100 - garantieAnteil) / 100
      //errechnet den Fondsanteil
      let teilfreistellung = 0.15 //
      //angenommener persönlicher Steuersatz (Stellschraube)
      let persoenlicherSteuersatz = 0.27
      let halbeinkunftverfahren = 0.5
      //wenn die Sparphase weniger als 12 Jahre lang ist, entfällt das Halbeinkunftverfahren (Faktor wird auf 1 gesetzt und hat somit keine Auswirkung)
      if (this.state.app.vermoegen.sparphaseJahre < 12) halbeinkunftverfahren = 1
      
      if (onlyLastYear) {
        let lastItem = result.at(-1)
        //Aktueller Wert des Vermögens
        let endwert = lastItem[1]
        //Summe der eingezahlten Beträge für den Fondsanteil
        let eingezahlt = lastItem[0] * 12 * this.state.app.vermoegen.sparbetragMtl * fondsAnteil
        //Gewinn
        let gewinn = endwert - eingezahlt
        //Ohne Gewinn fallen keine Steuern an
        if (gewinn < 0) gewinn = 0
        //Anrechnung der Teilfreistellung
        let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung)
        //Anrechnung des Halbeinkunftverfahren
        let gewinnNachHalbeinkunft = gewinnNachTeilfreistellung * halbeinkunftverfahren
        //zu zahlende Steuer ergibt sich aus der Summe des zu versteuernden Anteils des Fondsanteils der Police sowie des Gewinns aus dem Garantieanteil
        let steuern = gewinnNachHalbeinkunft * persoenlicherSteuersatz
        //reduziere aktuellen Datensatz um die Steuer
        lastItem[1] = lastItem[1] - steuern
        console.log("Steuerschlußberechnung " + (garantieAnteil > 0 ? "Fondsrente ohne Garantie" : "Fondsrente mit Garantie, Fondsanteil:"), "eingezahlt", eingezahlt, "gewinn", gewinn, "gewinn nach Teilfreistellung", gewinnNachTeilfreistellung, "gewinn nach Halbeinkunft", gewinnNachHalbeinkunft, "steuern", steuern)
      }
      else {
        //Reduziere den Endwert jedes Jahres um die theoretisch angefallene Steuer, im Falle einer Auszahlung
        for (let item in result) {
          //Aktueller Wert des Vermögens
          let endwert = result[item][1]
          //Summe der eingezahlten Beträge für den Fondsanteil
          let eingezahlt = result[item][0] * 12 * this.state.app.vermoegen.sparbetragMtl * fondsAnteil
          //Gewinn
          let gewinn = endwert - eingezahlt
          //Ohne Gewinn fallen keine Steuern an
          if (gewinn < 0) continue
          //Anrechnung der Teilfreistellung
          let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung)
          //Anrechnung des Halbeinkunftverfahren
          let gewinnNachHalbeinkunft = gewinnNachTeilfreistellung * halbeinkunftverfahren
          //zu zahlende Steuer ergibt sich aus der Summe des zu versteuernden Anteils des Fondsanteils der Police sowie des Gewinns aus dem Garantieanteil
          let steuern = gewinnNachHalbeinkunft * persoenlicherSteuersatz
          //reduziere aktuellen Datensatz um die Steuer
          result[item][1] = result[item][1] - steuern
          if (result[item][1] == result.at(-1)[1]) {
            console.log("Steuerschlußberechnung " + (garantieAnteil > 0 ? "Fondsrente ohne Garantie" : "Fondsrente mit Garantie, Fondsanteil:"), "eingezahlt", eingezahlt, "gewinn", gewinn, "gewinn nach Teilfreistellung", gewinnNachTeilfreistellung, "gewinn nach Halbeinkunft", gewinnNachHalbeinkunft, "steuern", steuern)
          }
        }
      }
      //Gibt das um die Steuer reduzierte Array zurück
      return result
    },
    //reduziert den Garantieanteil einer Fondsrente um die Steuer (Keine Teilfreistellung)
    reduceFondsPoliceGuaranteeChartDataByTaxes(result, onlyLastYear) {
      let persoenlicherSteuersatz = 0.27
      let halbeinkunftverfahren = 0.5
      if (this.state.app.vermoegen.sparphaseJahre < 12) halbeinkunftverfahren = 1
      if (onlyLastYear) {
        let lastItem = result.at(-1)
        //Aktueller Wert des Vermögens
        let endwert = lastItem[1]
        //Summe der eingezahlten Beträge
        let eingezahlt = lastItem[0] * 12 * this.state.app.vermoegen.sparbetragMtl * (this.fondspolice.garantieAnteil / 100)
        //Gewinn
        let gewinn = endwert - eingezahlt
        //Ohne Gewinn fallen keine Steuern an
        if (gewinn < 0) gewinn = 0
        //Anrechnung des Halbeinkunftverfahren
        let gewinnNachHalbeinkunft = gewinn * halbeinkunftverfahren
        //zu zahlende Steuer ergibt sich aus der Summe des zu versteuernden Anteils des Fondsanteils der Police sowie des Gewinns aus dem Garantieanteil
        let steuern = gewinnNachHalbeinkunft * persoenlicherSteuersatz
        //reduziere aktuellen Datensatz um die Steuer
        lastItem[1] = lastItem[1] - steuern
        console.log("Steuerschlußberechnung Fondsrente mit Garantie, Garantieanteil:", "eingezahlt", eingezahlt, "gewinn", gewinn, "gewinn nach Halbeinkunft", gewinnNachHalbeinkunft, "steuern", steuern)
      }
      //Reduziere den Endwert jedes Jahres um die theoretisch angefallene Steuer, im Falle einer Auszahlung
      for (let item in result) {
        //Aktueller Wert des Vermögens
        let endwert = result[item][1]
        //Summe der eingezahlten Beträge
        let eingezahlt = result[item][0] * 12 * this.state.app.vermoegen.sparbetragMtl * (this.fondspolice.garantieAnteil / 100)
        //Gewinn
        let gewinn = endwert - eingezahlt
        //Ohne Gewinn fallen keine Steuern an
        if (gewinn < 0) continue
        //Anrechnung des Halbeinkunftverfahren
        let gewinnNachHalbeinkunft = gewinn * halbeinkunftverfahren
        //zu zahlende Steuer ergibt sich aus der Summe des zu versteuernden Anteils des Fondsanteils der Police sowie des Gewinns aus dem Garantieanteil
        let steuern = gewinnNachHalbeinkunft * persoenlicherSteuersatz
        //reduziere aktuellen Datensatz um die Steuer
        result[item][1] = result[item][1] - steuern
        if (result[item][1] == result.at(-1)[1]) {
          console.log("Steuerschlußberechnung Fondsrente mit Garantie, Garantieanteil:", "eingezahlt", eingezahlt, "gewinn", gewinn, "gewinn nach Halbeinkunft", gewinnNachHalbeinkunft, "steuern", steuern)
        }
      }
      //Gibt das um die Steuer reduzierte Array zurück
      return result
    },
    /*
    Funktion derzeit nicht gebraucht, da sie nur die Steuer zum Ende errechnet, für den Chart aber für jedes Jahr die Steuer berechnet werden muss, damit am Ende
    des Charts kein riesen Knick kommt.
    */
    calcFondssteuern(result) {
      let teilfreistellung = 0.3
      let abgeltungssteuersatz = this.abgeltungssteuersatz
      let freibetrag = this.freibetrag
      let eingezahlt = this.state.app.vermoegen.sparphaseJahre * 12 * this.state.app.vermoegen.sparbetragMtl
      let endwert = result.at(-1)[1]
      let gewinn = endwert - eingezahlt - freibetrag
      if (gewinn < 0) return 0
      let gewinnNachTeilfreistellung = gewinn - (gewinn * teilfreistellung)
      let steuern = gewinnNachTeilfreistellung * abgeltungssteuersatz
      return steuern
    },
    calcGiroChartData() {
      //console.log("calc giro")
      let result = []
      let potentialStartYear = this.dataLastYear - this.state.app.vermoegen.sparphaseJahre
      let firstYear = giro[0].year
      let yearCounter = 0
      if (potentialStartYear < firstYear) {
        let amountMinus = Math.abs(firstYear - potentialStartYear)
        for (let z = 0; z < amountMinus; z++) {
          yearCounter++
        }
      } else {
        yearCounter = 1
      }
      result.push([yearCounter-1,0])
      let lastYear = giro.at(-1).year
      let startYear = lastYear - this.state.app.vermoegen.sparphaseJahre - 1
      let startIndex = giro.findIndex((o) => o.year == startYear)
      let savedAmount = 0
      if (startIndex === -1) {
        startYear = firstYear
        startIndex = 0
      }
      for (let c = startIndex; c < startIndex+this.state.app.vermoegen.sparphaseJahre; c++) {
      //for (let c = startIndex; c < giro.length; c++) {
        if (!giro[c]) break;
        let currentYear = giro[c]
        let currentInterestRate = currentYear.value
        let yearlyInterest = 0
        for (let c2 = 0; c2 < 12; c2++) {
          savedAmount = savedAmount + this.state.app.vermoegen.sparbetragMtl
          let interest = (savedAmount * (currentInterestRate / 100) / 12)
          yearlyInterest = yearlyInterest + interest
          savedAmount = savedAmount + interest
          /*
          let interestAfterTaxes = this.calcSimpleInterestAfterTaxes(interest)
          savedAmount = savedAmount + interestAfterTaxes
          */
        }
        let interestAfterTaxes = this.calcSimpleInterestAfterTaxes(yearlyInterest)
        let payedTaxes = yearlyInterest - interestAfterTaxes
        savedAmount = savedAmount - payedTaxes
        result.push([yearCounter, savedAmount])
        yearCounter++
      }

      this.state.app.vermoegen.pdfValues["Girokonto"] = result.at(-1)[1];
      console.log("result girokonto", result.at(-1)[1])
      return result
    },
    /*
    kopierte und angepasste Funktion vom MSCI World Investment. Eingebaut sind hier das Aufgeld und der Spread beim Kauf.
    */
    calcGold(sparBetrag) {
      //aufgeld
      let aufgeld = 0.03
      //let buySpread = 0.015
      //spread beim Kauf
      let buySpread = 0.04
      let valueArray = []
      let anzahlMonate = this.state.app.vermoegen.sparphaseJahre * 12
      //ermittelt den Startwert im Goldindex
      let startIndex = gold.length-1 - anzahlMonate
      if (!sparBetrag) sparBetrag = this.state.app.vermoegen.sparbetragMtl
      if (startIndex < 0) {
        let amountMinus = Math.abs(startIndex)
        for (let z = 0; z < amountMinus; z++) {
          valueArray.push(null)
        }
        startIndex = 0
      }
      let gesamtBetrag = 0
      // Startwert des Index. Gilt als Referenzwert für spätere Käufe.
      let goldStartValue = gold[startIndex]["Price"]
      let boughtShares = 0
      for (let i = startIndex; i < gold.length; i++) {
        let currentGold = gold[i]
        gesamtBetrag = gesamtBetrag + sparBetrag
        //errechnet die gekauften Anteile

        
        let kaufPreis = currentGold["Price"] * (1 + aufgeld)
        let sellPrice = currentGold["Price"] * (1 - buySpread)
        let boughtShare = sparBetrag / kaufPreis
        boughtShare = boughtShare * (kaufPreis / sellPrice)
        
        
        //let boughtShare = goldStartValue / (currentGold["Price"] + (currentGold["Price"] * buySpread)) * (sparBetrag - (sparBetrag * aufgeld))


        //addiere vergangene Anteile mit den dazugekauften
        boughtShares = boughtShares + boughtShare
        
        //errechne den Wert der aktuell gehaltenen Anteile
        //let currentShareValue = boughtShares * currentGold["Price"] / goldStartValue
        let currentShareValue = boughtShares * currentGold["Price"]

        valueArray.push(currentShareValue)
      }
      return valueArray
    },
    //Generiert die Chartdaten für das Investment in Gold
    calcGoldChartData() {
      //errechnet die Wertentwicklung von Gold
      let goldIndexData = this.calcGold()
      let result = []
      let i = 0
      for (let c = 0; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        let item = goldIndexData[i]
        result.push([c, item])
        i=i+12
      }
      //reduziert die Wertentwicklung um den Sellspread
      result = this.reduceGoldChartDataBySellSpread(result)

      this.state.app.vermoegen.pdfValues["Gold"] = result.at(-1)[1];

      console.log("gold result", result.at(-1)[1])
      return result
    },
    reduceGoldChartDataBySellSpread(result) {
      //let sellSpread = 0.04
      let sellSpread = 0.015
      for (let item of result) {
        item[1] = item[1] - (item[1] * sellSpread)
      }
      return result
    },
    //Generiert die Chartdaten für die kreditfinanzierte Immobile
    calcImmobilienChartData() {
      let immoIndexData = this.calcMsciWorld('immo')
      let result = []
      let i = 0
      for (let c = 0; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        let item = immoIndexData[i]
        result.push([c, item])
        i=i+12
      }
      result = this.reduceImmobilienChartDataByTaxes(result, true)
      console.log("Immobilie result", result.at(-1)[1])
      return result
    },
    berechneMonatlichenZinssatz(jahreszins) {
      const monatlicherZinssatz = Math.pow(1 + jahreszins, 1 / 12) - 1
      return monatlicherZinssatz
    },
    //Hier werden die Chartdaten für die kreditfinanzierte Immobilie generiert
    calcImmobilienChartDataByIndexAndCredit() {
      let zins = this.immobilienkredit.zins / 100
      let tilgung = this.immobilienkredit.tilgung / 100
      //berechne die monatliche kombinierte Tilgung
      let monatlicheKombinierteTilgung = this.berechneMonatlichenZinssatz(zins) + this.berechneMonatlichenZinssatz(tilgung)
      //hier wird der mögliche Kredit berechnet
      let gesamtKreditBetrag = this.calcPossibleCredit(this.state.app.vermoegen.sparbetragMtl, this.state.app.vermoegen.sparphaseJahre * 12, monatlicheKombinierteTilgung)
      //console.log("gesamtKreditBetrag", gesamtKreditBetrag)
      let startIndex = immo3.length-1 - this.state.app.vermoegen.sparphaseJahre 
      let startValue = immo3[startIndex].Price
      let result = []
      //Berechne für jedes Jahr den Wert der Immobilie und ziehe den noch zu zahlenden Kredit ab um den Gewinn zu ermitteln
      for (let c = 0; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        let currentValue = immo3[startIndex+c].Price
        let faktor = currentValue / startValue
        let newImmoValue = faktor * gesamtKreditBetrag
        let kreditUebrig = gesamtKreditBetrag - this.calcPossibleCredit(this.state.app.vermoegen.sparbetragMtl, c * 12, monatlicheKombinierteTilgung)
        let v = newImmoValue - kreditUebrig
        result.push([c, v])
      }
      result = this.reduceImmobilienChartDataByTaxes(result, true)
      this.state.app.vermoegen.pdfValues["Kreditfinanzierte Immobilie"] = result.at(-1)[1]
      return result
    },
    calcPossibleCredit(sparrate, laufzeitMonate, tilgungssatz) {
      let O10 = 1/(1+tilgungssatz)
      return sparrate * (1 - Math.pow(O10, laufzeitMonate)) / tilgungssatz;
    },
    calcGeldUntermKopfkissenData() {
      let result = [[0, '0']]
      let gesamtBetrag = 0
      for (let c = 1; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        gesamtBetrag = c * 12 * this.state.app.vermoegen.sparbetragMtl
        result.push([c, gesamtBetrag])
      }
      this.state.app.vermoegen.pdfValues["Geld unter dem Kopfkissen"] = result.at(-1)[1];
      this.state.app.vermoegen.pdfValues["Girokonto"] = result.at(-1)[1]; // check again if girokonto calculation is changed

      return result
    },
    //FESTGELD
    //hier werden Ertrag und Versteuerung einer Festgeldinstanz berechnet
    calcFestgeld(betrag, startYear, dauerInJahren) {
      let currentYear = startYear
      for (let c = 1; c <= dauerInJahren; c++) {
        //hole aktuellen Zeile aus der Festgeld-Zins-Tabelle
        let index = festgeld.findIndex((o) => o.year == currentYear)
        let zinssatz = festgeld[index].value
        let interest = betrag * (zinssatz / 100)
        //ziehe Steuern vom Zinsertrag ab
        let interestAfterTaxes = this.calcSimpleInterestAfterTaxes(interest)
        //console.log("interestAfterTaxes", interest, interestAfterTaxes)
        betrag = betrag + interestAfterTaxes
        currentYear++
      }
      return betrag
    },
    calcTagesgeld(betrag, startYear, monatlicherBetrag, dauerInJahren) {
      //find Index of list with interest rates
      let startIndex = giro.findIndex((o) => o.year == startYear)
      for (let c = startIndex; c < startIndex+dauerInJahren; c++) {
        if (!giro[c]) break;
        let currentYear = giro[c]
        let currentInterestRate = currentYear.value
        for (let c2 = 0; c2 < 12; c2++) {
          betrag = betrag + monatlicherBetrag
          let interest = (betrag * (currentInterestRate / 100) / 12)
          betrag = betrag + interest
        }
      }
      return betrag
    },
    /*
    generiert die Chartdaten für das Festgeld
    Der Einfachheit halber wird das Festgeld immer für ein Jahr angelegt
    In dem laufendem Jahr werden die monatlichen Sparbeträge angespart und am Ende des Jahres
    mit dem Ertrag aus dem Festgeld addiert und erneut angelegt
    */
    calcFestgeldData() {
      let result = []
      let gesamtBetrag = 0
      let lastYear = festgeld.at(-1).year
      let startYear = lastYear - this.state.app.vermoegen.sparphaseJahre - 1
      let currentYear = startYear
      let angespartesTagesgeld = 0
      for (let c = 0; c <= this.state.app.vermoegen.sparphaseJahre; c++) {
        //wenn angespartes Geld vorhanden ist (im Monat 0 ist noch keines vorhanden)
        if (angespartesTagesgeld) {
          //berechne Festgeld
          let festgeld = this.calcFestgeld(gesamtBetrag+angespartesTagesgeld, currentYear, 1)
          gesamtBetrag = festgeld
          //setze angespartes Geld auf 0 und spare neu
          angespartesTagesgeld = 0
        }
        //hier wird ein Jahr lang das gesparte Geld auf einem Tagesgeldkonto gespart
        angespartesTagesgeld = this.calcTagesgeld(0, currentYear, this.state.app.vermoegen.sparbetragMtl, 1)
        result.push([c, gesamtBetrag])
        currentYear++
      }

      this.state.app.vermoegen.pdfValues["Festgeld"] = result.at(-1)[1];

      return result
    },
    //Simple Funktion zur Versteuerung eines Betrags mit der Abgeltungssteuer
    calcSimpleInterestAfterTaxes(interest) {
      let abgeltungssteuer = this.abgeltungssteuersatz
      let freibetrag = this.freibetrag
      if (interest <= freibetrag) return interest
      let zuVersteuern = interest - freibetrag
      let steuern = zuVersteuern * abgeltungssteuer
      interest = interest - steuern
      return interest
    }
  },
  beforeDestroy() {
    if (this.somethingHasChanged) {
      if (this.$store.compSparformenRanking.every(obj => obj.active === false && !obj.autoActive)) {
        this.state.app.pdfCharts.vermoegen = document.querySelectorAll('#pdfChart canvas')[0].toDataURL()
      }else {
        this.state.app.pdfCharts.vermoegen = document.querySelectorAll('#echart canvas')[0].toDataURL()
      }
    }
  }
}
</script>

<style lang="stylus">
</style>

<style lang="stylus" scoped>
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1F2532;
}
.chart-wrapper {
  width: 630px;
  height: 100%;
  // position: absolute;
  // right: 0;
}
.chart {
  height: 100%;
}
.chart-legend-container {

}
.chart-legend {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.7px;
  color: #737B7D;
}
.pdf-chart{
  width: 770px;
  height: 620px;
}

::v-deep .pdf-chart canvas{
  width: 770px!important;
  height: 620px!important;
}
</style>
