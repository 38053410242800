<template>
  <div id="app">
    <transition name="fade">
      <div v-if="!isLandscape && !isPhone" class="rotate-hint flex justify-center items-center absolute inset-0 bg-white bg-opacity-95 z-[999]">
        <p class="text-3xl font-bold text-center rotate-90">Bitte zur Seite drehen &nbsp; : )</p>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLandscape: false,
      isPhone: false
    }
  },
  created() {
    window.addEventListener('resize', this.handleOrientationChange)
    // window.addEventListener('orientationchange', this.handleOrientationChange)
    this.handleOrientationChange()
  },
  mounted() {
    this.setTracking()
  },  
  destroyed() {
    window.removeEventListener('resize', this.handleOrientationChange)
    // window.removeEventListener('orientationchange', this.handleOrientationChange)
  },
  methods: {
    handleOrientationChange() {
      const isTablet = /ipad|tablet/i.test(navigator.userAgent)
      if (isTablet && window.matchMedia("(orientation: landscape)").matches) {
        this.isLandscape = true
      } else {
        this.isLandscape = false
      }
      if (!isTablet && window.matchMedia("(orientation: landscape)").matches) {
        this.isLandscape = true
      }

      if (this.$store.isMobile()) {
        this.isPhone = true;
      }else {
        this.isPhone = false;
      }

      // console.log(this.isLandscape);
      // console.log(this.isPhone);
    },
    //when routing is triggered, track the new page visit
    setTracking() {
      this.$router.afterEach((to, from) => {
        let url = window.location.origin.replace('www.', '') + window.location.pathname + to.path;
        this.$store.trackPageView(url)
      })
    }
  },
}
</script>

<style lang="stylus">
body {
  font-family: 'Open Sans', sans-serif
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../public/static/fonts/open-sans-v34-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/static/fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/static/fonts/open-sans-v34-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/static/fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../public/static/fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/static/fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/static/fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/static/fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../public/static/fonts/open-sans-v34-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/static/fonts/open-sans-v34-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/static/fonts/open-sans-v34-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/static/fonts/open-sans-v34-latin-500.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../public/static/fonts/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/static/fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/static/fonts/open-sans-v34-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/static/fonts/open-sans-v34-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../public/static/fonts/open-sans-v34-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/static/fonts/open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/static/fonts/open-sans-v34-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/static/fonts/open-sans-v34-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../public/static/fonts/open-sans-v34-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/static/fonts/open-sans-v34-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/static/fonts/open-sans-v34-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../public/static/fonts/open-sans-v34-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/static/fonts/open-sans-v34-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

.fade-enter-active {
  transition: all .4s;
}
.fade-leave-active {
  transition: all .4s;
}
.fade-enter {
  opacity:0
}
.fade-leave-to {
  opacity:0
}
</style>
