import Vue from 'vue'

const state = new Vue({
  data() {
    return {
      state: {
        isDevMode: window.location.hostname === 'localhost',
        loadingScreenActiveFromSplashscreen: false,
        appCopy: {},
        showTransition: true,
        changedLifeExpectancy: 22,
        app: {
          ziele: {
            erspartes: 0,
            jobSelbststaendig: 0,
            mobilitaet: 0,
            kinderSupport: 0,
            wohnEigentum: 0,
            geldImAlter: 0,
          },
          vermoegen: {
            fondsWechselIntervallInMonate: 180,
            sparbetragMtl: 0,
            renditeziel: 5,
            sparphaseJahre: 20,
            durchschnittInflationsrate: 0,
            model: {
              inflationsschutz: false,
              flexibel: false,
              ohneAbgeltungssteuer: false,
              lebenslangeRente: false
            },
            showAllSparformen: false,
            pdfValues: {},
            sparformen: [
              {
                id: 1,
                title: "Fondsrente ohne Garantie",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#FABE50',
                isPension: true,
                pointValues: {
                  inflationsschutz: 90,
                  flexibel: 50,
                  ohneAbgeltungssteuer: 100,
                  lebenslangeRente: 100
                }
              },
              {
                id: 2,
                title: "Fondsrente mit Garantie",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#FF7A00',
                isPension: true,
                pointValues: {
                  inflationsschutz: 50,
                  flexibel: 30,
                  ohneAbgeltungssteuer: 95,
                  lebenslangeRente: 95
                }
              },
              {
                id: 3,
                title: "Fondssparplan",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#CF0BA4',
                pointValues: {
                  inflationsschutz: 95,
                  flexibel: 90,
                  ohneAbgeltungssteuer: 0,
                  lebenslangeRente: 0
                }
              },
              {
                id: 4,
                title: "Kreditfinanzierte Immobilie",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#00C2FF',
                pointValues: {
                  inflationsschutz: 100,
                  flexibel: 15,
                  ohneAbgeltungssteuer: 0,
                  lebenslangeRente: 0
                }
              },
              {
                id: 5,
                title: "Festgeld",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#7738A9',
                pointValues: {
                  inflationsschutz: 50, //25
                  flexibel: 25,
                  ohneAbgeltungssteuer: 0,
                  lebenslangeRente: 0
                }
              },
              {
                id: 6,
                title: "Girokonto",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#187900',
                pointValues: {
                  inflationsschutz: 0,
                  flexibel: 95,
                  ohneAbgeltungssteuer: 50,
                  lebenslangeRente: 0
                }
              },
              {
                id: 7,
                title: "Geld unter dem Kopfkissen",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#86C600',
                pointValues: {
                  inflationsschutz: 0,
                  flexibel: 100,
                  ohneAbgeltungssteuer: 50,
                  lebenslangeRente: 0
                }
              },
              {
                id: 8,
                title: "Gold",
                showInfo: false,
                points: 0,
                active: false,
                durchschnittlicheRendite: false,
                autoActive: false,
                bgColor: '#f0890c',
                pointValues: {
                  /*
                  inflationsschutz: 100,
                  flexibel: 60,
                  ohneAbgeltungssteuer: 50,
                  lebenslangeRente: 0
                  */
                  inflationsschutz: 0,
                  flexibel: 0,
                  ohneAbgeltungssteuer: 0,
                  lebenslangeRente: 0
                }
              }
            ]
          },
          einkommensschutz: {
            nettoMtl: 2450,
            gehaltSteigerung: 0,
            alter: 24,

            geschlecht: null,
            berufsGruppe: null,
            koerperlicheArbeit: null,
            buRsikResult: '?',
            simulationResult: null,
            
            model: {
              zahltBeiVerlustGF: false,
              zahltBeiBU: false,
              zahltBeiEU: false,
              zahltBeiKrankheit: false,
            },

            "simulationBerufsunfähigkeitsversicherung": 500,
            "simulationErwerbsunfähigkeitsversicherung": 0,
            "simulationGrundfähigkeitsversicherung": 500,
            "simulationDread Disease Versicherung": 150000,
            "simulationKrankentagegeldversicherung": 50,

            versicherungsFormen: [
              {
                id: 1,
                title: "Berufsunfähigkeitsversicherung",
                sliderTitle: "Angenommene BU-Rente (BU) simulieren von",
                popperText: 'Du solltest mindestens 50&nbsp;%, bestenfalls 90&nbsp;% deines Netto&shy;einkommens versichern. Bei Einkommen bis 1.500 Euro sogar 100&nbsp;%. Achte darauf, dass du bei Eintritt einer Berufs&shy;unfähigkeit mindestens 1.000 € pro Monat an Rente ausgezahlt bekommst. Andernfalls riskierst du, deine Einnahmen bei Berufs&shy;unfähigkeit aufstocken zu müssen, zum Beispiel durch Bürger&shy;geld.<br><br>Einige Versicherer bieten einen Schutz von bis zu 65&nbsp;% des Bruttoeinkommens an. Dies entspricht einer Abdeckung von bis zu 90&nbsp;% des Netto&shy;einkommens. Die Höhe des Anteils am Einkommen kann je nach Versicherungsgesellschaft variieren.<br><br>Wenn die Gehalts&shy;steigerung aktiviert ist, wird angenommen, dass auch die BU-Rente steigt. Diese Steigerung (Dynamik) ist auf maximal 3&nbsp;% begrenzt und wird im Graphen abgebildet.<br><br>Du kannst die Berufs&shy;unfähigkeits&shy;versicherung auch mit anderen Versicherungen kombinieren, zum Beispiel mit einer Krankentage&shy;geld&shy;versicherung. So bist du bei Einkommensverlust optimal abgesichert.',
                showInfo: false,
                points: 0,
                active: false,
                bgColor: '#FABE50',
                pointValues: {
                  zahltBeiBU: 100,
                  zahltBeiEU: 95,
                  zahltBeiKrankheit: 75,
                  zahltBeiVerlustGF: 75
                }
              },
              {
                id: 2,
                title: "Erwerbsunfähigkeitsversicherung",
                sliderTitle: "Monatliche Rente (EU) simulieren von",
                popperText: 'Bist du angestellt und zahlst in die Deutsche Renten&shy;versicherung ein, hast du bei Erwerbs&shy;unfähigkeit einen Anspruch auf eine volle oder teilweise Erwerbs&shy;minderungs&shy;rente. Die durchschnittliche volle Erwerbs&shy;minderungs&shy;rente lag 2021 bei 968€. Die Lücke zum letzten Nettoverdienst kannst du mit einer privaten Erwerbs&shy;unfähigkeits&shy;versicherung schließen.<br><br>Einige Versicherer bieten einen Schutz von bis zu 65&nbsp;% des Bruttoeinkommens an. Dies entspricht einer Abdeckung von bis zu 90&nbsp;% des Netto&shy;einkommens. Die Höhe des Anteils am Einkommen kann je nach Versicherungsgesellschaft variieren.<br><br>Du solltest die Höhe der monatlichen Rente so vereinbaren, dass du im Falle einer vollen Erwerbs&shy;unfähigkeit nicht noch zusätzlich aufstocken musst, zum Beispiel durch Bürger&shy;geld. Grundsätzlich ist eine monatliche Rentenzahlung von 1.000€ bis 2.000€ üblich. Für Hausfrauen und Hausmänner ist die monatliche Rente auf maximal 1.000€ begrenzt. Schülerinnen und Schüler erhalten höchstens 1.500€ pro Monat.<br><br>Wenn die Gehalts&shy;steigerung aktiviert ist, wird angenommen, dass auch die BU-Rente steigt. Diese Steigerung (Dynamik) ist auf maximal 3&nbsp;% begrenzt und wird im Graphen abgebildet.',
                popperSource: 'https://www.finanztip.de/berufsunfaehigkeitsversicherung/erwerbsunfaehigkeitsversicherung/',
                showInfo: false,
                points: 0,
                active: false,
                bgColor: '#FF7A00',
                pointValues: {
                  zahltBeiBU: 0,
                  zahltBeiEU: 100,
                  zahltBeiKrankheit: 50,
                  zahltBeiVerlustGF: 40
                }
              },
              {
                id: 3,
                title: "Grundfähigkeitsversicherung",
                sliderTitle: "Grundfähigkeitsversicherung simulieren",
                popperText: 'Wird eine Grund&shy;fähigkeits&shy;versicherung anstelle einer Berufs&shy;unfähigkeits&shy;versicherung abgeschlossen, sollte die Versicherungs&shy;summe so gewählt werden, dass sie ca. 75% des letzten Netto-Einkommens ersetzt.<br><br>Wenn die Gehalts&shy;steigerung aktiviert ist, wird angenommen, dass auch die Rente der Grund&shy;fähigkeits&shy;versicherung dynamisch steigt. Diese Steigerung (Dynamik) ist auf maximal 3&nbsp;% begrenzt und wird im Graphen abgebildet.',
                popperSource: 'https://www.finanztip.de/berufsunfaehigkeitsversicherung/grundfaehigkeitsversicherung/',
                showInfo: false,
                points: 0,
                active: false,
                bgColor: '#00C2FF',
                pointValues: {
                  zahltBeiBU: 50,
                  zahltBeiEU: 50,
                  zahltBeiKrankheit: 50,
                  zahltBeiVerlustGF: 100
                }
              },
              {
                id: 4,
                title: "Dread Disease Versicherung",
                sliderTitle: "Dread Disease simulieren",
                popperText: "Wird eine Dread Disease Versicherung anstelle einer Berufs&shy;unfähigkeits&shy;versicherung abgeschlossen, sollte die Versicherungs&shy;summe so gewählt werden, dass eine lange Phase der Arbeits&shy;unfähigkeit überbrückt werden kann. Bei den meisten Versicherern sind Versicherungs&shy;summen zwischen 50.000€ und 250.000€ Standard.",
                popperSource: 'https://www.transparent-beraten.de/dread-disease-versicherung/',
                showInfo: false,
                points: 0,
                active: false,
                bgColor: '#CF0BA4',
                pointValues: {
                  zahltBeiBU: 25,
                  zahltBeiEU: 25,
                  zahltBeiKrankheit: 100,
                  zahltBeiVerlustGF: 50
                }
              },
              {
                id: 5,
                title: "Krankengeld & private Krankentagegeldversicherung",
                sliderTitle: "Krankengeld simulieren: 78 Wochen",
                popperText: "Das Kranken&shy;geld der gesetzlichen Kranken&shy;versicherungen wird bei ein und derselben Erkrankung über einen Zeitraum von höchstens 78 Wochen ausgezahlt. Es beträgt maximal 90&nbsp;% des letzten Nettogehalts.",
                showInfo: false,
                points: 0,
                active: false,
                bgColor: '#7738A9',
                pointValues: {
                  zahltBeiBU: 20,
                  zahltBeiEU: 20,
                  zahltBeiKrankheit: 20,
                  zahltBeiVerlustGF: 20
                }
              },
            ]
          },
          versicherung: {
            phase: false,
            answered: [],
            insuranceCategories: [
              {
                id: 1,
                name: 'Notwendig',
                text: 'Diese Versicherungen solltest du unbedingt besitzen:',
                insurances: []
              },
              {
                id: 2,
                name: "Empfehlenswert",
                text: 'Diese Versicherungen solltest du besitzen:',
                insurances: []
              },
              {
                id: 3,
                name: "Sinnvoll",
                text: 'Diese Versicherungen kannst du besitzen:',
                insurances: []
              },
              {
                id: 4,
                name: "Optional",
                text: 'Diese Versicherungen sind mit Einschränkungen sinnvoll:',
                insurances: []
              },
              {
                id: 5,
                name: "Nicht notwendig",
                text: 'Auf diese Versicherungen kannst du verzichten:',
                insurances: []
              }
            ],
            insurances: [
              {
                id: 1,
                name: "Kfz-Vollkasko"
              },
              {
                id: 2,
                name: "Kfz-Teilkasko"
              },
              {
                id: 3,
                name: "Kfz-Haftpflichtversicherung"
              },
              {
                id: 4,
                name: "Autoschutzbrief"
              },
              {
                id: 5,
                name: "Insassenunfall&shy;versicherung"
              },
              {
                id: 6,
                name: "Tierhalter-Haftpflicht&shy;versicherung"
              },
              {
                id: 7,
                name: "Tierkranken&shy;versicherung"
              },
              {
                id: 8,
                name: "Gewässerschaden-Haftpflicht&shy;versicherung"
              },
              {
                id: 9,
                name: "Wohngebäude&shy;versicherung mit Naturgefahrenschutz"
              },
              {
                id: 10,
                name: "Bauherren-Haftpflicht&shy;versicherung"
              },
              {
                id: 11,
                name: "Hausrat&shy;versicherung"
              },
              {
                id: 12,
                name: "Haus- und Grundbesitzer&shy;haftpflicht"
              },
              {
                id: 13,
                name: "Verkehrs&shy;rechtschutz"
              },
              {
                id: 14,
                name: "Reiserücktritt&shy;sversicherung"
              },
              {
                id: 15,
                name: "Privat-Haftpflicht&shy;versicherung",
                details: "bei Paaren und Familien reicht eine Police",
                modal: {
                  title: "Wissenwertes zur Versicherung",
                  text: ""
                }
              },
              {
                id: 16,
                name: "Rechtsschutz&shy;versicherung",
                details: "bei Paaren reicht eine Police"
              },
              {
                id: 17,
                name: "Handy- und Tablet&shy;versicherung"
              },
              {
                id: 18,
                name: "Reisegepäck&shy;versicherung"
              },
              {
                id: 19,
                name: "Familien&shy;versicherung, Gesetzliche Kranken- und Pflege&shy;versicherung"
              },
              {
                id: 20,
                name: "Krankentagegeld&shy;versicherung"
              },
              {
                id: 21,
                name: "Private Kranken&shy;versicherung und Pflegepflicht&shy;versicherung"
              },
              {
                id: 22,
                name: "Gesetzliche Kranken- und Pflegepflicht&shy;versicherung"
              },
              {
                id: 23,
                name: "Stationäre Zusatzversicherung"
              },
              {
                id: 24,
                name: "Zahnzusatz&shy;versicherung"
              },
              {
                id: 25,
                name: "Risikolebens&shy;versicherung"
              },
              {
                id: 26,
                name: "Auslandsreise-Kranken&shy;versicherung"
              },
              {
                id: 27,
                name: "Pflegezusatz&shy;versicherung"
              },
              {
                id: 28,
                name: "Pflegetagegeld&shy;versicherung",
                details: "als Alternative zur Einkommensab sicherung"
              },
              {
                id: 29,
                name: "Krankenhaustagegeld-Versicherung"
              },
              {
                id: 30,
                name: "Sterbegeld&shy;versicherung"
              },
              {
                id: 31,
                name: "Kinderinvalidäts&shy;versicherung"
              },
              {
                id: 32,
                name: "Berufsunfähigkeits&shy;versicherung"
              },
              {
                id: 33,
                name: "Erwerbsunfähigkeits-Versicherung"
              },
              {
                id: 34,
                name: "Grundfähigkeits-Versicherung"
              },
              {
                id: 35,
                name: "Funktionsinvalidäts-Versicherung"
              },
              {
                id: 36,
                name: "Dread Disease Versicherung"
              },
              {
                id: 37,
                name: "Unfall&shy;versicherung",
                details: "als Alternative zur Einkommensab sicherung"
              },
              {
                id: 38,
                name: "Senioren-Unfallversicherung"
              },
              {
                id: 39,
                name: "Freiwillige gesetzliche Krankenversicherung"
              },
              {
                id: 40,
                name: "Berufsunfähigkeits&shy;versicherung für Schülerinnen & Schüler"
              },
              {
                id: 41,
                name: "Kinder-Unfall&shy;versicherung"
              },
            ],
            livePhases: [
              {
                id: 1,
                text: "Junge Erwachsene (18 bis 24 Jahre)",
                isLifePhase: true,
                insurances: [
                  {
                    insuranceId: 15,
                    categoryId: 1
                  },
                  {
                    insuranceId: 27,
                    categoryId: 3
                  },
                  {
                    insuranceId: 17,
                    categoryId: 5
                  },
                  {
                    insuranceId: 18,
                    categoryId: 5
                  },
                  {
                    insuranceId: 29,
                    categoryId: 5
                  },
                  {
                    insuranceId: 30,
                    categoryId: 5
                  },
                  {
                    insuranceId: 37,
                    categoryId: 3
                  },
                  {
                    insuranceId: 28,
                    categoryId: 3
                  },
                  {
                    insuranceId: 32,
                    categoryId: 2
                  }
                ]
              },
              {
                id: 2,
                text: "Erwachsene ab 25, ledig",
                isLifePhase: true,
                insurances: [
                  {
                    insuranceId: 15,
                    categoryId: 1
                  },
                  {
                    insuranceId: 27,
                    categoryId: 3
                  },
                  {
                    insuranceId: 28,
                    categoryId: 3
                  },
                  {
                    insuranceId: 17,
                    categoryId: 5
                  },
                  {
                    insuranceId: 18,
                    categoryId: 5
                  },
                  {
                    insuranceId: 29,
                    categoryId: 5
                  },
                  {
                    insuranceId: 30,
                    categoryId: 5
                  },
                  {
                    insuranceId: 37,
                    categoryId: 3
                  },
                  {
                    insuranceId: 32,
                    categoryId: 2
                  }
                ]
              },
              {
                id: 3,
                text: "Erwachsene ab 25, verh. oder in Lebenspartnerschaft",
                isLifePhase: true,
                insurances: [
                  {
                    insuranceId: 15,
                    categoryId: 1
                  },
                  {
                    insuranceId: 25,
                    categoryId: 2
                  },
                  {
                    insuranceId: 27,
                    categoryId: 3
                  },
                  {
                    insuranceId: 17,
                    categoryId: 5
                  },
                  {
                    insuranceId: 18,
                    categoryId: 5
                  },
                  {
                    insuranceId: 29,
                    categoryId: 5
                  },
                  {
                    insuranceId: 30,
                    categoryId: 5
                  },
                  {
                    insuranceId: 37,
                    categoryId: 3
                  },
                  {
                    insuranceId: 28,
                    categoryId: 3
                  },
                  {
                    insuranceId: 32,
                    categoryId: 2
                  }
                ]
              },
              {
                id: 4,
                text: "Familie mit Kindern / Alleinerziehende",
                isLifePhase: true,
                insurances: [
                  {
                    insuranceId: 15,
                    categoryId: 1
                  },
                  {
                    insuranceId: 25,
                    categoryId: 2
                  },
                  {
                    insuranceId: 27,
                    categoryId: 3
                  },
                  {
                    insuranceId: 28,
                    categoryId: 3
                  },
                  {
                    insuranceId: 17,
                    categoryId: 5
                  },
                  {
                    insuranceId: 18,
                    categoryId: 5
                  },
                  {
                    insuranceId: 29,
                    categoryId: 5
                  },
                  {
                    insuranceId: 30,
                    categoryId: 5
                  },
                  {
                    insuranceId: 37,
                    categoryId: 3
                  },
                  {
                    insuranceId: 32,
                    categoryId: 2
                  },
                  {
                    insuranceId: 31,
                    categoryId: 2
                  },
                  {
                    insuranceId: 40,
                    categoryId: 2
                  },
                  {
                    insuranceId: 41,
                    categoryId: 3
                  }
                ]
              },
              {
                id: 5,
                text: "Rentner und Senioren",
                isLifePhase: true,
                insurances: [
                  {
                    insuranceId: 15,
                    categoryId: 1
                  },
                  {
                    insuranceId: 27,
                    categoryId: 3
                  },
                  {
                    insuranceId: 28,
                    categoryId: 3
                  },
                  {
                    insuranceId: 38,
                    categoryId: 3
                  },
                  {
                    insuranceId: 17,
                    categoryId: 5
                  },
                  {
                    insuranceId: 18,
                    categoryId: 5
                  },
                  {
                    insuranceId: 29,
                    categoryId: 5
                  },
                  {
                    insuranceId: 30,
                    categoryId: 5
                  }
                ]
              }
            ],
            questions: [
              {
                id: 1,
                text: "Besitzt du ein Kfz?",
                livePhases: [1, 2, 3, 4, 5],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 3,
                        categoryId: 1
                      },
                      {
                        insuranceId: 4,
                        categoryId: 4
                      },
                      {
                        insuranceId: 5,
                        categoryId: 5
                      }
                    ],
                    questions: [
                      {
                        id: 2,
                        text: "Ist das Kfz neu und/oder hochwertig?",
                        answers: [
                          {
                            id: 1,
                            text: "Ja",
                            insurances: [
                              {
                                insuranceId: 1,
                                categoryId: 3
                              }
                            ],
                          },
                          {
                            id: 2,
                            text: "Nein",
                            insurances: [
                              {
                                insuranceId: 2,
                                categoryId: 3
                              }
                            ],
                          }
                        ]
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein"
                  }
                ]
              },
              {
                id: 3,
                text: "Besitzt du ein Haustier?",
                livePhases: [1, 2, 3, 4, 5],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 7,
                        categoryId: 3
                      }
                    ],
                    questions: [
                      {
                        id: 4,
                        text: "Hast du einen Hund oder ein Pferd?",
                        answers: [
                          {
                            id: 1,
                            text: "Ja",
                            insurances: [
                              {
                                insuranceId: 6,
                                categoryId: 1
                              }
                            ],
                          },
                          {
                            id: 2,
                            text: "Nein"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein"
                  }
                ]
              },
              {
                id: 5,
                text: "Bist du Eigentümer einer Immobilie?",
                livePhases: [1, 2, 3, 4, 5],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 12,
                        categoryId: 2
                      }
                    ],
                    questions: [
                      {
                        id: 9,
                        text: "Besitzt du ein Wohnhaus?",
                        answers: [
                          {
                            id: 1,
                            text: "Ja",
                            insurances: [
                              {
                                insuranceId: 9,
                                categoryId: 2
                              }
                            ],
                            questions: [
                              {
                                id: 90,
                                text: "Besitzt du einen Öltank?",
                                answers: [
                                  {
                                    id: 1,
                                    text: "Ja",
                                    insurances: [
                                      {
                                        insuranceId: 8,
                                        categoryId: 1
                                      }
                                    ],
                                  },
                                  {
                                    id: 2,
                                    text: "Nein"
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            id: 2,
                            text: "Nein",
                            insurances: [
                              {
                                insuranceId: 10,
                                categoryId: 2
                              }
                            ],
                          }
                        ]
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein"
                  }
                ]
              },
              {
                id: 10,
                text: "Führst du einen eigenen Haushalt?",
                livePhases: [1, 2, 3, 4, 5],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 11,
                        categoryId: 3
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein"
                  }
                ]
              },
              {
                id: 100,
                text: "Bist du ein aktiver Verkehrsteilnehmer?",
                livePhases: [1, 2, 3, 4, 5],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 13,
                        categoryId: 3
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein"
                  }
                ]
              },
              {
                id: 11,
                text: "Planst du einen teuren, langfristigen Urlaub?",
                livePhases: [4, 5],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 14,
                        categoryId: 4
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein"
                  }
                ]
              },
              {
                id: 13,
                text: "Bist du selbstständig oder verbeamtet?",
                livePhases: [1, 2, 3, 4],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 21,
                        categoryId: 1
                      },
                      {
                        insuranceId: 20,
                        categoryId: 1
                      },
                      {
                        insuranceId: 26,
                        categoryId: 2
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein",
                    insurances: [
                      {
                        insuranceId: 22,
                        categoryId: 1
                      },
                      {
                        insuranceId: 23,
                        categoryId: 3
                      },
                      {
                        insuranceId: 24,
                        categoryId: 3
                      },
                      {
                        insuranceId: 26,
                        categoryId: 2
                      }
                    ]
                  }
                ]
              },
              {
                id: 130,
                text: "Bist du Schüler, Azubi oder Praktikant?",
                livePhases: [1],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [],
                    questions: [
                      {
                        id: 132,
                        text: "Liegt dein Einkommen unter 470€/ Monat?",
                        answers: [
                          {
                            id: 1,
                            text: "Ja",
                            insurances: [
                              {
                                insuranceId: 19,
                                categoryId: 1
                              }
                            ]
                          },
                          {
                            id: 2,
                            text: "Nein",
                            insurances: [
                              {
                                insuranceId: 39,
                                categoryId: 1
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein",
                    insurances: [],
                    questions: [
                      {
                        id: 131,
                        text: "Bist du pflichtversichert in Ausbildung / Freiwilligendienst?",
                        answers: [
                          {
                            id: 1,
                            text: "Ja",
                            insurances: [
                              {
                                insuranceId: 22,
                                categoryId: 1
                              }
                            ]
                          },
                          {
                            id: 2,
                            text: "Nein",
                            insurances: []
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                id: 13,
                text: "Arbeitest du in einem risikoreichen Beruf?",
                livePhases: [1, 2, 3, 4],
                answers: [
                  {
                    id: 1,
                    text: "Ja",
                    insurances: [
                      {
                        insuranceId: 33,
                        categoryId: 2
                      },
                      {
                        insuranceId: 34,
                        categoryId: 2
                      },
                      {
                        insuranceId: 35,
                        categoryId: 2
                      },
                      {
                        insuranceId: 36,
                        categoryId: 2
                      }
                    ]
                  },
                  {
                    id: 2,
                    text: "Nein",
                    insurances: []
                  }
                ]
              },
            ]
          },
          pdfCharts: {
            ziele: "",
            vermoegen: "",
            einkommen: "",
            versicherungen: "",
          }
        }
      }
    }
  },
  mounted() {
    console.log("mounted store")
  },
  computed: {
    compSparformenRanking() {
      let sparformen = this.state.app.vermoegen.sparformen.map((item) => {
        if (!this.compFondsrentenVisible) {
          if (item.id == 1 || item.id == 2) return null
        }
        item.points = 0
        item.hidden = false
        for (let n in item.pointValues) {
          if (this.state.app.vermoegen.model[n]) {
            item.points = item.points + item.pointValues[n]
          }
        }
        /*
        //if checkbox "pension" is activated, only pensions should be shown
        if (this.state.app.vermoegen.model.lebenslangeRente) {
          console.log("lebenslange rente aktiv")
          if (!item.isPension) return null
        }
        */

        if (!item.points) return null
        //if (!item.points) item.hidden = true
        return item
      })
      sparformen = sparformen.filter((o) => o !== null)
      if (!this.compSparformenActive) {
        sparformen.sort(function (a, b) { return 0.5 - Math.random() })
      }
      else sparformen.sort(function(a, b) { return b.points - a.points })

      for (let i = 5; i < sparformen.length; i++) {
        //sparformen[i].hidden = true
      }
      console.log("compSparformenRanking", sparformen)
      //!this.state.app.vermoegen.showAllSparformen && (sparformen = sparformen.slice(0,5))
      //if (sparformen[0]) sparformen[0].active = true
      return sparformen
    },
    compFondsrentenVisible() {
      if (this.state.app.vermoegen.sparphaseJahre < 12) return false
      if (!this.state.app.ziele.wohnEigentum && !this.state.app.ziele.geldImAlter && !this.state.app.ziele.kinderSupport) return false
      return true
    },
    compSparformenActive() {
      for (let n in this.state.app.vermoegen.model) {
        if (this.state.app.vermoegen.model[n]) return true
      }
      return false
    },
    compVersicherungsRanking() {
      let versicherungsFormen = this.state.app.einkommensschutz.versicherungsFormen.map((item) => {
        if (item.clicked === undefined) item.clicked = false;
        item.points = 0
        for (let n in item.pointValues) {
          if (this.state.app.einkommensschutz.model[n]) {
            item.points = item.points + item.pointValues[n]
          }
        }
        item.hidden = true;
        if (item.points > 0) {
          item.hidden = false;
        }
        return item
      })
      console.log("compVersicherungsFormenRanking", versicherungsFormen)
      versicherungsFormen.sort(function(a, b) { return b.points - a.points })
      
      let tmpObj = {...this.state.app.einkommensschutz.model}
      let activeCheckbox = Object.keys(tmpObj).find(key => tmpObj[key] === true)
      
      if (activeCheckbox === undefined) {
        versicherungsFormen = versicherungsFormen.slice().reverse()
        versicherungsFormen.forEach(elem => {
          elem.clicked = false;          
        });
      }

      if (activeCheckbox !== undefined && !versicherungsFormen.some(o => o.clicked)) {
        versicherungsFormen.forEach(elem => {
          elem.active = false;          
        });
        versicherungsFormen[0].active = true;
      }
      return versicherungsFormen
    },
    compFinanzbedarf() {
      let toReturn = 0;
      for (const ziel in this.state.app.ziele) {
        if (ziel == 'geldImAlter') {
          toReturn += this.state.app.ziele[ziel] * 12 * this.state.changedLifeExpectancy
        } else {
          toReturn += this.state.app.ziele[ziel]
        }
      }
      return toReturn
    },
    compFinanzbedarfLangfristig() {
      let filterArray = ["kinderSupport", "wohnEigentum", "geldImAlter"];
      let toReturn = 0;
      for (const ziel in this.state.app.ziele) {
        if (filterArray.includes(ziel)) {
          if (ziel == 'geldImAlter') {
            toReturn += this.state.app.ziele[ziel] * 12 * this.state.changedLifeExpectancy
          } else {
            toReturn += this.state.app.ziele[ziel]
          }
        }
      }
      return toReturn
    },
    compFinanzbedarfKurzfristig() {
      let filterArray = ["erspartes", "jobSelbststaendig", "mobilitaet"];
      let toReturn = 0;
      for (const ziel in this.state.app.ziele) {
        if (filterArray.includes(ziel)) {
          toReturn += this.state.app.ziele[ziel]
        }
      }
      return toReturn
    },
    compFinanzbedarfFormatted() {
      return Number(this.compFinanzbedarf).toLocaleString() + ' €'
    },
    compFinanzbedarfLangfristigFormatted() {
      return Number(this.compFinanzbedarfLangfristig).toLocaleString() + ' €'
    },
    compFinanzbedarfKurzfristigFormatted() {
      return Number(this.compFinanzbedarfKurzfristig).toLocaleString() + ' €'
    },
    compFinanzbedarfInflation() {
      let faktor = Math.pow(
        1 + this.state.app.vermoegen.durchschnittInflationsrate / 100,
        -1 * this.state.app.vermoegen.sparphaseJahre
      )
      let result = this.compFinanzbedarf / faktor
      return Math.round(result)
    },
    compFinanzbedarfInflationFormatted() {
      return Number(this.compFinanzbedarfInflation).toLocaleString() + ' €'
    },
    compSparPotential() {
      let princ = 0 // start deposit
      let add = this.state.app.vermoegen.sparbetragMtl // monthly deposit (need plus it every year)
      let rate = this.state.app.vermoegen.renditeziel / 100 // interest rate divided to create decimal
      let months = (this.state.app.vermoegen.sparphaseJahre * 12) //10 years of monthly contributions
      for (let i = 1; i <= months; i++) {
        princ += add
        princ += princ * (rate / 12)
      }
      //return princ.toFixed(0)
      return Math.round(princ)
    },
    compDifferenzBedarf() {
      return this.compSparPotential - this.compFinanzbedarfInflation
    },
    compDifferenzBedarfFormatted() {
      return Number(this.compDifferenzBedarf).toLocaleString() + ' €'
    }
  },
  methods: {
    copyState() {
      this.state.appCopy = JSON.parse(JSON.stringify(this.state.app))
    },
    resetData() {
      this.state.app = JSON.parse(JSON.stringify(this.state.appCopy))
    },
    sleep(milliseconds) {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    },
    isMobile() {
      let toReturn = false;
      if (window.matchMedia("(max-width: 480px) and (orientation: portrait)").matches
        || window.matchMedia("(max-height: 480px) and (orientation: landscape)").matches) {
          toReturn = true
      }

      return toReturn
    },
    isTablet() {
      let toReturn = false;
      if (window.matchMedia("(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)").matches
        || window.matchMedia("(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)").matches) {
        toReturn = true;
      }
      
      return toReturn
    },
    //tracking page view with matomo
    trackPageView(url) {
      if (window._paq) {
        window._paq.push(['setCustomUrl', url]);
        window._paq.push(['trackPageView']);
      }
    }
  }
})

export const store = state