<template>
  <div>
    <div v-if="$store.isMobile()" class="mobile-view w-screen h-screen p-8 flex flex-col justify-between">
      <a target="_blank" href="https://dia-vorsorge.de"><img src="../../public/static/img/dia_logo_trans.png"></a>
      
      <div class="container w-full">
        <h3>
          Der DIA Projektor funktioniert derzeit leider noch nicht mobil. 
          <br>
          <br>
          Wir freuen uns aber, wenn du uns mit einem Desktop Gerät wieder besuchst. 
        </h3>
        <a href="https://dia-vorsorge.de/dia-tools/">
          <button class="button">
            Zurück
          </button>
        </a>
      </div>
    </div>
    <div v-else class="home w-screen h-screen mx-auto bg-cover px-28 pt-16">
      <div class="flex flex-col h-full">
        <div class="">
          <a target="_blank" href="https://dia-vorsorge.de"><img src="../../public/static/img/dia_logo_trans.png"></a>
        </div>
        <div class="h-full max-w-lg">
          <div class="flex flex-col h-full justify-center">
            <div class="title">
              Der DIA Projektor bringt Licht<br>
              ins Dunkel deiner Finanzen.
              <div class="sub mt-2">
                Versicherungen, Vermögen und Vorsorge für Einsteiger
              </div>
            </div>
            <div class="text mt-2 max-w-md">
              Wie viel kannst du sparen? Welche Sparformen passen zu dir? Wie solltest du dein Einkommen absichern? 
              Der DIA Projektor hilft schnell und einfach, die für dich passenden Antworten zu finden.
            </div>
            <router-link :to="{name: 'versicherung'}">
              <button class="button mt-11">
                Los geht's
              </button>
            </router-link>
            <div class="text mt-3">
              <router-link :to="{name: 'Datenschutz'}" class="underline">Datenschutz</router-link> & <a class="underline" href="https://dia-vorsorge.de/impressum" target="_blank">Impressum</a>
            </div>
          </div>
        </div>
        <p class="text-[10px]	">Made with ♥ by birkenbeul communications GmbH, 2024</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style lang="stylus">

</style>

<style lang="stylus" scoped>
.home {
  background-image: url('../../public/static/img/home_bg.jpg')
  color: white
}
.mobile-view {
  background: linear-gradient(90deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%), url('../../public/static/img/home_bg_mobile.jpg');
  background-size: cover;
  color: white

  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.1px;
    text-shadow: 0px 8px 16px rgba(0, 0, 0, 0.35);
    margin-bottom: 32px;
  }

  .button {
    width: 100%;
  }
}
.title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: -0.7px;
  color: #FFFFFF;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.35);
  .sub {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.1px;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.35);
  }
}
.text {
  font-size:16px
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */
  letter-spacing: 0.3px;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}
.button {
  background: #3C64B1
  padding: 15px
  width:267px
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-align:center
  cursor:pointer
}
</style>
