<template>
  <controll-wrapper title="Wie erreichst du <br>deinen Finanzbedarf?">
    <template v-slot:content>
      <div class="pl-16 pr-[74px]">
        <app-slider v-for="val in sliderValues" :key="val.modelName"
          :modelObject="'vermoegen'"
          :minValue="val.minValue"
          :maxValue="val.maxValue"
          :modelName="val.modelName"
          :aboveText="val.aboveText"
          :icon="val.icon"
          :valueSuffix="val.valueSuffix"
          :valueTitle="val.valueTitle"
          :interval="val.interval"
          :isLazy="true"
          @updateSelected="updateSelected(val.modelName)" 
          @updateData="updateData(val.modelName)" 
        />
        <div class="info-text text-left mt-2">
          <b>Zur Orientierung:</b> Der Index MSCI World hat in diesem Zeitraum eine durchschnittliche Rendite von <span class="font-bold">{{ compAverageRendite }}</span> % erreicht. Deshalb sollte auf keinen Fall mit einer höheren Rendite (vor Kosten und Steuern) gerechnet werden.
        </div>
        <div class="text -ml-1 text-left mt-16 mb-4 flex flex-row gap-x-2">
          <div class="text-[#1e86cb]">Diese Inflationsrate rechnest du mit ein</div>
          <div class="info-icon grow self-center inline-flex cursor-pointer" @click="ui.showInflationModal = true">
            <img src="../../../public/static/img/info-icon-grey.svg">
          </div>
          <modal v-model="ui.showInflationModal">
            <div class="overflow-auto">
              <img class="inline-block max-w-[1200px] max-h-[80vh]" src="../../../public/static/img/infografiken/large/230317_DIA_2387_Projektor_Infografiken_Inflation.jpg">
            </div>
          </modal>
        </div>
        <app-slider
          :modelObject="'vermoegen'"
          :minValue="inflationSlider.minValue"
          :maxValue="inflationSlider.maxValue"
          :modelName="inflationSlider.modelName"
          :aboveText="inflationSlider.aboveText"
          :icon="inflationSlider.icon"
          :valueSuffix="inflationSlider.valueSuffix"
          :valueTitle="inflationSlider.valueTitle"
          :interval="inflationSlider.interval"
          :isLazy="true"
          @updateSelected="updateSelected(inflationSlider.modelName)" 
          @updateData="updateData(inflationSlider.modelName)" 
        />
        <div class="info-text text-left mt-2">
          <b>Hinweis:</b> Unter Berücksichtigung der Inflation beträgt dein Finanzbedarf in {{ state.app.vermoegen.sparphaseJahre }} Jahren {{ $store.compFinanzbedarfInflation.toLocaleString() + '€' }}
          <!--div>Sparpotential: {{$store.compSparPotential}}</div-->
          <!--div class="mt-4 text-center flex flex-row items-center">
          <input type="checkbox" name="inflation" v-model="compShowInflation" />
          <label for="inflation">Test</label>
        </div--> 
        </div>
      </div>
    </template>
  </controll-wrapper>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue"
import AppSlider from '@/components/AppSlider.vue'
import Modal from '@/components/modal.vue'

export default {
  name: 'Vermoegen_1',
  components: {
    ControllWrapper, AppSlider, Modal
  },
  data() { return {
    state: this.$store.state,
    selectedYears: 0,
    ui: {
      showInflationModal: false
    },
    sliderValues: [
      {
        valueSuffix: "euro",
        valueTitle: "Deine Einzahlungen pro Monat",
        minValue: 0,
        maxValue: 1500,
        interval: 10,
        modelName: "sparbetragMtl",
      },
      {
        valueSuffix: "prozent",
        valueTitle: "Mit dieser jährlichen Rendite rechnest du",
        minValue: 0,
        maxValue: 15,
        interval: 0.1,
        modelName: "renditeziel",
      },
      {
        valueSuffix: "jahr",
        valueTitle: "So lange willst du sparen",
        minValue: 0,
        maxValue: 50,
        interval: 1,
        modelName: "sparphaseJahre",
      }
    ],
    inflationSlider: {
      valueSuffix: "prozent",
      valueTitle: "",
      minValue: 0,
      maxValue: 10,
      interval: 0.1,
      modelName: "durchschnittInflationsrate",
    }
  }},
  props: ['showInflation', 'msciworld'],
  watch: {
    
  },
  mounted() {
    this.$emit('updateData')
  },
  computed: {
    compInflationText() {
      let str = "<b>Hinweis:</b> Unter Berücksichtigung der Inflation beträgt dein Finanzbedarf in " + this.state.app.vermoegen.sparphaseJahre + " Jahren " + $store.compFinanzbedarfInflation.toLocaleString() + "€"
      return str
    },
    compShowInflation: {
      get: function () {
        return this.showInflation
      },
      set: function (newValue) {
        this.$emit('update:showInflation', newValue)
      }
    },
    compAverageRendite() {
      let msciResult = this.calcMsciWorld('msciworld', 100, true, true)
      let rendite = this.calculateAnnualReturn(msciResult[0], msciResult.at(-1), 100, this.state.app.vermoegen.sparphaseJahre)
      return rendite
    },
  },
  methods: {
    updateData(data) {
      this.$emit('updateData')
    },
    updateSelected(modelName) {
      if(modelName === "sparphaseJahre") {
        this.selectedYears = this.state.app.vermoegen.sparphaseJahre
        console.log('selectedYears', this.selectedYears);
      }
    },
    calcMsciWorld(type, sparBetrag = 100, withoutTaxes = false, withoutCosts = false) {
      let valueArray = []
      let anzahlMonate = this.state.app.vermoegen.sparphaseJahre * 12
      //der Startindex des MSCI World wird anhand der angegeben Spardauer festgelegt
      let startIndex = this[type].length - 1 - anzahlMonate
      if (!sparBetrag) sparBetrag = this.state.app.vermoegen.sparbetragMtl
      if (startIndex < 0) {
        let amountMinus = Math.abs(startIndex)
        for (let z = 0; z < amountMinus; z++) {
          valueArray.push(null)
        }
        startIndex = 0
      }
      let gesamtBetrag = 0
      // Startwert des Index. Gilt als Referenzwert für spätere Käufe.
      let msciStartValue = this[type][startIndex]["Price"]
      let boughtShares = 0
      let monthCounter = 1
      //geht den Index ab dem Startwert bis zum aktuellsten Wert durch
      for (let i = startIndex; i < this[type].length; i++) {
        let currentMsci = this[type][i]
        //errechnet den bisher investierten Gesamtbetrag
        gesamtBetrag = gesamtBetrag + sparBetrag
        //errechnet die gekauften Anteile 
        let boughtShare = msciStartValue / currentMsci["Price"] * sparBetrag
        //addiere vergangene Anteile mit den dazugekauften
        boughtShares = boughtShares + boughtShare
        //wenn es sich bei der Berechnung um den MSCI World handelt, dann reduziere die gekauften Shares um den "Reduction in yield" Faktor, es sei denn es soll explizit ohne Kosten gerechnet werden
        //if (type == 'msciworld' && !withoutCosts) boughtShares = this.reduceByReductionInYieldValue(boughtShares, anzahlMonate, false)
        //errechne den Wert der aktuell gehaltenen Anteile
        let currentShareValue = boughtShares * currentMsci["Price"] / msciStartValue
        valueArray.push(currentShareValue)
        monthCounter++
      }
      let currentMsciValue = this[type][this[type].length - 1]["Price"]
      //gibt das Array mit der monatlichen Wertentwicklung zurück
      console.log('valueArray', valueArray);
      return valueArray
    },
    calculateAnnualReturn(startValue, endValue, monthlyDeposit, years) {
        const months = years * 12;
        const cashFlows = new Array(months).fill(-monthlyDeposit);
        cashFlows[0] -= startValue; // adjust the first value by start value
        cashFlows[cashFlows.length - 1] += endValue; // adjust the last value by end value

        let guess = 0.05; // initial guess for IRR
        const tolerance = 0.0001; // change as per your requirement
        let step = 0.05; // step size

        while (step >= tolerance) {
            let result = cashFlows.reduce((acc, val, i) => {
                return acc + (val / Math.pow(1 + guess, i / 12)); // using the formula of IRR
            }, 0);

            if (Math.abs(result) < tolerance) {
                break;
            }

            if (result > 0) {
                guess += step;
            } else {
                guess -= step;
                step /= 10;
            }
        }

        return (guess * 100).toFixed(2); // convert to percentage and round to two decimal places
    }
  }
}
</script>

<style lang="stylus">
</style>

<style lang="stylus" scoped>
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  letter-spacing: -0.7px;
  color: #737B7D;
}

.info-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  letter-spacing: -0.7px;
  color: #737B7D;
}

</style>
