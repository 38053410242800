<template>
  <div class="h-full flex flex-col relative left-0 top-0" v-show="!state.loadingScreenActiveFromSplashscreen">
    <h1 class="text-center pt-6 pb-7 title">Dein Versicherungscheck</h1>
    <div class="flex flex-row flex-grow justify-evenly">
      <controll-wrapper class="transition-all duration-1000 flex flex-col px-3" :style="{minWidth: compWidth}">
        <template v-slot:title>
          <h3 class="text-center">
            <transition name="fade" mode="out-in">
              <span v-html="compCurrentTitle" :key="compCurrentTitle"></span>
            </transition>
            <VDropdown
            v-if="!state.app.versicherung.phase"
            class="inline-block"
            :distance="35"
            :triggers="['click']"
            :placement="'right'"
          >
            <sup><small class="cursor-pointer ml-2 text-[#737B7D]">Quelle</small></sup>
            <template #popper>
              <div class="w-[420px] p-4 overflow-hidden">
                <div class="flex flex-col">
                  <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                    <div class="text quelle-text">
                      Bei der Bewertung der Relevanz von Versicherungen für den individuellen Bedarf hat das Deutsche Institut für Altersvorsorge (DIA) die Methodik und Empfehlungen der Stiftung Warentest übernommen. Mehr Infos dazu findest du in <a href="https://www.test.de/Versicherungen-Optimaler-Risikoschutz-1162242-0/" target="_blank">Finanztest Ausgabe 03/2022</a>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </VDropdown>
          </h3>
        </template>
        <template v-slot:content>
          <div class="pt-3 flex flex-col h-full grow">
            <div v-for="answer of compCurrentAnswers" :key="answer.id" class="pl-16 pr-[74px] pt-5">
              <div @click="processClickOnAnswer(compCurrentQuestion, answer)" :class="{'bigger': state.app.versicherung.phase }" class="answer-text py-4 w-full text-center bg-white hover:bg-[#1E86CB] hover:text-white transition-all duration-300 cursor-pointer">
                {{answer.text}}
              </div>
            </div>
            <div class="pt-6 px-6 mb-8 text-center info-text" v-if="compIsFinished">
              Die Übersicht kannst du in deinem persönlichen Ergebnis noch einmal checken und auf Einsparpotentiale prüfen.
              <br><br>
              <span class="text-[#1E86CB]">Tipp:</span> Nicht alle für dich als "empfehlenswert" und "sinnvoll" aufgelisteten Versicherungen sollten gleichzeitig abgeschlossen, sondern individuell geprüft werden. Ein fachkundiger, unabhängiger Finanzberater hilft dir gern dabei.
              <br><br>
              Lege jetzt deine Wünsche und Ziele fest.
            </div>
            <div class="flex flex-row gap-10 mt-auto mb-4 pl-16 pr-[74px]" :class="{'gap-4 pl-8 pr-8': compIsFinished}" v-if="state.app.versicherung.phase">
              <div @click="goBack()" class="answer-text-bottom py-4 w-full text-center bg-white hover:bg-[#1E86CB] hover:text-white transition-all duration-300 cursor-pointer">
                Eine Frage zurück
              </div>
              <div @click="restart()" class="answer-text-bottom py-4 w-full text-center bg-white hover:bg-[#1E86CB] hover:text-white transition-all duration-300 cursor-pointer">
                Neu starten
              </div>
            </div>
          </div>
        </template>
      </controll-wrapper>
      <div class="result-wrapper relative grow pl-12 flex flex-row overflow-x-hidden">
        <div class="flex flex-col justify-around">
          <div v-for="category of state.app.versicherung.insuranceCategories" class="basis-1/5 flex flex-row" :key="category.id">
            <div class="w-36">
              <div class="category-title">{{category.name}}</div>
              <div class="category-text pt-2">{{category.text}}</div>
            </div>
          </div>
        </div>
        <div v-dragscroll class="flex flex-col justify-around overflow-x-auto beauti-scrollbar 
        after:absolute after:bg-gradient-to-r after:from-transparent after:to-white after:opacity-80 after:h-full after:w-[60px] after:top-0 after:right-0 after:z-10">
          <div v-for="category of state.app.versicherung.insuranceCategories" class="basis-1/5 flex flex-row pl-4" :key="category.id">
            <transition-group name="flip-list" class="grow flex flex-row gap-1" tag="div">
              <template v-for="insurance of compInsurances">
                <div v-if="insurance.categoryId === category.id" :key="insurance.insuranceId + '_' + insurance.categoryId" class="card p-2 w-[165px] h-24 max-h-24 rounded-md" :class="'cat-' + insurance.categoryId">
                  <div class="card-title" v-html="insurance.details.name">
                  </div>
                  <div v-if="insurance.details.details && 1==2" class="card-text pt-2" v-html="insurance.details.details">
                  </div>
                </div>
              </template>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue"

import { dragscroll } from 'vue-dragscroll'

export default {
  name: 'Versicherung',
  components: {
    ControllWrapper
  },
  directives: {
    dragscroll
  },
  data() { 
    return {
      state: this.$store.state,
      ui: {
      },
    }
  },
  mounted() {
  },
  computed: {
    compIsFinished() {
      return !this.compCurrentQuestion && this.state.app.versicherung.phase
    },
    compCurrentQuestion() {
      if (!this.state.app.versicherung.phase) return
      let result = this.checkPath(this.state.app.versicherung.questions, this.state.app.versicherung.phase, this.state.app.versicherung.answered)
      return result
    },
    compCurrentTitle() {
      if (!this.state.app.versicherung.phase) return "In welcher Phase deines Lebens<br>befindest du dich?"
      if (this.compCurrentQuestion) return this.compCurrentQuestion.text
      return "Geschafft. Das sind alle Versicherungen, die für dich relevant sind."
    },
    compCurrentAnswers() {
      if (!this.state.app.versicherung.phase) return this.state.app.versicherung.livePhases
      return this.compCurrentQuestion.answers
    },
    compInsurances() {
      let result = []
      if (!this.state.app.versicherung.phase) return []
      let lifePhase = this.state.app.versicherung.livePhases.find((o) => o.id === this.state.app.versicherung.phase)
      for (let insurance of lifePhase.insurances) {
        result.push(insurance)
      }
      result = this.checkSelectedInsurances(result, this.state.app.versicherung.questions, this.state.app.versicherung.answered)
      result = this.removeKickedInsurances(result)
      for (let insurance of result) {
        let find = this.state.app.versicherung.insurances.find((o) => o.id === insurance.insuranceId)
        insurance.details = find
      }

      //prepare for pdf generation
      let categories = ["Notwendig", "Empfehlenswert", "Sinnvoll", "Optional", "Nicht notwendig"];
      let pdfResult = [];
      result.forEach(obj => {
        let tmpObj = {};
        tmpObj.category = categories[obj.categoryId-1]
        tmpObj.title = obj.details.name.replace('&shy;', '');
        pdfResult.push(tmpObj)
      });
      this.state.app.pdfCharts.versicherungen = pdfResult;

      // console.log({result})
      return result
    },
    compWidth() {
      if (!this.compCurrentQuestion && this.state.app.versicherung.phase) return "400px"
      return "534px"
    }
  },
  methods: {
    goBack() {
      if (!this.state.app.versicherung.answered.length) {
        this.state.app.versicherung.phase = null
        this.state.app.versicherung.answered = []
      }
      else this.state.app.versicherung.answered = this.state.app.versicherung.answered.slice(0, -1)
    },
    restart() {
      this.state.app.versicherung.answered = []
      this.state.app.versicherung.phase = null
    },
    processClickOnAnswer(question, answer) {
      if (answer.isLifePhase)  {
        this.state.app.versicherung.phase = answer.id
        return
      }
      let obj = {
        question: question.id,
        answer: answer.id
      }
      let find = this.state.app.versicherung.answered.find((o) => o.question === question.id)
      if (find) find.answer = obj.answer
      else this.state.app.versicherung.answered.push(obj)
      // console.log("answered", this.state.app.versicherung.answered)
    },
    checkPath(questions, phase, answered) {
      for (let question of questions) {
        if (question.livePhases && !question.livePhases?.includes(phase)) continue;
        let answeredQuestion = this.state.app.versicherung.answered.find((o) => o.question === question.id)
        if (answeredQuestion) {
          let answer = question.answers.find((o) => o.id === answeredQuestion.answer)
          if (answer.questions) {
            let result = this.checkPath(answer.questions, phase, answered)
            if (result) return result
          }
        } else {
          return question
        }
      }
      return false
    },
    checkSelectedInsurances(result, questions, answered) {
      // console.log("get init result", result)
      for (let question of questions) {
        //check if questions has been answered
        let answeredQuestion = this.state.app.versicherung.answered.find((o) => o.question === question.id)
        if (answeredQuestion) {
          let answer = question.answers.find((o) => o.id === answeredQuestion.answer)
          if (answer.insurances?.length) {
            for (let insurance of answer.insurances) {
              let find = result.find((o) => o.insuranceId == insurance.insuranceId)
              if (!find) {
                result.unshift(insurance)
              }
            }
          }
          if (answer.questions) {
            result = this.checkSelectedInsurances(result, answer.questions, answered)
          }
        }
      }
      return result
    },
    //some additinal logic when an insurance kicks another insurance, when both of them are listed
    removeKickedInsurances(result) {
      let f = result.find((o) => o.insuranceId == 21) 
      if (f) {
        result = result.filter((p) => p.insuranceId !== 19 && p.insuranceId !== 22)
      }
      f = null
      f = result.find((o) => o.insuranceId == 19)
      if (f) {
        result = result.filter((p) => p.insuranceId !== 22)
      }
      return result
    }
  }
}
</script>

<style lang="stylus">
  h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #1F2532;
  }
</style>

<style lang="stylus" scoped>
.flip-list-enter-active {
  transition: all 1s;
}
.flip-list-leave-active {
  transition: all 1s;
}
.flip-list-enter {
  opacity:0
  transform: translateX(-300px)
}
.flip-list-leave-to {
  opacity:0
  transform: translateX(-300px)
}
.flip-list-move {
  transition: transform 1s;
  opacity: 1
}
.quelle-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.7px;
  color: #737B7D;
  a {
    color: #1E86CB;
  }
}
.info-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.7px;
  color: #373F41;
}
.card-enter-active {
  transition: all 0.4s;
}
.card-leave-active {
  transition: all 0.4s;
}
.card-enter {
  opacity:0
}
.card-leave-to {
  opacity:0
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1F2532;
}
.answer-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.7px;
  color: #737B7D;
  border-radius: 24px;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
  user-select:none;
  &.bigger {
    font-size:16px
  }
}
.answer-text-bottom {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.7px;
  color: #1E86CB66;
  border-radius: 24px;
  border: 1px solid #1E86CB;
  user-select:none;
  //box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.2);
}
h3 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  color: #373F41;
}
.result-wrapper {
  .category-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #737B7D;
  }
  .category-text {
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.7px;
    color: #737B7D;
  }
  .cat-1 {
    background: #0F558D
    .card-title {
      color: white
    }
    .card-text {
      color: white
      opacity: 0.6;
    }
  }
  .cat-2 {
    background:#1E86CB
    .card-title {
      color: white
    }
    .card-text {
      color: white
      opacity: 0.6;
    }
  }
  .cat-3 {
    background: #00C2FF
    .card-title {
      color: white
    }
    .card-text {
      color: white
    }
  }
  .cat-4 {
    background: #737B7D
    .card-title {
      color: white
    }
    .card-text {
      color: white
    }
  }
  .cat-5 {
    background: #DCDCDC
    .card-title {
      color: #737B7D
    }
    .card-text {
      color: #737B7D
    }
  }
  .card {
    .card-title {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.7px;
    }
    .card-text {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.7px;
    }
    
  }
  .beauti-scrollbar {
    &::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #737B7D;
      border: 0px none #a62121;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #737B7D;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #737B7D;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #b52626;
      border-radius: 15px;
    }
    &::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    &::-webkit-scrollbar-track:active {
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}
.fade-enter-active {
  transition: all .4s;
}
.fade-leave-active {
  transition: all .4s;
}
.fade-enter {
  opacity:0
}
.fade-leave-to {
  opacity:0
}

</style>
