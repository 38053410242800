<template>
  <div class="select-wrapper mb-1">
    <div class="select-title flex items-end">
      <span v-html="valueTitle"></span>
    </div>
    <div 
      class="select-container flex flex-row w-full justify-between items-center relative"
      :class="'z-'+zOrder"
      >
      <div class="aselect" :data-value="state.app[modelObject][modelName]" :data-list="list">
        <div class="selector" :class="{open: visible}" @click="toggle()">
          <div class="label">
            <span>{{ state.app[modelObject][modelName] == null ? placeHolder : compValueFE }}</span>
          </div>
          <div class="arrow" :class="{ expanded: visible }"></div>
          <div :class="{ hidden: !visible, visible }">
            <ul>
              <li
                :class="{ current: item === state.app[modelObject][modelName] }"
                v-for="item in list"
                @click="select(item.value)"
                :key="item.valueFE"
              >
                {{ item.valueFE }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ControllWrapper",
  props: {
    modelObject: String,
    modelName: String,
    valueTitle: String,
    placeHolder: String,
    list: Array,
    zOrder: Number,
  },
  components: {},
  computed: {
    compValueFE() {
      let val = this.state.app[this.modelObject][this.modelName];
      let valFE;
      this.list.find((o, i) => {
        if (o.value == val) {
          valFE = o.valueFE;
        }
      })

      return valFE;
    }
  },
  data() {
    return {
      // isLazy: false,
      state: this.$store.state,
      visible: false,
    };
  },
  mounted() {
    // console.warn(this.$route.name, this.modelName);
  },
  methods: {
  toggle() {
    this.visible = !this.visible;
  },
  select(option) {
      // this.value = option;
      this.state.app[this.modelObject][this.modelName] = option
  }
}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.select-title {
  margin-left: -5px
  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #1E86CB;
  }
}
.aselect {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 4px;
  .selector {
    height: 36px
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none; 
    &.open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .arrow {
      position: absolute;
      right: 10px;
      top: 40%;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 10px solid #737B7D;
      transform: rotateZ(0deg) translateY(0px);
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(.59,1.39,.37,1.01);
    }
    .expanded {
      transform: rotateZ(180deg) translateY(2px);
    }
    .label {
      display: block;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #737B7D;
    }
  }
  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    position: absolute;
    z-index: 999;
    background: #fff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    left: 0;
    top: 36px;
    overflow: hidden;
  }
  li {
    padding: 8px;
    color: #666;
    text-align: center
    user-select: none; 
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #737B7D;
    &:hover {
      color: white;
      background: #1e86cb;
      cursor: pointer;
    }
  }
  .current {
    background: #eaeaea;
  }
  .hidden {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
}
</style>
