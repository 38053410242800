<template>
  <div class="controll-wrapper relative pt-4 pb-4 h-fit min-h-full">
    <h3 class="w-full text-center px-4" v-if="title" v-html="title"></h3>
    <slot name="title"></slot>
    <div class="content-wrapper flex flex-col h-full min-h-full flex-grow">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ControllWrapper',
  props: {
    title: String
  },
  components: {
  },
  computed: {
  },
  data() { return {

  }}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.controll-wrapper {
  background: #FFFFFF;
  border: 2px solid #DCDCDC;
  border-radius: 4px; 
  width: 534px
  box-shadow: rgba(0, 0, 0, 0.45) 28px 0px 20px -20px;
  z-index:1

  &::after {
    content:'';
    position absolute;
    right: -17px;
    width: 0;
    height: 0;
    top: 25%;
    border-style: solid;
    border-width: 17.5px 0 17.5px 17px;
    border-color: transparent transparent transparent #ffffff;
    filter: drop-shadow(2px 0px 0px #DCDCDC);
  }

  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: #373F41;
  }

  .content-wrapper {
    padding-top: 22px;
  }
}
</style>
