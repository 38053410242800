import { render, staticRenderFns } from "./altersvorsorge.vue?vue&type=template&id=01004b27&scoped=true&"
import script from "./altersvorsorge.vue?vue&type=script&lang=js&"
export * from "./altersvorsorge.vue?vue&type=script&lang=js&"
import style1 from "./altersvorsorge.vue?vue&type=style&index=1&id=01004b27&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01004b27",
  null
  
)

export default component.exports