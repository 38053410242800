<template>
  <transition name="modal">
    <div class="modal-wrapper" v-if="show" @click.self="close">
      <div class="modal" :class="customClass">
        <div class="head">
          <button class="close-button" @click="close">&#x2715;</button>
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: this.value
    }
  },
  watch: {
    value(val) {
      this.show = val
    },
    show(val) {
      this.$emit('input', val)
      if (val) {
        document.body.appendChild(this.$el)
      }
    }
  },
  methods: {
    close() {
      this.show = false
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  z-index: 9999;
}

.modal {
  background-color: white;
  padding: 1rem;
  position: relative;
  border-radius: 5px;
  display:flex;
  flex-direction:column;
  max-height:93%;
  max-width:93%;
  overflow:hidden;
  position:relative;
}

.head {
  place-self:end
}

.content {
  position:relative;
  max-height:100%;
  overflow:auto;
}

.close-button {
  position: relative;
  border: none;
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
</style>