<template>
  <controll-wrapper title="Wie hoch ist dein Risiko, berufsunfähig zu werden?">
    <template v-slot:content>
      <div class="pl-16 pr-[74px]">
        <div v-for="val in dataValues" :key="val.modelName">
          <app-multi-slider v-if="val.type=='multi'"
            :modelObject="'einkommensschutz'"
            :modelName="val.modelName"
            :valueTitle="val.valueTitle"
            :interval="val.interval"
            :isLazy="val.isLazy"
            :values="val.values"
            @updateSelected="updateSelected(val.modelName)" 
            @updateData="updateData(val.modelName)" 
          />
          <app-select v-if="val.type=='select'"
            :modelObject="'einkommensschutz'"
            :modelName="val.modelName"
            :valueTitle="val.valueTitle"
            :placeHolder="val.placeHolder"
            :list="val.values"
            :zOrder="val.zOrder"
          />
        </div>
      </div>
      <div class="risk-wrapper">
        <p class="risk-title">Dein Risiko<br></p>  
        <div class="value-wrapper relative">
          <p class="risk-title"><span>{{state.app.einkommensschutz.buRsikResult}}%</span></p>
          <VDropdown
            v-if="state.app.einkommensschutz.buRsikResult !== '?'"
            :distance="35"
            :triggers=[]
            :shown="showInfo"
            :placement="'right'"
            :class="'absolute cursor-pointer top-[25%] right-[35%]'"
          >
            <div>
              <img @click.stop="showInfo = !showInfo" class="w-5 h-5 z-20" :src="'static/img/info-icon-grey.svg'">
            </div>
            <template #popper>
              <div class="w-[420px] p-4 overflow-hidden">
                <div class="flex flex-col">
                  <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                    <div class="popper-text mt-7">
                      <strong>Dein Risko ist {{state.app.einkommensschutz.buRsikResult}}%</strong><br><br>
                      Dein Risiko, berufsunfähig zu werden, beträgt {{state.app.einkommensschutz.buRsikResult}} Prozent. <br>
                      Jede {{Math.round(100/state.app.einkommensschutz.buRsikResult)}}. Person in deiner Berufsgruppe kann laut Statistik mindestens einmal im Berufsleben temporär oder dauerhaft die Tätigkeit nicht mehr ausführen.
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </VDropdown>
        </div>
        

        <p class="text">Deine Berufsunfähigkeit kann jederzeit eintreffen. Wann genau weiß niemand. Wir wählen einen zufälligen Zeitpunkt.</p>
        <div class="btn-wrapper" :class="{'active': compRiskButtonActive}" @click="getSimulationRisk()">
          <!-- <img v-if="!compRiskButtonActive" src="/static/icons/risk-icon-white.svg" alt="">
          <img v-else src="/static/icons/risk-icon-dark.svg" alt=""> -->
          <span v-if="!compRiskButtonActive" class="text">Simulation Berufsunfähigkeit</span>
          <span v-else class="text">Simulation wiederholen</span>
        </div>

      </div>
    </template>
  </controll-wrapper>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue";
import AppSlider from '@/components/AppSlider.vue'
import AppMultiSlider from '@/components/AppMultiSlider.vue'
import AppSelect from '@/components/AppSelect.vue'

export default {
  name: 'Einkommenschutz_2',
  components: {
    ControllWrapper,
    AppSlider,
    AppMultiSlider,
    AppSelect
  },
  data() { return {
    state: this.$store.state,
    showInfo: false,
    riskButtonActive: false,
    apiEndPoint: "sex",
    dataValues: [
      {
        type: "select",
        valueTitle: "Dein Geschlecht",
        values:[
          {
            value: 'male',
            valueFE: 'männlich'
          },
          {
            value: 'female',
            valueFE: 'weiblich'
          }
        ],
        placeHolder: "Auswählen",
        modelName: "geschlecht",
        zOrder: 50,
      },
      {
        type: "select",
        valueTitle: "Deine Berufsgruppe",
        apiEndPoint: "job",
        values: [
          {
            value: 0,
            valueFE: "Akademiker"
          },
          {
            value: 1,
            valueFE: "Büroberuf (Kaufmann)"
          },
          {
            value: 2,
            valueFE: "Handwerksberuf"
          }
        ],
        placeHolder: "Auswählen",
        modelName: "berufsGruppe",
        zOrder: 20,
      },
      {
        type: "select",
        valueTitle: "Körperliche Arbeit",
        apiEndPoint: "koerperlicheArbeit",
        values:[
          {
            value: 0,
            valueFE: "wenig"
          },
          {
            value: 1,
            valueFE: "mittel"
          },
          {
            value: 2,
            valueFE: "viel"
          }
        ],
        placeHolder: "Auswählen",
        modelName: "koerperlicheArbeit",
        zOrder: 10,
      },
    ],
    simulationValues: [60,30,50],
    simIndex: 0,
  }},
  watch: {
    'state.app.einkommensschutz' : {
      handler: function(newVal, oldVal) {
        this.getBuRisk();
      },
      deep: true
    },
  },
  mounted() {
    this.updateData();
    this.getBuRisk();
  },
  computed: {
    compRiskButtonActive() {
      return this.state.app.einkommensschutz.simulationResult != null;
    },
  },
  methods: {
    getSimulationRisk() {
      if (this.simIndex >= this.simulationValues.length) {
        this.simIndex = 0;
      }
      this.state.app.einkommensschutz.simulationResult = this.simulationValues[this.simIndex];
      this.simIndex++;
    },
    getBuRisk() {
      // console.log(this.state.app.einkommensschutz);
      if (this.state.app.einkommensschutz.alter != null && this.state.app.einkommensschutz.geschlecht != null) {
        let apiUrl = "https://bu-dia.core.progressus.sonares.org/calc/bu2";
        let data = {
          age: this.state.app.einkommensschutz.alter,
          sex: this.state.app.einkommensschutz.geschlecht,
          job: this.state.app.einkommensschutz.berufsGruppe,
          koerperlicheArbeit: this.state.app.einkommensschutz.koerperlicheArbeit,
          bmi: 0,
          family: 0,
          alc: 0,
          smoke: 0
        }
        this.$http.post(apiUrl,
          data
        ).then(response => {
          this.state.app.einkommensschutz.buRsikResult = response.data;
        }).catch(err => {
          console.error(err);
        })
      }
    },
    updateData(data) {
      this.$emit('updateData')
    }
  }
}
</script>

<style lang="stylus">
</style>

<style lang="stylus" scoped>
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1F2532;
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.7px;
  color: #737B7D;
}
.popper-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.7px;
  color: #373F41;
}
.chart-wrapper {
  width: 630px;
  height: 100%;
  // position: absolute;
  // right: 0;
}
.chart {
  height: 100%;
}

.risk-wrapper {
  .risk-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.7px;
    color: #737B7D;
    
    span {
      font-size: 40px;
      font-weight: 600;
    }
  }
  .text {
    padding-inline: 58px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.7px;
    color: #737B7D;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    // width: 280px;
    height: 50px;
    max-width: 280px;
    background: #1E86CB;
    border-radius: 50px;
    margin: 0 auto;
    margin-top: 10px;
    cursor: pointer;
    transition: all 150ms ease-in-out;

    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      padding: 0;
      width: 100%;
      user-select: none; 
    }

    &.active {
      background: #fff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
      
      .text {
        color: #333D52;
      }
    }
  }
}
</style>
