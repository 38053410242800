import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import App from '../views/app.vue'
import Versicherung from '../views/versicherung.vue'
import Ziele from '../views/ziele.vue'
import Vermoegen from '../views/vermoegen/vermoegen.vue'
import Vermoegen_1 from '../views/vermoegen/vermoegen_1.vue'
import Vermoegen_2 from '../views/vermoegen/vermoegen_2.vue'
import Einkommen from '../views/einkommen/einkommen.vue'
import Einkommen_1 from '../views/einkommen/einkommen_1.vue'
import Einkommen_2 from '../views/einkommen/einkommen_2.vue'
import Einkommen_3 from '../views/einkommen/einkommen_3.vue'
import Ergebnis from '../views/ergebnis.vue'
import Altersvorsorge from '../views/altersvorsorge.vue'
import Berufsunfaehigkeit from '../views/berufsunfaehigkeit.vue'
import Fondsanlagen from '../views/fondsanlagen.vue'
import Lebenserwartung from '../views/lebenserwartung.vue'
import Datenschutz from '../views/Datenschutz.vue'
import pdfGen from '../views/pdfgen.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  },
  {
    path: '/app',
    name: 'app',
    component: App,
    children: [
      {
        path: '',
        alias: '/versicherungscheck',
        name: 'versicherung',
        component: Versicherung,
      },
      {
        path: 'ziele',
        alias: '/ziele',
        name: 'ziele',
        component: Ziele,
      },
      {
        path: 'vermoegen',
        name: 'vermoegen',
        component: Vermoegen,
        children: [
          {
            path: '1',
            alias: '',
            name: 'vermoegen_1',
            component: Vermoegen_1,
          },
          {
            path: '2',
            name: 'vermoegen_2',
            component: Vermoegen_2,
          }
        ]
      },
      {
        path: 'einkommen',
        name: 'einkommensschutz',
        component: Einkommen,
        children: [
          {
            path: '1',
            alias: '',
            name: 'einkommensschutz_1',
            component: Einkommen_1,
          },
          {
            path: '2',
            alias: '',
            name: 'einkommensschutz_2',
            component: Einkommen_2,
          },
          {
            path: '3',
            alias: '',
            name: 'einkommensschutz_3',
            component: Einkommen_3,
          }
        ]
      },
      {
        path: 'ergebnis',
        name: 'ergebnis',
        component: Ergebnis,
      },
      {
        path: 'pdfgen',
        name: 'PdfGen',
        component: pdfGen,
      },
      {
        path: 'altersvorsorge',
        name: 'altersvorsorge',
        component: Altersvorsorge,
      },
      {
        path: 'berufsunfaehigkeit',
        name: 'berufsunfaehigkeit',
        component: Berufsunfaehigkeit,
      },
      {
        path: 'fondsanlagen',
        name: 'fondsanlagen',
        component: Fondsanlagen,
      },
      {
        path: 'lebenserwartung',
        name: 'lebenserwartung',
        component: Lebenserwartung,
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

let hasRedirected = false
router.beforeEach((to, from, next) => {
  const isMobile = /iPhone|Android/i.test(navigator.userAgent)
  const isSmallScreen = window.matchMedia("(max-width: 767px)").matches
  if (isMobile && isSmallScreen && to.name !== 'home' && !hasRedirected) {
    hasRedirected = true
    next({ name: 'home' })
  }else {
    next();
  }
})

export default router
