<template>
  <div class="h-full relative left-0 top-0 flex flex-col">
    <h1 class="text-center pt-6 pb-7 title"></h1>
    <div class="flex flex-row gap-2 justify-between h-full">
      <controll-wrapper title="Möchtest du eine Zusammenfassung der Ergebnisse?">
        <template v-slot:content>
          <div class="pl-16 pr-[74px] pt-16">
            Glückwunsch! Jetzt hast du den Überblick über deine finanziellen Möglichkeiten. 
            <br><br>
            Mit dieser Zusammenfassung kannst du deine Finanzen in die eigenen Hände nehmen und sie als Grundlage für dein erstes Gespräch mit einem Finanzspezialisten nutzen – egal ob Finanzberater, Bank oder Verbraucherzentrale. Gemeinsam mit einem Profi kannst du dir die für deinen persönlichen Bedarf geeigneten Produkte und Anbieter vergleichen und empfehlen lassen.
          </div>
          <div class="pt-20 flex flex-col gap-y-5 items-center">
            <div 
              class="btn py-4 bg-[#1E86CB] text-white w-72 rounded-xl shadow-lg text-center cursor-pointer" >
              PDF öffnen
            </div>
            <div 
              class="btn py-4 bg-[#1E86CB] text-white w-72 rounded-xl shadow-lg text-center cursor-pointer" >
              PDF herunterladen
            </div>
            <div @click="restart()" class="btn py-4 bg-white text-[#373F41] w-72 rounded-xl shadow-lg text-center cursor-pointer">
              Neu starten
            </div>
          </div>
        </template>
      </controll-wrapper>
      <div class="chart-wrapper relative grow text-center">
        <img class="inline-block" src="static/img/pdf_preview2.jpg">
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ControllWrapper from "@/components/ControllWrapper.vue"

export default {
  name: 'PdfGen',
  components: {
    ControllWrapper
  },
  data() { return {
    state: this.$store.state,

  }},
  beforeMount() {
    this.state.showTransition = false;
    this.$router.push({name:"ergebnis"})
  },  
  mounted() {
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
.btn {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
}
</style>