<template>
  <div class="slider-wrapper mb-5">
    <div class="slider-title flex items-end mb-1">
      <img v-if="icon" :src="require(`../../public/static/icons/${icon}.png`)" :alt="`Icon ${icon}`">
      <span class="whitespace-nowrap" v-html="valueTitle"></span>
      <div class="info-icon grow self-center inline-flex">
        <VDropdown
          ref="dropdown"
          :distance="15"
          :placement="'right'"
          class="checkbox-info-container"
        >
        <img class="ml-2" v-if="icon2" src="../../public/static/img/info-icon-grey.svg" alt="Icon info">
          <template #popper>
            <div class="w-[420px] p-4 overflow-hidden">
              <div class="flex flex-col">
                <div class="bg-[#F2F2F2] p-7 grow max-h-[490px] overflow-auto shadow-inner modal-scrollbar">
                  <div class="text">
                    <div class="">
                      Bei der Berechnung des Finanzbedarfs im Alter wird standardmäßig von folgenden Annahmen ausgegangen: <br><br>

                      &#8226; Renteneintrittsalter: 67 Jahre <br>
                      &#8226; Lebenserwartung: 89 Jahre* <br>
                      &#8226; Dauer der Auszahlung: 22 Jahre <br><br>
                      Die zugrundeliegende Formel lautet: <br>
                      gewählter monatlicher Betrag x 12 Monate x 22 Jahre = zusätzliches Geld im Alter <br><br>

                      Du kannst deine individuelle Lebenserwartung aber auch auf Grundlage zahlreicher Faktoren selbst berechnen, indem du hier <span @click="closeDropdown" class="italic text-[#1E86CB]">Sciencefact - Lebenserwartung berechnen</span> klickst und den errechneten Wert manuell eingibst. <br><br>

                      *Die durchschnittliche Lebenserwartung ergibt sich aus folgenden Kennzahlen: Laut der <a class="italic text-[#1E86CB]" href="https://aktuar.de/unsere-themen/lebensversicherung/Seiten/sterbetafeln.aspx" target="_blank">Deutschen Aktuarvereinigung (DAV)</a>, der Vereinigung der Versicherungsmathematiker Deutschlands, liegt die durchschnittliche Lebenserwartung eines 20-jährigen Mannes bei 95,4 Jahren und einer 20-jährigen Frau bei 99,2 Jahren. Der Mittelwert ergibt eine Lebenserwartung für heute 20-Jährige von 97 Jahren. Das <a class="italic text-[#1E86CB]" href="https://www-genesis.destatis.de/genesis/online?sequenz=tabelleErgebnis&selectionname=12621-0002&zeitscheiben=16&sachmerkmal=ALT577&sachschluessel=ALTVOLL000,ALTVOLL020,ALTVOLL040,ALTVOLL060,ALTVOLL065,ALTVOLL080#abreadcrumb" target="_blank">Statistische Bundesamt</a> errechnet auf Basis aktueller Sterbetafeln eine Lebenserwartung von 83,6 Jahren bei 20-jährigen Frauen und 78,8 Jahren bei 20-jährigen Männern. Berechnet man hier den Mittelwert, erhält man eine Lebenserwartung von 81 Jahren. Für unsere Berechnung haben wir das Mittel beider Werte errechnet. Es liegt bei einer Lebenserwartung von 89 Jahren für heute 20-Jährige.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </VDropdown>
      </div>
      <!-- <img class="ml-2" v-if="icon2" src="../../public/static/img/info-icon-grey.svg" alt="Icon info"> -->
    </div>
    <div class="slider-container flex flex-row w-full justify-between items-center">
      <vue-slider 
        width="calc(100% - 90px)"
        v-model="state.app[modelObject][modelName]" 
        :tooltip="'none'"
        :drag-on-click="true"
        :dotSize="'20px'"
        :min="compMinValue"
        :max="allowedInsurancesForDynamic.includes(modelName) && state.app.einkommensschutz.gehaltSteigerung > 0 ? compMaxDynamicValue : compMaxValue"
        :interval="interval"
        @drag-start="$emit('updateSelected')" 
        @drag-end="isLazy && $emit('updateData')"
        @change="!isLazy && $emit('updateData')"
        @dragging="getDraggingPosition"
      />
      <div class="input-wrapper relative">
        <input 
          :id="modelName"
          v-model="compValue" 
          :class="[valueSuffix, {big:bigText}]"
          :style="inputStyle"
          @focus="$emit('updateSelected')"
          @input="$emit('updateData')"
        />
        <label :for="modelName" :class="{big:bigText}" :style="inputStyle" v-if="valueSuffix=='euro'" class="suffix absolute top-[11px] right-2">€</label>
        <label :for="modelName" :class="{big:bigText}" :style="inputStyle" v-if="valueSuffix=='prozent'" class="suffix absolute top-[11px] right-2">%</label>
        <label :for="modelName" :class="{big:bigText}" :style="inputStyle" v-if="valueSuffix=='jahr'" class="suffix absolute top-[11px] right-2">Jahre</label>
        <p class="extra-data" v-if="allowedInsurancesForDynamic.includes(modelName) && bigText && state.app.einkommensschutz.gehaltSteigerung > 0">+ {{Math.min(state.app.einkommensschutz.gehaltSteigerung, 3)}}% Dynamik p.a.</p>
        <!-- <p class="extra-data" v-if="modelName === 'simulationBerufsunfähigkeitsversicherung' && bigText && state.app.einkommensschutz.gehaltSteigerung > 0">letztes Jahr: {{ compDynamicValue }}€</p> -->
      </div>

    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'ControllWrapper',
  props: {
    valueSuffix: String,
    icon: String,
    icon2: Boolean,
    valueTitle: String,
    minValue: Number,
    maxValue: Number,
    modelName: String,
    interval: Number,
    modelObject: String,
    isLazy: Boolean,
    inputStyle: Object,
    bigText: Boolean,
  },
  components: {
    VueSlider
  },
  created() {
    // console.log("minValue",this.minValue);
    // console.log("maxValue",this.maxValue);
    // console.log("interval",this.interval);
  },
  computed: {
    compValue: {
      get : function() { 
        // return Intl.NumberFormat().format(this.state.app[this.$route.name][this.modelName])
        if (this.modelName === "simulationBerufsunfähigkeitsversicherung") {
          let percentage = this.sliderPositionPercentage;
          let minVal = this.compMinValue;
          let maxVal = this.compMaxValue;
          let value = minVal + (maxVal - minVal) * percentage;

          this.state.app.einkommensschutz.simulationBerufsunfähigkeitsversicherungErstesJahr = value;

          // this.state.app[this.modelObject][this.modelName] = value;
          this.compKeepSliderAtMaximum;

          return Number(Math.round(value / this.interval) * this.interval).toLocaleString()
          // return Number(this.state.app[this.modelObject][this.modelName]).toLocaleString()
        }else if (this.modelName === "simulationErwerbsunfähigkeitsversicherung") {
          let percentage = this.sliderPositionPercentage;
          let minVal = this.compMinValue;
          let maxVal = this.compMaxValue;
          let value = minVal + (maxVal - minVal) * percentage;

          this.state.app.einkommensschutz.simulationErwerbsunfähigkeitsversicherungErstesJahr = value;

          // this.state.app[this.modelObject][this.modelName] = value;
          this.compKeepSliderAtMaximum;

          return Number(Math.round(value / this.interval) * this.interval).toLocaleString()
          // return Number(this.state.app[this.modelObject][this.modelName]).toLocaleString()
        }else if (this.modelName === "simulationGrundfähigkeitsversicherung") {
          let percentage = this.sliderPositionPercentage;
          let minVal = this.compMinValue;
          let maxVal = this.compMaxValue;
          let value = minVal + (maxVal - minVal) * percentage;

          this.state.app.einkommensschutz.simulationGrundfähigkeitsversicherungErstesJahr = value;

          // this.state.app[this.modelObject][this.modelName] = value;
          this.compKeepSliderAtMaximum;

          return Number(Math.round(value / this.interval) * this.interval).toLocaleString()
          // return Number(this.state.app[this.modelObject][this.modelName]).toLocaleString()
        }else {
          return Number(this.state.app[this.modelObject][this.modelName]).toLocaleString()
        }          
      },
      set : function(val) {
        console.log("set", val, this.modelName)
        if (typeof val === 'string') {
          // Ersetze Kommas nur, wenn es nicht das letzte Zeichen im String ist
          if (!val.endsWith(',')) {
              val = val.replace(',', '.');
          } else {
            return
          }
        }
        //Dezimal nur erlauben, wenn renditeziel
        if (this.modelName == "renditeziel") val = parseFloat(val);
        else val = parseInt( (''+val).split('.').join(''), 10)
        if (val < this.minValue) {val = this.minValue}
        if (val > this.maxValue) {val = this.maxValue}
        if (val.toString() === 'NaN') val = this.minValue
        this.state.app[this.modelObject][this.modelName] = val
      }
    },
    compDynamicValue: {
      get : function() { 
        // return Intl.NumberFormat().format(this.state.app[this.$route.name][this.modelName])
        return Number(this.state.app[this.modelObject][this.modelName]).toLocaleString()
      },
      set : function(val) {
        val = parseInt( (''+val).split('.').join(''), 10)
        if (val < this.minValue) {val = this.minValue}
        if (val > this.maxValue) {val = this.maxValue}
        if (val.toString() === 'NaN') val = this.minValue
        this.state.app[this.modelObject][this.modelName] = val
      }
    },
    compMaxDynamicValue() {
      let toReturn = this.maxValue;
      if (this.modelName === "simulationBerufsunfähigkeitsversicherung" || this.modelName === "simulationErwerbsunfähigkeitsversicherung") {
        // voller schutz bis 1500 netto
        if (this.state.app.einkommensschutz.nettoMtl <= 1500 && this.modelName === "simulationBerufsunfähigkeitsversicherung") {
          toReturn = Math.round(this.state.app.einkommensschutz.nettoMtl * 1 / this.interval) * this.interval;
        }else {
          toReturn = Math.round(this.state.app.einkommensschutz.nettoMtl * 0.9 / this.interval) * this.interval;
        }
      }

      if (this.allowedInsurancesForDynamic.includes(this.modelName)) {

        // dynamik bis 3%
        if (this.state.app.einkommensschutz.gehaltSteigerung > 0) {
          let yearsToRetire = 67 - this.state.app.einkommensschutz.alter;
          let ageOfBu = this.state.app.einkommensschutz.simulationResult;
          let yearsFromBuToRetire = 67 - ageOfBu;
          let factor = (Math.min(this.state.app.einkommensschutz.gehaltSteigerung, 3) + 100) / 100; // max 1.03

          // let monatsRate = this.monatlichesGehaltMitZweiSteigerungsraten(this.state.app.einkommensschutz.nettoMtl*12, this.state.app.einkommensschutz.gehaltSteigerung, yearsToRetire-yearsFromBuToRetire, factor, yearsToRetire);
          // toReturn *= Math.pow(factor, yearsToRetire); 
          // toReturn *= Math.pow(factor, (yearsToRetire - Math.round(yearsToRetire/5 - 1))); 
          toReturn = Math.round(toReturn / this.interval) * this.interval;

        }

        // kann das weg?
        if (toReturn > this.maxValue) toReturn = this.maxValue; // set value to max if it is higher than max
        
        this.compDynamicValue = toReturn; // set value to max if it is higher than max
      }


      return toReturn
    },
    compMaxValue() {
      let toReturn = this.maxValue;
      if (this.modelName === "simulationBerufsunfähigkeitsversicherung" || this.modelName === "simulationErwerbsunfähigkeitsversicherung") {
        // voller schutz bis 1500 netto
        if (this.state.app.einkommensschutz.nettoMtl <= 1500 && this.modelName === "simulationBerufsunfähigkeitsversicherung") {
          toReturn = Math.round(this.state.app.einkommensschutz.nettoMtl * 1 / this.interval) * this.interval;
        }else {
          toReturn = Math.round(this.state.app.einkommensschutz.nettoMtl * 0.9 / this.interval) * this.interval;
        }
        this.compValue = toReturn; // set value to max if it is higher than max
      }
      return toReturn
    },
    compMinValue() {
      let toReturn = this.minValue;
      if (this.modelName === "simulationBerufsunfähigkeitsversicherung") {
        // console.log(Math.round(this.state.app.einkommensschutz.nettoMtl * 0.5 / 50));
        toReturn = Math.round(this.state.app.einkommensschutz.nettoMtl * 0.5 / this.interval) * this.interval;
        this.state.app[this.modelObject][this.modelName] = toReturn;
      }

      return toReturn
    },
    compKeepSliderAtMaximum() {
      this.state.app[this.modelObject][this.modelName] = this.compMaxDynamicValue;
    }
  },
  data() { return {
    // isLazy: false,
    state: this.$store.state,
    sliderPositionPercentage: 1,
    allowedInsurancesForDynamic: [
      "simulationBerufsunfähigkeitsversicherung", 
      "simulationErwerbsunfähigkeitsversicherung", 
      "simulationGrundfähigkeitsversicherung"
    ],
  }},
  mounted() {
    // console.warn(this.$route.name, this.modelName);
  },
  methods: {
    monatlichesGehaltMitZweiSteigerungsraten(startgehalt, steigerung1, jahre1, steigerung2, gesamtJahre) {
      let y1 = steigerung1 / 100;
      let y2 = steigerung2 / 100;
      
      let monatlicheGehaelter = [];

      // Monatliches Gehalt für die ersten Jahre mit der ersten Steigerungsrate
      for (let jahr = 1; jahr <= jahre1; jahr++) {
        startgehalt *= (1 + y1);
        monatlicheGehaelter.push(startgehalt / 12);
      }

      // Monatliches Gehalt für die restlichen Jahre mit der zweiten Steigerungsrate
      for (let jahr = jahre1 + 1; jahr <= gesamtJahre; jahr++) {
        startgehalt *= (1 + y2);
        monatlicheGehaelter.push(startgehalt / 12);
      }

      return monatlicheGehaelter;
    },
    getDraggingPosition(currentVal) {
      let minVal = this.compMinValue;
      let maxVal = this.compMaxDynamicValue;
      let valuePercentage = (currentVal - minVal) / (maxVal - minVal);
      
      this.sliderPositionPercentage = valuePercentage;
    },
    closeDropdown() {
      this.$nextTick(() => {
        this.$refs.dropdown.hide();
        this.$emit('openModal');
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">

.text {
  font-weight: 600;
  color: #1F2532;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 22px;
  margin-left: -4px;
  margin-bottom: 8px;
}
.slider-wrapper {
  .slider-title {
    margin-left: -5px
    img {
      max-height: 20px;
      max-width: 20px;
      margin-right: 8px;
    }
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.7px;
      color: #1E86CB;
    }
  }
  .slider-container {
    ::v-deep .vue-slider-dot-handle {
      background: #FABE50;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
      width: 20px;
      height: 20px;
      margin-top: -3px;
      margin-left: -3px;
    }
    input {
      width: 81px;
      height: 36px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      padding: 10px 17px 10px 7px;
      
      &.prozent {
        padding-right: 20px;
        &.big {
          padding-right: 35px;
        }
      }
      &.euro {
        padding-right: 17px;
        &.big {
          padding-right: 23px;
        }
      }
      &.jahr {
        padding-right: 42px;
      }
      
      color: #737B7D;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.7px;

      &.big {
        font-weight: 800;
        font-size: 26px;
        line-height: 22px;
        width: 136px;
      }
    }
    .suffix {
      color: #737B7D;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      text-align: right;
      letter-spacing: -0.7px;

      &.big {
        font-weight: 800;
        font-size: 26px;
        line-height: .6;
        margin-left: 30px;
      }
    }

    .extra-data {
      position: absolute;
      right: 0
      font-size: 14px;
      color: #737B7D;
    }
  }
}
</style>
