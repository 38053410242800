<template>
  <transition name="loader2">
    <div v-if="ui.showLoadingScreen" @click="ui.showLoadingScreen = false" class="loading-container absolute w-full h-full flex flex-col justify-center items-center z-20">
      <div>
        <img v-if="routeName == 'ziele'" :src="'./static/img/map_animation.gif'" />
        <img v-if="routeName == 'vermoegen_1'" :src="'./static/img/euro_animation.gif'" />
        <img v-if="routeName == 'einkommensschutz_1'" :src="'./static/img/suitcase_animation.gif'" />
        <img v-if="routeName == 'versicherung'" :src="'./static/img/document_animation.gif'" />
      </div>
      <div class="title">
        {{compTitle}}
      </div>
      <div class="text mt-6" v-html="compText">
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'LoadingScreen',
  props: ['to', 'from'],
  components: {
  },
  data() { return {
    state: this.$store.state,
    ui: {
      showLoadingScreen: false
    },
    routeName: ''
  }},
  async mounted() {
    this.routeName = this.$route.name
    this.showScreen()
  },
  watch: {
    'to.name': async function(n) {
      this.routeName = n
      this.showScreen()
    }
  },
  computed: {
    compTitle() {
      if (this.routeName == 'ziele') {
        return "Wünsche und Ziele"
      }
      if (this.routeName == 'vermoegen_1') {
        return "Vermögensaufbau"
      }
      if (this.routeName == 'einkommensschutz_1') {
        return "Einkommensschutz"
      }
      if (this.routeName == 'versicherung') {
        return "Versicherungscheck"
      }
    },
    compText() {
      if (this.routeName == 'ziele') {
        return "Wie du deinen Finanzbedarf definieren<br>und richtig einschätzen kannst."
      }
      if (this.routeName == 'vermoegen_1') {
        return "Wie du deine Vermögensziele realistisch<br>erreichen kannst."
      }
      if (this.routeName == 'einkommensschutz_1') {
        return "Wie du deinen Lebensunterhalt gegen den<br>Verlust der Arbeitskraft absicherst."
      }
      if (this.routeName == 'versicherung') {
        return "Erfahre welche Versicherungen in deiner Lebenssituation wichtig sind und welche du dir sparen kannst."
      }
    }
  },
  methods: {
    async showScreen() {
      if (!this.$route.params.back && (this.routeName == 'ziele' || this.routeName == 'vermoegen_1' || this.routeName == 'einkommensschutz_1' || this.routeName == 'versicherung')) {
        this.ui.showLoadingScreen = true
        if (!this.from) this.state.loadingScreenActiveFromSplashscreen = true
        await this.$store.sleep(3000)
        this.ui.showLoadingScreen = false
        this.state.loadingScreenActiveFromSplashscreen = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.loading-container {
  background: radial-gradient(33.23% 58.03% at 52.55% 50.75%, #1E86CB 0%, #0F558D 100%);
  img {
    width: 226px
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -2.4px;
    color: #FABE50;
  }
  .text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.7px;
    color: #FFFFFF;
  }
}
.loader-enter-active {
  transition: all .6s;
}
.loader-leave-active {
  transition: all .8s;
}
.loader-enter {
  transform: translateX(-110%);
  opacity:0
}
.loader-leave-to {
  transform: translateX(-110%);
  opacity:0
}

.loader2-enter-active {
  transition: all .6s;
}
.loader2-leave-active {
  transition: all .8s;
}
.loader2-enter {
  //transform: translateX(-110%);
  opacity:0
}
.loader2-leave-to {
  //transform: translateX(-110%);
  opacity:0
}

</style>
