<template>
  <div class="h-full flex flex-col">
    <div class="title pb-7 pt-6">
      Altersvorsorge
    </div>
    <div class="grow">
      <iframe class="w-full h-full" src="https://www.ihre-rentenluecke.de/#start" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Ziele',
  components: {
  },
  data() { return {
    ui: {
      
    }
  }}
}
</script>

<style lang="stylus">
</style>

<style lang="stylus" scoped>
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #1F2532;
}
</style>
