<template>
<div class="flex flex-col h-screen justify-center bg-slate-100">
  <div class="home relative w-screen max-w-screen-2xl h-screen max-h-[866px] mx-auto flex flex-row">
    <div class="menu-container flex flex-col">
      <div class="menu absolute overflow-hidden h-full flex flex-col items-center pt-3 pb-2 z-50 bg-white	" :class="{'menu-open': ui.showMenu}" @mouseover="ui.showMenu=true" @mouseleave="ui.showMenu=false" @click="ui.showMenu=true">
        <div class="h-[31px] self-start pl-4 logo-container">
          <img v-show="!ui.showMenu" src="../../public/static/img/dia_logo_small.png">
          <img class="cursor-pointer" @click="$router.push({name: 'home'})" v-show="ui.showMenu" src="../../public/static/img/dia_logo_full_menu.png">
        </div>
        <button @click="$router.push({name: 'versicherung'})" class="menu-item mt-24 flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'versicherung'" src="../../public/static/img/doc_g.png">
            <img v-else src="../../public/static/img/doc_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text" :class="$route.name == 'versicherung' && 'active'" v-show="ui.showMenu">
              Versicherungscheck
            </div>
          </transition>
        </button>
        <button @click="$router.push({name: 'ziele'})" class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'ziele'" src="../../public/static/img/map_g.png">
            <img v-else src="../../public/static/img/map_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text" :class="$route.name == 'ziele' && 'active'" v-show="ui.showMenu">
              Ziele
            </div>
          </transition>
        </button>
        <button @click="$router.push({name: 'vermoegen_1'})" class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'vermoegen_1' && $route.name != 'vermoegen_2'" src="../../public/static/img/coins_g.png">
            <img v-else src="../../public/static/img/coins_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text" :class="($route.name == 'vermoegen_1' || $route.name == 'vermoegen_2') && 'active'" v-show="ui.showMenu">
              Vermögensaufbau
            </div>
          </transition>
        </button>
        <button @click="$router.push({name: 'einkommensschutz_1'})" class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'einkommensschutz_1' && $route.name != 'einkommensschutz_2' && $route.name != 'einkommensschutz_3'" src="../../public/static/img/suitcase_g.png">
            <img v-else src="../../public/static/img/suitcase_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text" :class="($route.name == 'einkommensschutz_1' || $route.name == 'einkommensschutz_2' || $route.name == 'einkommensschutz_3') && 'active'" v-show="ui.showMenu">
              Einkommensschutz
            </div>
          </transition>
        </button>
        <button @click="$router.push({name: 'ergebnis'})" class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'ergebnis'" src="../../public/static/img/flag_g.png">
            <img v-else src="../../public/static/img/flag_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text" :class="$route.name == 'ergebnis' && 'active'" v-show="ui.showMenu">
              Ergebnis
            </div>
          </transition>
        </button>
        <button @click="$router.push({name: 'altersvorsorge'})" class="menu-item mt-8 flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'altersvorsorge'" src="../../public/static/img/couple_g.png">
            <img v-else src="../../public/static/img/couple_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text secondary" :class="$route.name == 'altersvorsorge' && 'active'" v-show="ui.showMenu">
              Altersvorsorge
            </div>
          </transition>
        </button>
        <button @click="$router.push({name: 'berufsunfaehigkeit'})" class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'berufsunfaehigkeit'" src="../../public/static/img/disabled_g.png">
            <img v-else src="../../public/static/img/disabled_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text secondary" :class="$route.name == 'berufsunfaehigkeit' && 'active'" v-show="ui.showMenu">
              Berufsunfähigkeits<br>
              -schutz
            </div>
          </transition>
        </button>
        <button class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <a href="https://software.vorsorge-finanzplanung.de/fondsanlagenoptimierer/views/index.xhtml" target="_blank">
              <img v-if="$route.name != 'fondsanlagen'" src="../../public/static/img/chart_g.png">
              <img v-else src="../../public/static/img/chart_b.png">
            </a>
          </div>
          <transition name="fade-left">
            <div class="icon-text secondary" :class="$route.name == 'fondsanlagen' && 'active'" v-show="ui.showMenu">
              <a href="https://software.vorsorge-finanzplanung.de/fondsanlagenoptimierer/views/index.xhtml" target="_blank">
                Fondsanlagen
                Optimierer
              </a>
            </div>
          </transition>
        </button>
        
        <!--button @click="$router.push({name: 'fondsanlagen'})" class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'fondsanlagen'" src="../../public/static/img/chart_g.png">
            <img v-else src="../../public/static/img/chart_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text secondary" :class="$route.name == 'fondsanlagen' && 'active'" v-show="ui.showMenu">
              Fondsanlagen
              Optimierer
            </div>
          </transition>
        </button-->
        <button @click="$router.push({name: 'lebenserwartung'})" class="menu-item flex flex-row gap-3 self-start justify-center items-center pl-5 pr-5">
          <div class="menu-icon">
            <img v-if="$route.name != 'lebenserwartung'" src="../../public/static/img/star_g.png">
            <img v-else src="../../public/static/img/star_b.png">
          </div>
          <transition name="fade-left">
            <div class="icon-text secondary" :class="$route.name == 'lebenserwartung' && 'active'" v-show="ui.showMenu">
              Lebenserwartungs<br>
              -rechner
            </div>
          </transition>
        </button>
        <!--button class="flex flex-row pl-5 pr-5 w-full grow items-end">
          <div class="menu-item flex flex-row gap-3 items-center">
            <div class="menu-icon">
              <img src="../../public/static/img/setting.png">
            </div>
            <transition name="fade-left">
              <div class="icon-text" v-show="ui.showMenu">
                Einstellungen
              </div>
            </transition>
          </div>
        </button-->
      </div>
    </div>
    <div class="w-full flex flex-col justify-between relative overflow-x-hidden">
      <loading-screen :to="ui.showLoadingScreenTo" :from="ui.showLoadingScreenFrom" />
      <transition :name="compFade" mode="out-in">
        <router-view class="pl-[30px] pr-[30px] pb-2 overflow-y-auto" :class="compIsIframe && 'nopadding'" />
      </transition>
      <div class="footer-nav z-10 px-32 py-4 flex justify-between items-center" v-show="!state.loadingScreenActiveFromSplashscreen" v-if="!compIsIframe">
        <button @click="goback()" class="py-2.5 px-12 bg-[#FABE50] text-white rounded-3xl">
          <span>Zurück</span>
        </button>
        <button v-if="compNextBtnVisible" @click="gonext()" :class="{'disabled': !gonext(true)}" class="py-2.5 px-12 bg-[#FABE50] text-white rounded-3xl">
          <span>{{compNextBtnText}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import LoadingScreen from '@/components/loadingScreen.vue'
import { watch } from 'vue-demi'
import { Warning } from 'postcss'

export default {
  name: 'HomeView',
  components: {
    LoadingScreen
  },
  mounted() {
    this.$store.copyState()
    let n = this.$route.name
    if (n != 'ziele' && n != 'vermoegen' && n != 'einkommensschutz' && n != 'versicherung') return
    console.log("route entry")
    this.ui.showLoadingScreenFrom = this.$route
    this.state.showTransition = true
  },
  data() { return {
    state: this.$store.state,
    ui: {
      showMenu: false,
      showLoadingScreenFrom: false,
      showLoadingScreenTo: false
    }
  }},
  computed: {
    compFade() {
      if (this.state.showTransition) {
        return 'fade'
      }else {
        return ''
      }
    },
    compIsIframe() {
      if (this.$route.name == 'lebenserwartung') return true
      if (this.$route.name == 'altersvorsorge') return true
      if (this.$route.name == 'berufsunfaehigkeit') return true
      if (this.$route.name == 'fondsanlagen') return true
      return false
    },
    compNextBtnVisible() {
      if (this.$route.name == 'ergebnis' || this.$route.name == 'PdfGen') return false
      return true
    },
    compNextBtnText() {
      if (this.$route.name === "versicherung") {
        return "Weiter zu Wünsche & Ziele"
      }
      if (this.$route.name === "ziele") {
        return "Weiter zu Vermögensaufbau"
      }
      if (this.$route.name === "vermoegen_2") {
        return "Weiter zu Einkommensschutz"
      }
      if (this.$route.name === "einkommensschutz_3") {
        return "Weiter zu Ergebnis"
      }

      return "Weiter"
    },
    // allowNext() {
    //   let toReturn;
    //   if (this.state.isDevMode) { 
    //     // if running on localhost skip anything else
    //     if (this.state.routingRules[this.$route.name].required === null) {
    //       // if has no requirements, return true
    //       toReturn = true
    //     }else {
    //       let requirements = this.state.routingRules[this.$route.name].required;
    //       let model = this.state.routingRules[this.$route.name].model;
    //       let subModel = this.state.routingRules[this.$route.name].subModel
    //       let mainModel = subModel ? this.state.app[model][subModel] : this.state.app[model]
    //       console.warn({requirements});
    //       if (Object.keys(requirements)[0] === '_and') {
    //         requirements['_and'].forEach(elem => {
    //           // console.warn(mainModel[elem]);
    //           if (mainModel[elem] === null) {
    //             toReturn = false
    //           }else {
    //             toReturn = true
    //           }
    //         });
    //       }
    //       if (Object.keys(requirements)[0] === '_some') {
    //         let exists = requirements['_some'].some(elem => mainModel[elem] === true);
    //         toReturn = exists ? true : false;
    //       }
    //     }
    //   }else {
    //     toReturn = true
    //   }
    //   console.warn({toReturn});
    //   return toReturn
    // },
  },
  methods: {
    getSubSet(requirements, model, subModel) {
      //  let requirements = ['geschlecht','berufsGruppe', 'koerperlicheArbeit', 'buRsikResult', 'simulationResult']
      //   let model = "einkommensschutz";
        // let subModel = false
        let mainModel = subModel ? this.state.app[model][subModel] : this.state.app[model]
        let subSet;
        if (requirements !== null) {
          subSet = Object.entries(mainModel)
                              .filter(([key, value]) => requirements.includes(key))
                              .map(([key, value]) => ({ [key]: value }));
        }else {
          subSet = mainModel;
        }

        return subSet;
    },
    gonext(checkOnly) {
      let routeOption = {
        name: null,
        allowRouting: false,
      };

      if (this.$route.name === 'versicherung') {
        routeOption.name = 'ziele';
        routeOption.allowRouting = true; // todo?
        // this.$router.push({name: 'ziele'})
        // return
      }
      if (this.$route.name === 'ziele') {
        routeOption.name = 'vermoegen_1';

        let requirements = ['erspartes', 'geldImAlter', 'jobSelbststaendig', 'kinderSupport', 'mobilitaet', 'wohnEigentum'];
        let subSet = this.getSubSet(requirements, "ziele", false)
        
        routeOption.allowRouting = subSet.some(obj => Object.values(obj).every(val => val > 0)) ? true :false
      }
      if (this.$route.name === 'vermoegen_1') {
        routeOption.name = 'vermoegen_2';


        let requirements = ['sparbetragMtl', 'sparphaseJahre'];
        let subSet = this.getSubSet(requirements, "vermoegen", false)
        
        routeOption.allowRouting = subSet.every(obj => Object.values(obj).every(val => val > 0)) ? true :false
      }
      if (this.$route.name === 'vermoegen_2') {
        routeOption.name = 'einkommensschutz_1';

        let requirements = ['flexibel', 'inflationsschutz', 'lebenslangeRente', 'ohneAbgeltungssteuer']
        let subSet = this.getSubSet(requirements, "vermoegen", "model")
        let subSetIsSet = subSet.some(obj => Object.values(obj).every(val => val === true));

        // let subSet2 = this.getSubSet(null, "vermoegen", "sparformen")
        // let subSetIsSet2 = subSet2.some(obj => obj.active === true);
        
        // routeOption.allowRouting = (subSetIsSet && subSetIsSet2) ? true :false
        routeOption.allowRouting = (subSetIsSet) ? true :false
      }
      if (this.$route.name === 'einkommensschutz_1') {
        routeOption.name = 'einkommensschutz_2';

        let requirements = ['alter', 'gehaltSteigerung', 'nettoMtl'];
        let subSet = this.getSubSet(requirements, "einkommensschutz", false)
        
        routeOption.allowRouting = subSet.every(obj => Object.values(obj).every(val => val !== null)) ? true :false
      }
      if (this.$route.name === 'einkommensschutz_2') {
        routeOption.name = 'einkommensschutz_3';

        // let requirements = ['geschlecht','berufsGruppe', 'koerperlicheArbeit', 'buRsikResult', 'simulationResult']
        // let requirements = ['simulationResult']
        let requirements = [];
        let subSet = this.getSubSet(requirements, "einkommensschutz", false)

        routeOption.allowRouting = subSet.every(obj => Object.values(obj).every(val => val !== null)) ? true :false
      }
      if (this.$route.name === 'einkommensschutz_3') {
        routeOption.name = 'ergebnis';

        let requirements = ['zahltBeiBU', 'zahltBeiEU', 'zahltBeiKrankheit', 'zahltBeiVerlustGF']
        let subSet = this.getSubSet(requirements, "einkommensschutz", "model")
        let subSetIsSet = subSet.some(obj => Object.values(obj).every(val => val === true));

        // let subSet2 = this.getSubSet(null, "einkommensschutz", "versicherungsFormen")
        // let subSetIsSet2 = subSet2.some(obj => obj.active === true);
        
        // routeOption.allowRouting = (subSetIsSet && subSetIsSet2) ? true :false
        routeOption.allowRouting = subSetIsSet;
      }

      this.state.isDevMode = false;

      if (this.state.isDevMode) {
        if (routeOption.name !== null) {
          if (!checkOnly) {
            if (this.$route.name === 'einkommensschutz_2') {
              this.getRandomSimulationRisk()
              setTimeout(() => {
                this.$router.push({name: routeOption.name})
              }, 350)
            }else {
              this.$router.push({name: routeOption.name})
            }
          }
          return true
        }
      }else if (routeOption.allowRouting && routeOption.name !== null) {
        if (!checkOnly) {
          if (this.$route.name === 'einkommensschutz_2') {
            this.getRandomSimulationRisk()
            setTimeout(() => {
              this.$router.push({name: routeOption.name})
            }, 350)
          }else {
            this.$router.push({name: routeOption.name})
          }
        }
        return true
      }
      return false
    },
    goback() {
      if (this.$route.name === 'ziele') {
        this.$router.push({name: 'versicherung', params: { back: true }})
        return
      }
      if (this.$route.name === 'vermoegen_2') {
        this.$router.push({name: 'vermoegen_1', params: { back: true }})
        return
      }
      if (this.$route.name === 'vermoegen_1') {
        this.$router.push({name: 'ziele', params: { back: true }})
        return
      }
      if (this.$route.name === 'einkommensschutz_1') {
        this.$router.push({name: 'vermoegen_2', params: { back: true }})
        return
      }
      if (this.$route.name === 'einkommensschutz_2') {
        this.$router.push({name: 'einkommensschutz_1', params: { back: true }})
        return
      }
      if (this.$route.name === 'einkommensschutz_3') {
        this.$router.push({name: 'einkommensschutz_2', params: { back: true }})
        return
      }
      if (this.$route.name === 'ergebnis') {
        this.$router.push({name: 'einkommensschutz_1', params: { back: true }})
        return
      }
    },
    getRandomSimulationRisk() {
      if (this.state.app.einkommensschutz.simulationResult === null) {
        // set random values from [65,35,55]
        let simValues = [65,35,55];
        let randomValue = simValues[Math.floor(Math.random() * simValues.length)];
        this.state.app.einkommensschutz.simulationResult = randomValue;
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    //if (to.name != 'ziele' && to.name != 'vermoegen' && to.name != 'einkommensschutz') next()
    console.log("route change")
    this.ui.showLoadingScreenFrom = from
    this.ui.showLoadingScreenTo = to
    next()
  },
}
</script>

<style lang="stylus">
.sweetmodal {
  svg {
    display:inline
  }
}
</style>

<style lang="stylus" scoped>
.nopadding {
  padding:0!important
}
.menu-container {
  width:73px
}
.menu {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease
  width:73px
  min-width:72px
  .logo-container {
    img {
      height:31px
    }
  }
  .menu-item {
    cursor:pointer
    height:64px
    text-align:left
  }
  .menu-icon {
    width:32px
    min-width:32px
    height:32px
    z-index:10
    background:white
    img {
      width:32px
      height:32px
    }
  }
  .icon-text {
    z-index:0
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.7px;
    color: #737B7D;
    &.secondary {
      color:rgba(115, 123, 125, 0.5);
    }
    &.active {
      color: #1E86CB
    }
  }
}
.menu-open {
  width: 226px
}

.home {
  background:white
  color: black
}
.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* or 125% */
  letter-spacing: 0.1px;
  .sub {
    font-size:22px
    font-weight:600
  }
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.35);
}
.text {
  font-size:16px
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */
  letter-spacing: 0.3px;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}
.button {
  background: #3C64B1
  padding: 15px
  width:267px
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-align:center
  cursor:pointer
}

.disabled {
  opacity: .5
  cursor: default
}

.fade-enter-active {
  transition: all .4s;
}
.fade-leave-active {
  transition: all .4s;
}
.fade-enter {
  opacity:0
}
.fade-leave-to {
  opacity:0
}

.fade-left-enter-active {
  transition: all .4s;
}
.fade-left-leave-active {
  transition: all .4s;
}
.fade-left-enter {
  transform: translateX(-110%);
  opacity:0
}
.fade-left-leave-to {
  transform: translateX(-110%);
  opacity:0
}
.footer-nav {
  background-color: #f2f2f2;
  //height: 76px;
  width: 100%
  button {
    span {
      // font-family: 'Mulish';
      font-style: normal;
      font-weight: 900;
      font-size: 13px;
      line-height: 22px;
      text-align: center;
    }
  }
}
</style>
