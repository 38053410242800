<template>
  <div class="slider-wrapper mb-7 relative z-0">
    <div class="slider-title flex items-end">
      <span v-html="valueTitle"></span>
    </div>
    <div class="slider-container flex flex-row w-full justify-between items-center">
      <vue-slider 
        :width="compWidth"
        v-model="state.app[modelObject][modelName]" 
        :tooltip="'none'"
        :drag-on-click="true"
        :dotSize="'20px'"
        :lazy="isLazy || false" 
        :interval="interval"
        :adsorb="true"
        :data="values"
        :marks="true"
        :included="true"
        :railStyle="{backgroundColor: '#DCDCDC'}"
        @drag-start="$emit('updateSelected')" 
        @change="$emit('updateData')"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'ControllWrapper',
  props: {
    modelObject: String,
    modelName: String,
    valueTitle: String,
    interval: Number,
    values: Array,
    type: String,
    isLazy: Boolean,
  },
  components: {
    VueSlider
  },
  computed: {
    compWidth() {
      return Math.floor(100 - 100/this.values.length)+"%"
    }
  },
  data() { return {
    state: this.$store.state,
  }},
  mounted() {
    // console.warn(this.state.app[this.modelO]);
    // console.warn(this.$route.name, this.modelName);
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.slider-wrapper {
  .slider-title {
    margin-left: -5px
    img {
      max-height: 20px;
      max-width: 20px;
      margin-right: 8px;
    }
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.7px;
      color: #1E86CB;
    }
  }
  .slider-container {
    ::v-deep .vue-slider-dot-handle {
      background: #FABE50;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
      width: 20px;
      height: 20px;
      margin-top: -3px;
      margin-left: -3px;
    }
    input {
      width: 81px;
      height: 36px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      padding: 10px 17px 10px 7px;
      
      &.prozent {
        padding-right: 20px;
      }
      &.euro {
        padding-right: 17px;
      }
      &.jahr {
        padding-right: 42px;
      }
      
      color: #737B7D;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.7px;
    }
    .suffix {
      color: #737B7D;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.7px;
    }
  }
}

::v-deep .vue-slider-ltr .vue-slider-mark-step, .vue-slider-rtl .vue-slider-mark-step {
  top: -5px;
  background-color: #DCDCDC;
  width: 2px;
  height: 4px;
  display: block!important;
}

::v-deep .vue-slider-ltr .vue-slider-mark-label {
  transform: translate(-50%, -220%)
}
::v-deep .vue-slider {
  margin: 0 auto;
}
.slider-container {
  margin-top: 35px;
  background-color: #DCDCDC;
  border-radius: 5px;
}
::v-deep .vue-slider-process {
  background-color: transparent
}
::v-deep .vue-slider-mark-label {
  color: #737B7D;
}
</style>
