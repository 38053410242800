import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import { store } from './store'
import axios from 'axios'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
Vue.use(FloatingVue)

Vue.config.productionTip = false

Vue.prototype.$store = store
Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')