<template>
  <div class="h-full flex flex-col">
    <div class="grow">
      <iframe class="w-full h-full" src="https://software.vorsorge-finanzplanung.de/fondsanlagenoptimierer/views/index.xhtml" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Ziele',
  components: {
  },
  data() { return {
    ui: {
      
    }
  }}
}
</script>

<style lang="stylus">
</style>

<style lang="stylus" scoped>

</style>
