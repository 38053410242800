import { render, staticRenderFns } from "./vermoegen_1.vue?vue&type=template&id=65a64e3f&scoped=true&"
import script from "./vermoegen_1.vue?vue&type=script&lang=js&"
export * from "./vermoegen_1.vue?vue&type=script&lang=js&"
import style1 from "./vermoegen_1.vue?vue&type=style&index=1&id=65a64e3f&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a64e3f",
  null
  
)

export default component.exports